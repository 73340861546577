// Styles for the feedback icon
@import "mixins";

.app-feedback {
  a {
    position: absolute;
    z-index: 2000;
    top: 15px;
    left: 100%;
    margin-left: -25px;
  }
}

.vfeed-dialog.vui-popup.vui-dialog {

  > .panel-content {
    .flex(1, 1, auto);
    height: auto;
  }

  .vfeed-content {
    padding-left: 4px;
    line-height: 16px;

    .feedback-form {
      padding: 0px;
    }

    .canvas {
      cursor: pointer;
      border-width: 1px 0 1px 1px;
      border-color: gray;
      border-style: solid;
      padding-right: 20px;
    }

    .content-padding {
      padding-left: 4px;
    }
    .smiley {
      padding: 4px;
      cursor: pointer;
    }
    form .form-group {
      display: block;
      padding: 0;
      margin-bottom: 0;
      margin-right: 0;
    }
    form .form-group.form-group-horizontal {
      margin: 4px 0 0 0;
      border:none;

      &:first-child {
        margin: 0;
      }
    }
    form .form-group textarea, .form .form-group textarea {
      margin: 0;
    }
    textarea {
      padding: 0 6px;
    }
    form .form-group a {
      margin-right: 0;
    }
    form .form-group input[type=text] {
      margin-top: 0;
    }
    form .form-group .smiley-container{
      display: inline-block;
    }

    input[type=email]{
      width: 95%;
    }
  }
}

.vui-notification {
  z-index: 2;
  header {
    display: flex;
    align-items: center;
    > span {
      flex-shrink: 0;
      &~span:not(:last-child) {
        flex: 1 1 100%;
        margin-right: 30px;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
  .panel-content {
    p {
      text-overflow: ellipsis;
      overflow: hidden;
      width: 100%;
    }
  }
}