/**
 * Styles for the Application Control Bar
 */

@import "focus";
@csp-header-background: #314351;
@header-item-opacity: 0.65;
@dropdown-margin: 12px;

@clr-selected-item-background: #D9E4EA;
@clr-hover-item-background: #eee;
@clr-item-color: #565656;
@header-items-outline-offset: -.08033rem; // Needed for focus outline. If you change this value verify focus on IE, FF (Mac and Windows") and Chrome (Mac and Windows)

.clr-nav-item {
   line-height: 18px;
   padding: 2px 0 2px 0;
}

.actions-menu, .dropdown-menu {
   color: @clr-item-color;
}

.perf {
   line-height: 10px;
}

.perf-badge-threshold-exceeded {
   background-color: red;
}

.user-tabbing .main-nav HEADER {
   .branding {
      //Branding image focus
      > a:focus {
         outline: @outline-white-focus;
         outline-offset: @header-items-outline-offset;
         outline: @outline-webkit-focus;
      }
   }

   .header-nav, .settings {
      //Header dropdown menu focus
      button:focus {
         outline: @outline-white-focus;
         outline-offset: @header-items-outline-offset;
         outline: @outline-webkit-focus;
      }
      // Refresh button focus
      a[role='button'].nav-link:focus {
         outline: @outline-white-focus;
         outline-offset: @header-items-outline-offset;
         outline: @outline-webkit-focus;
      }

      vsc-navigation-view-menu-item:focus {
         background-color: #eee;
      }
   }
}

.main-nav {
   @clarity-header-height: 60px;
   position: relative;

   HEADER {
      @navigation-item-gutter: 48px; // design
      padding: 0 0 0 @navigation-item-gutter / 2;
      // clr override
      height: 2.60rem; // Make focus frame more visible in the header. If you change this value verify focus on IE, FF (Mac and Windows") and Chrome (Mac and Windows)
      align-items: center;
      // clr override
      .showFeedback {
         z-index: 1171;
      }

      .branding {

         // clarity overrides per design
         width: auto;
         min-width: auto;
         max-width: auto;

         // clr override
         padding-left: 0;
         padding-right: @navigation-item-gutter / 2 - 4px;
         // clr override

         > a {
            position: relative;
            padding-right: 4px;
         }

         .clr-icon {
            margin-right: 12px;
            width: 36px;
            height: 32px;
         }
      }

      .header-nav {
         display: block;

         CLR-DROPDOWN {
            &.dropdown > .nav-link {
               opacity: 1;
               > .dropdown-toggle {

                  margin: 0 @navigation-item-gutter / 2;
                  //Home burger menu
                  &:not(.btn) {
                     padding-left: 0.2rem;
                     padding-right: 0.3rem;
                  }

                  .home-menu-link {
                     .nav-icon {
                        padding: 0;
                        width: auto;
                        font-size: inherit;
                        display: flex;
                        align-items: center; // balanced

                        background: transparent;
                        border: none;
                     }
                     color: #fafafa;
                     opacity: @header-item-opacity;
                     &:hover {
                        text-decoration: none;
                        opacity: 1;
                     }
                  }
               }
            }

            .dropdown-menu:focus {
               outline: @outline-focus;
               outline: @outline-webkit-focus;
               outline-offset: -1px;
            }

            .state-focused {
               outline: @outline-focus;
               outline: @outline-webkit-focus;
            }
         }
      }

      .search-box {

         display: flex;
         margin-left: 12px;
         margin-right: 12px;
         flex: 0 1 100%;
         max-width: none;

         // clr override  - (reset) alpha changes that affect dropdown menus
         opacity: initial;
         &:hover {
            opacity: initial;
         }

         .search-icon {
            margin-right: 6px;
            opacity: @header-item-opacity;
            &:hover {
               opacity: 1;
            }
         }

         > [role="quickSearchView"] {
            display: flex;
            flex: 0 1 100%;
            align-self: center;

            .quickSearchContainer {
               display: flex;
               flex: 0 1 100%;
               margin: 0; // VUI reset
               position: relative;

               // override clarity searchbox
               INPUT[type="text"] {
                  min-width: 150px; // RWD constraint
                  max-width: 150px;
                  flex: 0 1 100%;
                  text-overflow: ellipsis;
                  // clr override
                  padding-left: 6px;
               }

               // results dropdown
               .results-quicksearch {

                  // submodules quick search
                  &-container {
                     border-top: none;
                     box-shadow: 2px 2px 3px @clr-near-white;
                     //width: 500px;
                     width: inherit;

                     .results-padding-LR {
                        border: 1px solid @clr-light-gray;
                        border-radius: 0px 0px 3px 3px;
                        border-top: none;

                        TABLE .results-cell-white ul > li {
                           list-style-type: none;
                        }
                     }
                  }

                  &-position {
                     left: auto;
                     right: auto;
                     top: 42px;
                     left: -22px;
                  }
               }
            }
         }
      }

      // 20160920 - overriding clarity styles. Please ask Clarity to conform, then delete these styles
      .settings {
         align-items: center;
         // overflow: hidden; // why are elements even overflowing to begin with? is layout flawed?
         .update-info {
            overflow: hidden;
         }

         .global-refresh-button {
            z-index: 200;
         }

         .spinner.spinner-inline {
            line-height: 24px;
            vertical-align: middle;
            height: 24px;
            width: 24px;
            min-height: 24px;
            min-width: 24px;
            z-index: 100;

            &.spinner-paused {
               animation-play-state: paused;
               display: none;
            }
         }

         button {
            &.nav-icon {
               border: none;
               background: transparent;

               [shape="caret down"] {
                  margin-left: 24px;
               }
            }
         }

         .nav-link {
            // contents
            .fa {
               font-size: inherit;
            }

            .user-menu-large {
               padding: 0 0 0 18px;
               font-size: inherit;
               width: auto;
               display: inline-block;

               clr-icon {
                  left: initial;
                  right: 18px;
               }

               span {
                  margin-right: 46px;
               }
            }

            .help-menu {
               display: inline-block;

               [shape="caret down"] {
                  margin-left: 24px;
               }
            }
         }
         // Refresh button
         a[role='button'].nav-link {
            display: flex;
         }

         // 20170213 - bolting on support for next gen angular components
         // component: VcActionMenu - abstraction over clarity
         VC-ACTION-MENU {

            // clarity dropdown
            CLR-DROPDOWN {

               .dropdown-menu {
                  // stop the text clippage
                  min-width: 30ch;
               }

               .dropdown-menu:focus {
                  outline: @outline-focus;
                  outline: @outline-webkit-focus;
                  outline-offset: -1px;
               }

               .state-focused {
                  outline: @outline-focus;
                  outline: @outline-webkit-focus;
               }
            }

            VC-ACTION-MENU-TOGGLE {
               &:hover {
                  opacity: 1;
               }
            }

         }

         .perf {
            .nav-link {
               padding-right: 0px;
               padding-left: 0px;

               &:last-child {
                  padding-left: @navigation-item-gutter/4;
               }
            }
         }
      }

      .actions-menu {
         margin-top: 0;
      }

      > .divider, .settings > .divider {
         height: 40px; // design
         /* clr override */
         display: flex;
         flex: 0 0 auto;
         width: 1px;
         background: @clr-almost-white;
         opacity: 0.15;
         /* /clr override */

      }
   }
   // /HEADER
}

// todo: the splitter layout code MUST BE REFACTORED into VUI components !!!!!!!!!!!!!

// splitter work
.k-splitter {

   .k-splitbar {
      //border-left: 0.5px #000 solid;
      background: @clr-almost-white; // opaque reason to cut bleed

      &.k-state-focused {
      }

      // horiz
      &.k-splitbar-horizontal {

         // reset kendo
         &.k-state-focused {
            border-top: none;
            border-bottom: none;
         }

         border-top: 0;
         border-bottom: 0;
         width: 3px;
         border-left-width: 1px;

         cursor: ew-resize;
         transition: border 0.1s;
         transition-timing-function: start-end;

         &-hover {
            border-left: 1px #ddd solid;
            transition: border 0.5s;
            transition-timing-function: step-end;
         }

      }

      // vert
      // horiz
      &.k-splitbar-vertical {

         height: 3px;
         border-top: 0;
         border-bottom-color: #ddd;
         border-bottom-width: 1px;
         border-bottom-style: solid;
         border-left: 0;
         border-right: 0;

         cursor: ns-resize;
         transition: border 0.1s;
         transition-timing-function: start-end;

         // reset kendo
         &.k-state-focused {
         }
         &-hover {
            border-top-color: transparent;
            border-bottom: 1px #ddd solid;
            transition: border 0.5s;
            transition-timing-function: step-end;
         }

      }

   }

   // avatar - dragged
   .k-ghost-splitbar {

      &-horizontal {
         width: 2px;
         border-left: 2px #89cbdf solid;
         outline: none;
         background: none;
      }

      // vertical
      &-vertical {
         height: 2px;
         border-top: 0;
         border-bottom: 2px #89cbdf solid;
         outline: none;
         background: none;
      }

   }

}

// /k-splitter

#horizontal-panel {
   background-color: transparent;
   &.hide-object-navigator {
      > .k-splitbar {
         display: none;
      }
   }
}

// recent tasks actually, softened edge
#bottom-panel-pane {
   box-shadow: 0 -2px 3px 3px rgba(197, 197, 197, .2);
}

// todo: end splitter refactor

