@import "mixins";

@headerGradientTopColor: #F5F5F5;
@headerGradientBottomColor: #FFFFFF;
@textMediumGrayColor: #666666;

.settingsBlock {
   margin: 0;
   .flexbox();
   .flex-direction(column);
   height: 100%;
   width: 100%;
}

.settingsBlockTitle {
   font-weight: bold;
   color: @textMediumGrayColor;
   margin-left: 7px;
}

.settingsBlockHeader {
   margin-bottom: 12px;
   background: @headerGradientTopColor; /* Old browsers */
   background: -moz-linear-gradient(top,  @headerGradientTopColor 0%, @headerGradientBottomColor 100%); /* FF3.6+ */
   background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,@headerGradientTopColor), color-stop(100%,@headerGradientBottomColor)); /* Chrome,Safari4+ */
   background: -webkit-linear-gradient(top,  @headerGradientTopColor 0%,@headerGradientBottomColor 100%); /* Chrome10+,Safari5.1+ */
   background: -o-linear-gradient(top,  @headerGradientTopColor 0%,@headerGradientBottomColor 100%); /* Opera 11.10+ */
   background: -ms-linear-gradient(top,  @headerGradientTopColor 0%,@headerGradientBottomColor 100%); /* IE10+ */
   background: linear-gradient(to bottom,  @headerGradientTopColor 0%,@headerGradientBottomColor 100%); /* W3C */
   filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='@headerGradientTopColor', endColorstr='@headerGradientBottomColor',GradientType=0 ); /* IE6-9 */
}

.settingsBlockButton {
   float: right;
   margin-left: 8px;
}

vx-tabbed-view {
  &.flexbox-column {
    .nav.nav-tabs {
      height: auto;
    }
  }
}