// @import 'markdown';

// Clarity Variables
@clr-nav-element-color: #565656;
@clr-nav-element-hover-color: #000;
@clr-selected-highlight-color: #d9e4ea;
@clr-selected-hover-color: #eee;
// @clr-nav-element-font-name: "Proxima Nova Regular" @dev-center-font;
// @clr-selected-highlight-font-name: "Proxima Nova Semibold" @dev-center-font;
@clr-selected-highlight-font-size: 13px;
@clr-selected-highlight-line-height: 24px;


// Clarity Mixins
.clr-nav-element() {
  font-size: @clr-selected-highlight-font-size;
  color: @clr-nav-element-color !important;
}
.clr-nav-element-selected() {
  .clr-nav-element;
  background: @clr-selected-highlight-color;
}
.clr-nav-element-hover() {
  .clr-nav-element;
  background: @clr-selected-hover-color;
  color: @clr-nav-element-hover-color !important;
}
