@import "focus";

.user-tabbing .multi-level {

   .toc-headers-view:focus {
      outline: @outline-focus;
      outline: @outline-webkit-focus;
      outline-offset: -1px;
      overflow-x: hidden;
      overflow-y: auto;

      .state-focused {
         outline: @outline-focus;
         outline: @outline-webkit-focus;
         margin-left: 1px;
         margin-right: 1px;
      }

      li.active {
         margin-top: 1px;
         margin-left: 1px;
         margin-right: 1px;
      }

      li:hover {
         margin-left: 1px;
         margin-right: 1px;
      }
   }
}

.multi-level {
   .vui-icon-caret-black-down,
   .vui-icon-caret-black-right {
      margin-right: 2px;
   }

   .toc-tabs-title {
      line-height: 24px;
      cursor: pointer;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
   }

   // Overridding some rules applied from VUI
   .vui-toc-tabs {
      box-sizing: border-box;
      width: 190px;
   }

   // Fix indentation of the toc-tabs elemens
   // toc-tabs - 1st level
   .vui-toc-tabs ul.no-category-title > li > a {
      padding-left: 25px;
   }

   // toc-tabs - 2nd level
   .vui-toc-tabs ul:not(.no-category-title) > li > a {
      max-width: 138px;
      padding-left: 39px;
   }

   // Raise the vui-icon arrow to align to the text
   .toc-tabs-title [class*=vui-icon] {
      margin-top: -2px !important;
   }

   .vui-tab-content {
      display: block;
      left: 200px;
   }
}