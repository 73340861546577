/* Copyright 2015 VMware, Inc. All rights reserved. -- VMware Confidential */
/*
 * Styles for the master-details layout used in events- and tasks- consoles.
 */

@import "mixins";
@import "focus";

.user-tabbing {
   /* Kendo horizontal spliter */
   div.k-splitbar.k-splitbar-horizontal.k-state-focused {
      outline: @outline-focus;
      outline: @outline-webkit-focus;
   }
   /* Kendo vertical spliter */
   div.k-splitbar.k-splitbar-vertical.k-state-focused {
      outline: @outline-focus;
      outline: @outline-webkit-focus;
   }
}

.split-view {
   /* Expand splitter-content vertically and remove borders */
   .k-splitter {
      height: 100%;
   }
   .k-widget {
      border-style: initial;
   }

   /* Details panel styles */
   .details {
      background-color: white;
      overflow: auto;
      .no-items-selected {
         font-weight: bold;
         font-size: large;
         color: #A6A6A6;
      }
      .content {
         padding: 20px;
         .half-size {
            width:50%;
         }
      }
   }
}

.horizontal-flex-container {
   .flexbox();
   .flex-direction(row);
}

.centered-content-container {
   .flexbox();
   .justify-content(center); /* align horizontal */
   .align-items(center); /* align vertical */
}

.property-value {
   padding: 5px;
   .property {
      font-weight: bold;
      min-width: 90px;
      padding-right: 10px;
      /* This is needed otherwise min-width is ignored
         for <span> elements in IE. */
      display: inline-block;
   }
}

.iso-chooser-splitter {
   min-height: 400px;

   [kendo-splitter] {
      width: 100%;
      min-height: 400px;
      border: 0;
      background: transparent;

      // TODO remove this workaround once the truncation of the splitter
      // with modified splitter-bar-s is fixed
      border-right: 1px solid #ccc;
   }

   .chooser-panel {
      border: 1px solid #ccc;
      background: white;

      .flexbox();
      .flex-direction(column);
   }

   .chooser-panel-content {
      .flex(1, 1, auto);
      overflow-y: auto;
   }

   .chooser-panel-title {
      .flex(0, 0, auto);
      padding: 8px;
      border-bottom: 1px solid #ccc;
      background-color: rgb(231, 235, 239);
   }

   .file-chooser {
      ul {
         list-style-type: none;
         margin: 0;
      }
      li {
         line-height: 20px;
         padding: 2px;
         border-bottom: 1px solid #eee;
         cursor: pointer;
         &:hover {
            background-color: rgb(198, 231, 255);
         }
         &.selected {
            background-color: rgb(163, 215, 255);
         }
      }
   }

   .file-information {
      .chooser-panel-content {
         padding: 3px;
      }
   }
}

.custom-split-view {
   @media only screen and (max-width: 1024px) {
      .flexbox();
      .flex-direction(column);
   }
   @media only screen and (min-width: 1025px) {
      .flexbox();
      .flex-direction(row);
   }
}