@import 'mixins';

.vui-stack-view .stack-view-table .stack-view-row-i .stack-view-cell [class*=icon],
.vui-stack-view .stack-view-table .stack-view-row .stack-view-cell [class*=icon] {
  position: static;
}

.vui-stack-view .stack-view-table .stack-view-row-i:first-of-type .stack-view-cell {
  padding: 5px 5px;

  &.warning {
    background-color: rgb(251, 214, 214);
  }
}

.vui-stack-view {
  .stack-view-row-i:not(:hover), .vui-stack-view .stack-view-row:not(:hover) {
    .stack-view-row-action {
      display: none;
    }
  }
}

.vui-stack-view-padded {
  margin-bottom: 11px;
}

.stack-view-cell {
  &.respect-newlines {
    white-space: pre-line;
  }

  .right-arrow, .down-arrow {
    margin: 2px 0 0 0;
  }
}

label.stack-view-cell {
  font-weight: normal;
}

.hide-expansion-arrow {
  // Don't allow the row to be expanded by clicking its cell
  .stack-view-cell:first-child {
    pointer-events: none;
  }
  .stack-view-cell:before {
    // Don't show the expansion arrow
    display: none !important;
  }
}

.stack-view {
  ul {
    margin: 0;

    li {
      list-style: none;
    }
  }
}