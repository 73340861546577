
@import "focus";

@portletsOffset: 10px;
@arrowBorderWidth: 4px;
@arrowBorderColor: #333;
@portletTitleBarHeight: 30px;
@portletBackgroundColor: #FFF;
@portlet-line-height: 24px;
@clr-link-color: #0079b8;
@clr-link-hover-color: #004a70;

.user-tabbing {
  .vx-portlet {
    /* Portlet title */
    .portlet-titlebar:focus {
      outline: @outline-focus;
      outline-offset: -1px;
      outline: @outline-webkit-focus;
    }
    /* Portlet footer links/actions  */
    .vx-portlet-footer a:focus {
      outline: @outline-focus;
      outline: @outline-webkit-focus;
    }
  }
}

.vx-portlets {
  display: flex;
  flex-direction: row;
  flex: 1 1 auto;
  height: 100%;
  margin-top: @portletsOffset;

  &:not(.ui-sorting) {
    .col {
      .vx-portlet {
        .portlet-titlebar {
          .arrow {
            // the transition easing should not be available
            // if the items are being reordered/rebuilt
            -webkit-transition: all 300ms ease-in 0s;
            -moz-transition: all 300ms ease-in 0s;
            -o-transition: all 300ms ease-in 0s;
            transition: all 300ms ease-in 0s;
          }
        }
      }
    }
  }

  .col {
    flex: 1 1 100%;
    display: flex;
    flex-direction: column;

    &:not(:first-child) {
      margin-left: @portlet-line-height/2;
    }

    .vx-portlet {
      box-shadow: 1px 1px 4px 0px rgba(0,0,0,0.10);
      min-height: 30px;
      border: 1px solid lightGrey;
      border-radius: 3px;
      overflow: auto;
      background: white;
      margin: 0 0 @portlet-line-height/2 0;
      flex-shrink: 0;
      box-sizing: border-box;

      &.placeholder {
        opacity: 0.5;
        background: lighten(lightSkyBlue, 20%);
      }

      .portlet-titlebar {
        cursor: pointer;
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
        margin: 0;
        padding: 12px @portletsOffset;
        box-sizing: border-box;
        position: relative;
        overflow: hidden;

        &:hover {
          .arrow {
            svg {
              polyline,
              path {
                fill: @clr-link-hover-color;
              }
            }
          }
        }
        .titlebar-text {
          flex: 1 1 100%;
          display: inline-flex !important;
          white-space: nowrap;
          overflow: hidden !important;
          padding: 0 !important;
          box-sizing: border-box;
          max-width: initial !important;
        }
        .arrow {
          display: inline-flex;
          svg {
            path {
              fill: @clr-link-color;
            }
          }
        }
      }

      .vx-portlet-content {
        padding: 0 @portletsOffset @portletsOffset/2 @portletsOffset;

        [kendo-grid],
        [vui-datagrid] {
          &:extend(.flex-grid);
        }
      }

      .vx-portlet-footer {
        padding: 0 @portletsOffset @portletsOffset/2 @portletsOffset;
        display: inline-flex;
        .vx-portlet-footer-link {
          margin-right: @portletsOffset;
        }
      }

      // if card is collapsed...
      &.collapsed {
        // rotate the arrow in the card-header
        > .portlet-titlebar {
          .arrow {
            -webkit-transform: rotate(180deg);
            -moz-transform: rotate(180deg);
            -o-transform: rotate(180deg);
            -ms-transform: rotate(180deg);
            transform: rotate(180deg);
          }
        }
        // hide card-block
        // and card-footer
        .vx-portlet-content,
        .vx-portlet-footer {
            display: none;
        }
      }

      &.vx-property-view {
        width: auto;
        height: auto;
        flex-shrink: 0;
      }

    }
  }
}

@media (max-width: 1000px) {
  .vx-portlets,
  .vx-portlets .col {
    display: block;
    margin: 0 !important;
  }
}

.portlets-activity-indicator {
  height: 200px;
  position: relative;
}