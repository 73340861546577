@import "mixins";
@import "colors";
@import "focus";

[vx-tab-headers] {
   padding: 10px 0;
}

[vx-tab-content] {
   position: relative;
}

.user-tabbing {
   [vx-navi-tab-headers], .vx-tab-wrapper {
      /* Vui tabs */
      .nav > li:focus {
         outline: @outline-focus;
         outline-offset: -1px;
         outline: @outline-webkit-focus;
      }

      /* Vui second level tabs */
      .nav-secondary {
         > button:focus {
            outline: @outline-focus;
            outline: @outline-webkit-focus;
         }
      }
   }
}

[vx-tab-pane] {
   position: absolute;
   top: 0;
   right: 0;
   bottom: 0;
   left: 0;
}

[vx-tabs] [vx-tab-pane] {
   display: none;
}

[vx-tabs] [vx-tab-pane].active {
   display: block;
}

[vx-tabs-flexy] [vx-tab-pane].active {
   .flexbox();
}

// -----------------------------------------
// rendered tabs in a panel
.vui-panel,
.vui-wizard,
.modal-dialog {

   // core nav header component, mimics Clarity tabs
   .vx-tab-wrapper,
   [vx-navi-tab-headers],
   .wizard-modal-content,
   .modal-content {

      // -- subclassed modifiers -----

      // primary level tabs
      &[level="first"],
         // reused
      &[level="details"] {

         padding: 16px 20px; // centered and allocate whitespace balance

         .nav.nav-tabs {
            @tab1-shape-hover-border: @clr-actionblue;
            @tab1-shape-active-border: @clr-actionblue;
            @tab1-layout-default-border: @clr-light-gray;

            @tab1-text-default-foreground: @clr-gray;
            @tab1-text-active-foreground: @clr-black;
            @tab1-layout-gutter: 30px;
            @tab1-highlight-border-width: 3px;

            height: 29px; // tight fit until official clarity tabs onboard
            border-bottom: 1px solid @tab1-layout-default-border;
            background: none;
            padding-bottom: 0;
            // until there is a real RWD tab system, will be hiding tabs that are clipped, truncated due to *any* reason
            // it could be the language of choice, it could be the user has a low resolution; that doesn't matter at this point
            // we will work on a progressive disclosure icon or something to let the user know there are more tabs
            // in addition, this tab set widget comes from VUI. It is deprecated as of 2017
            overflow: hidden;

            > LI {
               font-size: 14px;
               height: inherit;
               margin-bottom: 0; // reset VUI

               &:not(:first-child) {
                  margin-left: @tab1-layout-gutter/2;
               }
               &:not(:last-child) {
                  margin-right: @tab1-layout-gutter/2;
               }

               > A {
                  color: @tab1-text-default-foreground;
                  margin: 0;
                  padding-top: 1px;
                  padding-bottom: 16px;
               }

               // ~~~~~~~~~~~~ state modifiers ~~~~~~~~~~~~~
               &.active {

                  //box-shadow: inset 0 -@tab1-highlight-border-width 0px @tab1-shape-active-border;

                  // linked trigger
                  > A {
                     font-size: inherit; //reset
                     font-weight: inherit;
                     color: @tab1-text-active-foreground;

                     &:hover {
                        border-bottom-width: 3px; // override clarity
                     }
                  }

               }

            }

         }
      }

      &[level="second"] {
         padding: 8px 0;
         display: flex; // keep

      .btn-group {
            @tab2-highlight-color: @clr-actionblue;
            @tab2-shape-active-background: @clr-actionblue;

            @tab2-shape-default-background: @clr-white;
            @tab2-shape-default-border: @clr-actionblue;
            @tab2-text-default-foreground: @clr-actionblue;

            @tab2-text-hover-foreground: extract(@clr-actionblue-list, 6);
            @tab2-shape-hover-background: extract(@clr-actionblue-list, 1);
            @tab2-highlight-active-border: extract(@clr-actionblue-list, 2); // click on tab

            @tab2-shape-hover-border: @clr-actionblue;
            @tab2-text-active-foreground: @clr-white;

            @tab2-layout-gutter: 1px;

            background-color: @tab2-shape-default-border;
            padding: 1px; // inset
            border-radius: 3px;
            display: flex;
            overflow-x: hidden; // crop label; do

            > button:not(:last-child),
            > button.active:not(:last-child),
            > button:last-child {
               border-color: @tab2-highlight-color;
            }
            > .btn {
               text-transform: none;
               margin: 0;
               background-color: @tab2-shape-default-background;
               color: @tab2-text-default-foreground;
               border: 0;

               cursor: pointer;

               padding: 0 15px;
               letter-spacing: 0.1px;
               font-size: 13px;
               font-weight: 400;

               // logical states
               // edge elements
               &:not(:first-child) {
                  margin-left: @tab2-layout-gutter/2;
               }
               &:not(:last-child) {
                  margin-right: @tab2-layout-gutter/2;
               }

               // intra button layout
               &:not(:first-child):not(:last-child) {

               }

               // interactions

               // softened inset shadow
               &.active {
                  .active-tab-btn(@tab2-shape-active-background, @tab2-text-active-foreground);
               }

               &:not(.active) {

                  &:active {
                     outline: none;
                     // inset effect shading
                     box-shadow: inset 0 1px 0 @tab2-highlight-active-border;
                  }

                  &:hover {
                     color: @tab2-text-hover-foreground;
                     background-color: @tab2-shape-hover-background;
                  }
               }

            }
         }
      }

      // Known use: as "pills" on the "networks" tab of Host objects

      // child panels reveal the property view details usually of a selection from a parent grid
      // resembles the primary tab for now; however it should get a unique look and concept
      &[level="details"] {
         @tab3-shape-bottom-padding: 16px - 10px;

         padding: 0 0 @tab3-shape-bottom-padding 0; // centered and allocate whitespace balance
         // -- /property view child details --
      }

      // /core nav header component
   }

   .vx-tab-wrapper {
      box-sizing: border-box;
   }
   // /vui-panel
}

.vui-secondary-tabs .btn-group.pills .btn {
   float: none;
}

.vui-panel [vx-navi-tab-headers][level=second] {
   display: block;
   .nav-secondary {
      display: inline-flex;
      overflow: visible;
   }
}