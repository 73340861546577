.failed-tasks-notification {
   list-style-type: none;
   margin: 0 0 10px;

   > .item {
      display: table-row;

      > .name, .value {
         float: none;
         display: table-cell;
         width: auto;
      }

      > .name {
         white-space: nowrap;
      }

      .value {
         padding-left: 8px;
         white-space: pre-line;// collapse white spaces
      }
   }
}