/* Copyright 2015 VMware, Inc. All rights reserved. -- VMware Confidential */
/*
 * Styles for the event console.
 */

.split-view .details {
   .ask-vmware {
      margin-left: auto;
   }
   .property-value .property.date-time {
      font-weight: initial;
   }
   .causes-list {
      margin: 0px 0px 0px 20px;
      li {
         line-height: 25px;
      }
   }

   /* Related events list modifications to look like in the flex */
   div[vui-datagrid].related-events {
      .k-grid.k-widget {
         border: 1px solid #d8d8d8;
      }
      .k-grid-content {
         height: 100% !important;
         overflow-y: auto;
      }
      .k-grid-header, .k-grid-pager {
         display: none;
      }
   }
}