// For layouts with flex-direction: column which contain grids,
// we still need to "convert" from flex to absolute positioning
// for the div that contains a vui-datagrid. Kendo grids seem
// to only be able to size themselves if the height of their
// container is fixed (e.g. 250px) or was set to 100% via
// absolute positioning.

@import "focus";
.user-tabbing {
  .vui-action-bar {
    /* Vui action bar items */
    ul li a:focus {
      outline: @outline-focus;
      outline: @outline-webkit-focus;
    }
  }
  .k-grid-header .k-header {
    /* Kendo grid header menu */
    .k-header-column-menu:focus {
      outline: @outline-focus;
      outline: @outline-webkit-focus;
    }
    /* Kendo grid header */
    .k-link:focus {
      outline: @outline-focus;
      outline: @outline-webkit-focus;
    }
  }
}

.flex-to-absolute-positioning-container {
  flex-grow: 1;
  position: relative;
}

.flex-to-absolute-positioning-container-inner {
  position: absolute;
  width: 100%;
  height: 100%;
}

div[vui-datagrid] {
  .k-grid .k-header.k-grid-toolbar {
    background-color: transparent !important;
  }

  .k-block,
  .k-widget:not(.k-tooltip) {
    background-color: transparent !important;
  }

  .k-grid-content {
    background: white;
  }


  // preparing a fix for
  // this bug
  // https://bugzilla.eng.vmware.com/show_bug.cgi?id=1739212
  // Empty datagrids do not look like they are empty
  // onDataBound a classname "no-items-to-display"
  // should be added to the k-grid
  // also an attribute no-items-text="No items to display"
  // should be added to the ..k-virtual-scrollable-wrap element
  .k-grid.no-items-to-display {
    .k-virtual-scrollable-wrap:after {
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      content: attr(no-items-text);
    }
    .k-pager-wrap.k-grid-pager .k-pager-info {
      display: none;
    }
  }
}

.grid-container-with-cell-ellipsis {
  td {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

// For layout of grid which automatically sizes to the height if its parent.
// In order to use it make sure the parent is:
//    1. Make the parent with { display: flex }
//    2. Re-consider option 1 or set height yourself.
// The grid will shrink and grow depending on the available space.
.flex-grid {
  .flexbox();
  .flex-auto();
  .flex-direction(column);
  width: 100%;

  .k-grid.k-widget {
    .flexbox();
    .flex-auto();
    .flex-direction(column);
    height: 100%;
  }

  .k-grid.k-widget .k-grid-header,
  .k-grid.k-widget .k-grid-footer {
    flex-shrink: 0;
  }

  .k-grid.k-widget .k-grid-content {
    .flex-auto();
    .flexbox();
    height: auto !important;
    position: relative;
  }

  .k-grid.k-widget .k-grid-content .k-virtual-scrollable-wrap {
    height: 100% !important;
    position: absolute;
    right: 16px;
    left: 0;
  }

  // fixing a visual glitch
  // with the vertical scrollbar in IE11
  .k-grid.k-widget .k-grid-content .k-scrollbar-vertical > div {
    background-color: white;
  }
}

//Styling for vui datagrid checkbox container
div.vui-checkbox-container {
  position: relative;
  width: 100%;
  min-width: 40px;
  height: 28px;
  margin-left: -12px;
  margin-right: -12px;
  text-overflow: clip; /*firefox has funny dots*/
  //invisible textarea that fills the whole cell
  > input:last-child {
    cursor: default;
    width: 100%;
    height: 100%;
    opacity: 0;
    text-indent: -9999em; /*hides the blinking caret on IE*/
  }
  > input[type="checkbox"] {
    position: absolute;
    top: 8px;
    left: 12px;
  }
}

.k-grid {
  .k-group-cell {
    text-overflow: clip;
  }
}

.vui-datagrid {
  &.k-widget {
    background: white;
  }
}

.shadow-box{
  box-shadow: inset 0 0 0 1px #33ccff;
}

.vui-datagrid.k-grid.k-widget .k-grid-content tbody tr td.k-state-focused,
 div[vui-datagrid] .k-grid.k-widget .k-grid-content tbody tr td.k-state-focused,
 .vui-datagrid.k-grid.k-widget .k-grid-content-locked tbody tr td.k-state-focused,
 div[vui-datagrid] .k-grid.k-widget .k-grid-content-locked tbody tr td.k-state-focused{
  &:extend(.shadow-box);
}

.vui-datagrid.k-grid.k-widget .k-grid-header th.k-header.k-state-focused,
 div[vui-datagrid] .k-grid.k-widget .k-grid-header th.k-header.k-state-focused{
  &:extend(.shadow-box);
}


.vui-datagrid.k-grid.k-widget .k-grid-header,
div[vui-datagrid] .k-grid.k-widget .k-grid-header {
  border-right: 0;
}

.k-grid {
  .k-grid-header {
    position: relative;

    .k-grid-header-wrap {
      border-right: 0;
    }

    &:before,
    &:after {
      content: " ";
      height: 100%;
      width: 1px;
      background: #ccc;
      position: absolute;
      top: 0;
      z-index: 2;
    }
    &:before {
      left: 0;
    }
    &:after {
      right: 0;
    }
  }
}
