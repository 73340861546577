.remote-plugin-extension-context-selector {
   display: flex;
   flex-direction: column;
   position: relative;

   .remote-plugin-extension-context-selector-header {
      flex: 0 0 auto;

      display: flex;
      flex-direction: row;
      align-items: center;

      padding: 0 24px;
      border-bottom: 1px solid transparent;
      margin-left: -10px;
      line-height: 48px;

      // Default theme
      & {
         background-color: #FFFFFF;
         border-color: #CCCCCC
      }

      // Dark theme
      [data-theme="dark"] & {
         background-color: #17242B;
         border-color: #485764
      }

      h6 {
         flex: 0 0 auto;
         margin-top: 0;
      }

      .instance-dropdown-container {
         flex: 1 1 100%;
         overflow: hidden;
      }
   }

   & > [vx-view] {
      flex: 1 1 100%;
   }
}

@base-remote-plugin-icon-size: 32px;

.remote-plugin-icon(@targetSize;
      @margin-top: 0px; @margin-right: 0px;
      @margin-bottom: 0px; @margin-left: 0px) {

   @scale: unit(@targetSize) / unit(@base-remote-plugin-icon-size);
   @margin-right-offset: @targetSize - @base-remote-plugin-icon-size;
   @margin-bottom-offset: @targetSize - @base-remote-plugin-icon-size;

   width: @base-remote-plugin-icon-size;
   height: @base-remote-plugin-icon-size;
   transform: scale(@scale, @scale);
   transform-origin: 0 0;
   margin-top: @margin-top;
   margin-right: ~"calc(@{margin-right} + @{margin-right-offset})";
   margin-bottom: ~"calc(@{margin-bottom} + @{margin-bottom-offset})";
   margin-left: @margin-left;
}

.remote-plugin-icon-navigation {
   @margin-top: 1px;
   @margin-right: 4px;
   @margin-bottom: 0px;
   @margin-left: 4px;
   .remote-plugin-icon(16px, @margin-top, @margin-right, @margin-bottom, @margin-left) !important;
   max-width: none !important;
   max-height: none !important;
   vertical-align: text-bottom !important;
}

.remote-plugin-icon-menu {
   @margin-top: -2px;
   @margin-right: 4px;
   @margin-bottom: 0px;
   @margin-left: 0px;
   .remote-plugin-icon(16px, @margin-top, @margin-right, @margin-bottom, @margin-left) !important;
}

.remote-plugin-icon-action {
   @margin-top: -2px;
   @margin-right: 4px;
   @margin-bottom: 0px;
   @margin-left: 0px;
   .remote-plugin-icon(16px, @margin-top, @margin-right, @margin-bottom, @margin-left) !important;
}

html-bridge-plugin-modal-iframe {
   display: block;
   width: 100%;
   height: 100%;
}

.modal.sandbox-modal {
   .modal-dialog {
      width: auto;
      height: auto;

      .modal-content {
         @clarity-modal-content-padding: 0.125rem;
         @clarity-modal-header-padding: 1rem;

         margin: 0;
         padding: 0 @clarity-modal-content-padding;

         .modal-header {
            box-sizing: border-box;
            margin: 0;
            padding: @clarity-modal-header-padding;
            min-height: 0;
            max-width: 100%;

            .modal-title {
               .modal-title-icon {
                  flex: 0 0 auto;
               }

               .modal-title-text {
                  width: 100%;
                  word-wrap: break-word;
                  overflow-wrap: break-word;
                  overflow: hidden;
               }
            }
         }

         .modal-body {
            box-sizing: border-box;
            margin: 0;
            padding: 0;
            min-width: 0;
            max-width: 100%;
         }
      }
   }
}
