.formPane {
  .flexbox();

  .userRolesPane {
    .flex(1,0, 50%);
    padding-right: 10px;
  }

  .privilegesPane {
    .flex(1,0, 50%);
    padding-left: 10px;
  }

  // Hack to resolve horizontal scrollbar appearing in add permissions modal
  // Per Aditya Bhandari, this issue should be resolved in clarity 0.4.1
  .row {
    margin-left: 0px;
    margin-right: 0px;
  }
}
