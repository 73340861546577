@import "mixins";

.action-confirmation {
  .vx-alert-body {
    .flexbox();
  }

  .vx-alert-text  {
    .flex(1, 1, auto);
    margin: auto;
    white-space : pre-line;
    text-align: left;
  }

  .vx-alert-icon  {
    margin: auto;
    padding: 0 10px 5px;
  }

  .vx-alert-footer  {
    padding: 5px 15px 8px;
    text-align: right;
  }

  .vx-alert-footer span {
    padding: 0 3px;
  }

  .vx-modal-titlebar {
    font-size: 12px;
  }
}

[vx-alert] {
  .vx-modal-backdrop {
    z-index: 1100;
  }
  .vx-alert-modal {
    z-index: 1100;
  }
}

.icon-warning-32 {
  background-image: url(../img/warning-32x32.png);
  display: inline-block;
  vertical-align: middle;
  background-repeat: no-repeat;
  margin: 1px 4px 0px;
  height: 32px;
  width: 32px;
}



.informational-dialog-text {
  white-space: pre-line;
}

.vui-popup.vui-dialog {
  min-height: initial;
  overflow: hidden;
}
.vui-wizard .wizard-panel-TOC .wizard-steps-list li a.no-content:before {
  content: '';
}
