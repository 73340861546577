.shares-control {
   vx-value-in-units {
      margin-top: 0px;

      .input-group-btn {
         line-height: 18px;

         button.dropdown-toggle {
            border-width: 1px;
         }
      }
   }
}
