.white-space-normal {
  white-space: normal;
}
.white-space-nowrap {
  white-space: nowrap;
}
.white-space-pre {
  white-space: pre;
}
.white-space-pre-line {
  white-space: pre-line;
}
.white-space-pre-wrap {
  white-space: pre-wrap;
}
/**
 * The Main Less file for the App Lib CSS
 *
 */
.sandbox-iframe {
  border: 0px;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  display: block;
}
.sandbox-iframe-dialog {
  border: 0px;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 28px;
  bottom: 0;
  right: 0;
  left: 0;
}
/** TODO ajaffer: temp. fix to ask kendo grid to show ellipsis
This should be fixed in vui **/
.k-grid td {
  /* Override for columns that show wrapped text (IP-addresses, Notes) */
}
.k-grid td * {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.k-grid td .line-wrap {
  white-space: normal;
}
div.k-animation-container #cols_menu {
  display: none;
}
.vertical-flex-container {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}
.sub-tab-content {
  margin-top: 10px;
  height: 100%;
  -webkit-flex: 1 1 100%;
  -ms-flex: 1 1 100%;
  flex: 1 1 100%;
  position: relative;
  overflow: hidden;
}
.sub-tab-content > .content {
  position: absolute;
}
.vx-multi-extension-view > :not(:last-child) {
  margin-bottom: 10px;
}
.vui-primary-tabs {
  overflow: hidden;
}
.progress-text {
  padding-top: 5px;
  font-weight: bold;
}
.progress-bordered {
  border: solid 1px black;
  border-color: lightgray;
  width: 200px;
  padding: 5px 5px 10px 5px;
}
.progress-centered {
  position: absolute;
  top: 50%;
  right: 50%;
  margin-right: -100px;
  margin-top: -50px;
  background: white;
}
a[disabled] {
  pointer-events: none;
  opacity: .5;
}
.disabled-link {
  pointer-events: none;
  cursor: default;
  color: #9A9A9A !important;
}
.non-navigatable-link {
  pointer-events: none;
  cursor: default;
  color: inherit !important;
}
.non-navigatable-link:hover {
  text-decoration: none;
}
.results-footer-searching-text-black {
  padding-left: 5px;
  padding-bottom: 3px;
  padding-top: 3px;
}
.results-footer-searching-text-blue {
  padding-left: 5px;
  padding-bottom: 3px;
  padding-top: 3px;
  border-top: 1px solid #D2D3D4;
}
.results-footer-searching-text-blue .all-results-link {
  color: #369 !important;
}
.vertical-aligned-text {
  vertical-align: middle;
}
.disabled-text {
  font-style: italic;
  color: gray;
}
.numeric-text {
  vertical-align: middle;
  text-align: right;
  width: 100%;
  display: inline-block;
}
.no-items-selected-container {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}
.no-items-selected-text {
  font-size: 16px;
  font-weight: bold;
  color: #A0A0A0;
}
.vx-master-details-view .master-details-details-container {
  width: 100%;
  height: calc(100% - 1px);
}
.vx-master-details-view .nav.nav-tabs {
  margin-top: 0px;
}
.vx-master-details-view vx-tabbed-view ul.nav-tabs > li,
.tabbed-object-view ul.nav-tabs > li {
  flex: 0 1 auto;
  overflow-x: auto;
  overflow-y: hidden;
}
.vx-master-details-view vx-tabbed-view ul.nav-tabs > li > a,
.tabbed-object-view ul.nav-tabs > li > a {
  text-overflow: ellipsis;
  overflow: hidden;
  display: inline-block;
  white-space: nowrap;
  padding-left: 0px;
  padding-right: 0px;
  width: 100%;
}
.vx-master-details-view {
  min-height: 450px;
  /*makes view usable for super-small screen heights*/
}
.vx-master-details-hide-splitter .k-splitbar {
  display: none;
}
[class^="vx-icon-"],
[class*=" vx-icon-"] {
  display: inline-block;
  vertical-align: text-bottom;
  margin: 1px 4px 0;
}
[class^="vsphere-icon-"],
[class*=" vsphere-icon-"] {
  display: inline-block;
  vertical-align: text-bottom;
  margin: 1px 4px 0;
}
.word-wrap {
  word-wrap: break-word;
}
p:first-child {
  display: inline-block;
}
.relative-container {
  position: relative;
}
.absolute-container {
  position: absolute;
}
.centered-text-block {
  height: 100%;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 10%;
}
.object-not-found {
  background: #ececec;
}
input[type=number].spinfree::-webkit-inner-spin-button,
input[type=number].spinfree::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}
input[type=number].spinfree {
  -moz-appearance: textfield;
}
clr-stack-block.warning .stack-block-label {
  background-color: #fbd6d6;
}
clr-tooltip.tooltip {
  opacity: 1;
}
body div.modal-backdrop + clr-modal > div.modal {
  z-index: 1060;
}
@clr-grays : (label: gray, base: @clr-gray, hex-colors: @clr-gray-list);
@clr-blues : (label: blue, base: @clr-blue, hex-colors: @clr-blue-list);
@clr-actionblues : (label: action-blue, base: @clr-actionblue, hex-colors: @clr-actionblue-list);
@clr-actionpurples : (label: action-purple, base: @clr-actionpurple, hex-colors: @clr-actionpurple-list);
@clr-reds : (label: stoplight-red, base: @clr-red, hex-colors: @clr-red-list);
@clr-yellows : (label: stoplight-yellow, base: @clr-yellow, hex-colors: @clr-yellow-list);
@clr-greens : (label: stoplight-green, base: @clr-green, hex-colors: @clr-green-list);
@clr-base-colors : (label: base, base: @clr-white, hex-colors: @clr-base-color-list);
.user-tabbing .focusable-area *:focus {
  outline: 1px dotted #212121;
  outline: 5px auto -webkit-focus-ring-color;
}
.user-tabbing [role=tree].view-focused [role=treeitem].state-focused {
  outline: 1px dotted #fafafa;
  outline-offset: -0.08033rem;
  outline: 1px dotted #212121;
  outline: 5px auto -webkit-focus-ring-color;
}
.clr-action-bar clr-icon {
  color: #565656;
}
.clr-action-bar .clr-no-border-button {
  outline: 0;
}
.clr-action-bar .clr-no-border-button span {
  color: #0079b8;
}
.k-menu.k-menu-vertical.k-context-menu {
  /* Size the menu based on the contents, but truncate
    * if they don't fit in 400px */
  width: auto;
  max-width: 400px;
  /* Some hacks around pointer-events:none, which prevent the tooltip from
    * showing on the menu header */
}
.k-menu.k-menu-vertical.k-context-menu span.k-link {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.k-menu.k-menu-vertical.k-context-menu .menu-header span.k-link {
  pointer-events: auto;
}
.k-menu.k-menu-vertical.k-context-menu .menu-header.k-state-hover span.k-link {
  color: #666;
  cursor: default;
}
/** These css rules are needed for menu scroller
 * https://jira.eng.vmware.com/browse/VSUIP-1811
 */
#vui-actions-menu.k-menu.k-menu-vertical.k-context-menu.vui-menu-scroller {
  position: static!important;
  overflow-y: auto;
  overflow-x: hidden;
}
#vui-actions-menu.k-menu.k-menu-vertical.k-context-menu.vui-menu-scroller > li {
  position: static;
}
#vui-actions-menu.k-menu.k-menu-vertical.k-context-menu.vui-menu-scroller > li > .k-link:first-child {
  position: relative;
}
#vui-actions-menu.k-menu.k-menu-vertical.k-context-menu.vui-menu-scroller > li.k-state-border-down > .k-animation-container {
  width: auto!important;
}
#vui-actions-menu.k-menu.k-menu-vertical.k-context-menu.vui-menu-scroller > li.k-state-border-down > .k-animation-container > ul.k-menu-group:first-child {
  position: relative!important;
}
/* This fixes the missing separators between child menus/actions
 * in the tree view context menu */
li.k-item.is-separated {
  border-top-width: 1px !important;
}
ul.k-group.k-menu-vertical ul.k-group.k-menu-group.k-popup.k-reset {
  max-width: 400px;
  width: auto;
}
.alarm-details-popup td {
  vertical-align: top;
  padding-top: 5px;
}
.alarm-details-popup table td:first-child {
  width: 120px;
  font-weight: bold;
}
.alarm-details-popup .actions {
  text-align: right;
}
.alarm-details-popup .actions button {
  margin-left: 5px;
  margin-top: 10px;
}
.charts-toolbar {
  background-color: #F2F2F2;
  border: 1px solid #DDDDDD;
  padding: 8px;
  font-weight: bold;
}
.charts-overview-title {
  font-weight: bold;
  margin: 5px;
}
chart-view {
  display: inline-block;
  vertical-align: top;
  border: 1px solid transparent;
  margin: 2px 4px;
}
chart-view .loading-text {
  display: none;
}
chart-view .centered-content {
  display: inline-block;
}
chart-view.loading {
  width: 500px;
  height: 330px;
  border: 1px solid #d6d6d6;
}
chart-view.loading .loading-text {
  height: 100%;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-align: center;
}
chart-view.loading .loading-text img {
  margin-bottom: 3px;
}
.half-width {
  width: 50%;
}
.column-meter {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}
.column-meter span {
  width: 40px;
  display: block;
  text-align: right;
  margin-right: 10px;
}
.column-meter meter {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  -webkit-flex: 1 1 100%;
  -ms-flex: 1 1 100%;
  flex: 1 1 100%;
}
.column-meter meter::-webkit-meter-bar {
  background: none;
  background-color: #e5e5e5;
}
.column-meter meter::-webkit-meter-optimum-value {
  background: none;
  background-color: #4679b9;
}
/**
 * Styles for the Application Control Bar
 */
.clr-nav-item {
  line-height: 18px;
  padding: 2px 0 2px 0;
}
.actions-menu,
.dropdown-menu {
  color: #565656;
}
.perf {
  line-height: 10px;
}
.perf-badge-threshold-exceeded {
  background-color: red;
}
.user-tabbing .main-nav HEADER .branding > a:focus {
  outline: 1px dotted #fafafa;
  outline-offset: -0.08033rem;
  outline: 5px auto -webkit-focus-ring-color;
}
.user-tabbing .main-nav HEADER .header-nav button:focus,
.user-tabbing .main-nav HEADER .settings button:focus {
  outline: 1px dotted #fafafa;
  outline-offset: -0.08033rem;
  outline: 5px auto -webkit-focus-ring-color;
}
.user-tabbing .main-nav HEADER .header-nav a[role='button'].nav-link:focus,
.user-tabbing .main-nav HEADER .settings a[role='button'].nav-link:focus {
  outline: 1px dotted #fafafa;
  outline-offset: -0.08033rem;
  outline: 5px auto -webkit-focus-ring-color;
}
.user-tabbing .main-nav HEADER .header-nav vsc-navigation-view-menu-item:focus,
.user-tabbing .main-nav HEADER .settings vsc-navigation-view-menu-item:focus {
  background-color: #eee;
}
.main-nav {
  position: relative;
}
.main-nav HEADER {
  padding: 0 0 0 24px;
  height: 2.60rem;
  align-items: center;
}
.main-nav HEADER .showFeedback {
  z-index: 1171;
}
.main-nav HEADER .branding {
  width: auto;
  min-width: auto;
  max-width: auto;
  padding-left: 0;
  padding-right: 20px;
}
.main-nav HEADER .branding > a {
  position: relative;
  padding-right: 4px;
}
.main-nav HEADER .branding .clr-icon {
  margin-right: 12px;
  width: 36px;
  height: 32px;
}
.main-nav HEADER .header-nav {
  display: block;
}
.main-nav HEADER .header-nav CLR-DROPDOWN.dropdown > .nav-link {
  opacity: 1;
}
.main-nav HEADER .header-nav CLR-DROPDOWN.dropdown > .nav-link > .dropdown-toggle {
  margin: 0 24px;
}
.main-nav HEADER .header-nav CLR-DROPDOWN.dropdown > .nav-link > .dropdown-toggle:not(.btn) {
  padding-left: 0.2rem;
  padding-right: 0.3rem;
}
.main-nav HEADER .header-nav CLR-DROPDOWN.dropdown > .nav-link > .dropdown-toggle .home-menu-link {
  color: #fafafa;
  opacity: 0.65;
}
.main-nav HEADER .header-nav CLR-DROPDOWN.dropdown > .nav-link > .dropdown-toggle .home-menu-link .nav-icon {
  padding: 0;
  width: auto;
  font-size: inherit;
  display: flex;
  align-items: center;
  background: transparent;
  border: none;
}
.main-nav HEADER .header-nav CLR-DROPDOWN.dropdown > .nav-link > .dropdown-toggle .home-menu-link:hover {
  text-decoration: none;
  opacity: 1;
}
.main-nav HEADER .header-nav CLR-DROPDOWN .dropdown-menu:focus {
  outline: 1px dotted #212121;
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -1px;
}
.main-nav HEADER .header-nav CLR-DROPDOWN .state-focused {
  outline: 1px dotted #212121;
  outline: 5px auto -webkit-focus-ring-color;
}
.main-nav HEADER .search-box {
  display: flex;
  margin-left: 12px;
  margin-right: 12px;
  flex: 0 1 100%;
  max-width: none;
  opacity: initial;
}
.main-nav HEADER .search-box:hover {
  opacity: initial;
}
.main-nav HEADER .search-box .search-icon {
  margin-right: 6px;
  opacity: 0.65;
}
.main-nav HEADER .search-box .search-icon:hover {
  opacity: 1;
}
.main-nav HEADER .search-box > [role="quickSearchView"] {
  display: flex;
  flex: 0 1 100%;
  align-self: center;
}
.main-nav HEADER .search-box > [role="quickSearchView"] .quickSearchContainer {
  display: flex;
  flex: 0 1 100%;
  margin: 0;
  position: relative;
}
.main-nav HEADER .search-box > [role="quickSearchView"] .quickSearchContainer INPUT[type="text"] {
  min-width: 150px;
  max-width: 150px;
  flex: 0 1 100%;
  text-overflow: ellipsis;
  padding-left: 6px;
}
.main-nav HEADER .search-box > [role="quickSearchView"] .quickSearchContainer .results-quicksearch-container {
  border-top: none;
  box-shadow: 2px 2px 3px #eee;
  width: inherit;
}
.main-nav HEADER .search-box > [role="quickSearchView"] .quickSearchContainer .results-quicksearch-container .results-padding-LR {
  border: 1px solid #ddd;
  border-radius: 0px 0px 3px 3px;
  border-top: none;
}
.main-nav HEADER .search-box > [role="quickSearchView"] .quickSearchContainer .results-quicksearch-container .results-padding-LR TABLE .results-cell-white ul > li {
  list-style-type: none;
}
.main-nav HEADER .search-box > [role="quickSearchView"] .quickSearchContainer .results-quicksearch-position {
  left: auto;
  right: auto;
  top: 42px;
  left: -22px;
}
.main-nav HEADER .settings {
  align-items: center;
}
.main-nav HEADER .settings .update-info {
  overflow: hidden;
}
.main-nav HEADER .settings .global-refresh-button {
  z-index: 200;
}
.main-nav HEADER .settings .spinner.spinner-inline {
  line-height: 24px;
  vertical-align: middle;
  height: 24px;
  width: 24px;
  min-height: 24px;
  min-width: 24px;
  z-index: 100;
}
.main-nav HEADER .settings .spinner.spinner-inline.spinner-paused {
  animation-play-state: paused;
  display: none;
}
.main-nav HEADER .settings button.nav-icon {
  border: none;
  background: transparent;
}
.main-nav HEADER .settings button.nav-icon [shape="caret down"] {
  margin-left: 24px;
}
.main-nav HEADER .settings .nav-link .fa {
  font-size: inherit;
}
.main-nav HEADER .settings .nav-link .user-menu-large {
  padding: 0 0 0 18px;
  font-size: inherit;
  width: auto;
  display: inline-block;
}
.main-nav HEADER .settings .nav-link .user-menu-large clr-icon {
  left: initial;
  right: 18px;
}
.main-nav HEADER .settings .nav-link .user-menu-large span {
  margin-right: 46px;
}
.main-nav HEADER .settings .nav-link .help-menu {
  display: inline-block;
}
.main-nav HEADER .settings .nav-link .help-menu [shape="caret down"] {
  margin-left: 24px;
}
.main-nav HEADER .settings a[role='button'].nav-link {
  display: flex;
}
.main-nav HEADER .settings VC-ACTION-MENU CLR-DROPDOWN .dropdown-menu {
  min-width: 30ch;
}
.main-nav HEADER .settings VC-ACTION-MENU CLR-DROPDOWN .dropdown-menu:focus {
  outline: 1px dotted #212121;
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -1px;
}
.main-nav HEADER .settings VC-ACTION-MENU CLR-DROPDOWN .state-focused {
  outline: 1px dotted #212121;
  outline: 5px auto -webkit-focus-ring-color;
}
.main-nav HEADER .settings VC-ACTION-MENU VC-ACTION-MENU-TOGGLE:hover {
  opacity: 1;
}
.main-nav HEADER .settings .perf .nav-link {
  padding-right: 0px;
  padding-left: 0px;
}
.main-nav HEADER .settings .perf .nav-link:last-child {
  padding-left: 12px;
}
.main-nav HEADER .actions-menu {
  margin-top: 0;
}
.main-nav HEADER > .divider,
.main-nav HEADER .settings > .divider {
  height: 40px;
  /* clr override */
  display: flex;
  flex: 0 0 auto;
  width: 1px;
  background: #fafafa;
  opacity: 0.15;
  /* /clr override */
}
.k-splitter .k-splitbar {
  background: #fafafa;
}
.k-splitter .k-splitbar.k-splitbar-horizontal {
  border-top: 0;
  border-bottom: 0;
  width: 3px;
  border-left-width: 1px;
  cursor: ew-resize;
  transition: border 0.1s;
  transition-timing-function: start-end;
}
.k-splitter .k-splitbar.k-splitbar-horizontal.k-state-focused {
  border-top: none;
  border-bottom: none;
}
.k-splitter .k-splitbar.k-splitbar-horizontal-hover {
  border-left: 1px #ddd solid;
  transition: border 0.5s;
  transition-timing-function: step-end;
}
.k-splitter .k-splitbar.k-splitbar-vertical {
  height: 3px;
  border-top: 0;
  border-bottom-color: #ddd;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-left: 0;
  border-right: 0;
  cursor: ns-resize;
  transition: border 0.1s;
  transition-timing-function: start-end;
}
.k-splitter .k-splitbar.k-splitbar-vertical-hover {
  border-top-color: transparent;
  border-bottom: 1px #ddd solid;
  transition: border 0.5s;
  transition-timing-function: step-end;
}
.k-splitter .k-ghost-splitbar-horizontal {
  width: 2px;
  border-left: 2px #89cbdf solid;
  outline: none;
  background: none;
}
.k-splitter .k-ghost-splitbar-vertical {
  height: 2px;
  border-top: 0;
  border-bottom: 2px #89cbdf solid;
  outline: none;
  background: none;
}
#horizontal-panel {
  background-color: transparent;
}
#horizontal-panel.hide-object-navigator > .k-splitbar {
  display: none;
}
#bottom-panel-pane {
  box-shadow: 0 -2px 3px 3px rgba(197, 197, 197, 0.2);
}
.controlcenter {
  padding-top: 5px;
}
/******** Controlcenter Category********/
.controlcenter-category {
  padding: 0 10px;
  height: 21px;
  line-height: 21px;
  color: #597994;
  background-color: rgba(62, 137, 196, 0.2);
  font-weight: 400;
}
/******** Controlcenter Shortcuts ********/
.user-tabbing {
  /* Shortcut items in shortcuts view */
}
.user-tabbing .controlcenter-shortcut:focus {
  outline: 1px dotted #212121;
  outline: 5px auto -webkit-focus-ring-color;
}
.controlcenter-shortcut-icon-display {
  display: inline-block;
  background: url(/ui/resources/img/plugin.svg);
}
.controlcenter-shortcut {
  display: inline-block;
  cursor: pointer;
  margin: 21px 10px;
  text-align: center;
  vertical-align: top;
  width: 95px;
}
.controlcenter-shortcut span.controlcenter-shortcut-plugin-icon {
  background-size: cover;
  width: 32px !important;
  height: 32px !important;
}
.controlcenter-shortcut-icon {
  width: 32px;
  height: 32px;
}
.controlcenter-shortcut-label {
  margin-top: 10px;
  max-width: 95px;
  color: #333333;
  line-height: 13px;
  white-space: pre-line;
  word-wrap: break-word;
  padding-bottom: 2px;
}
.user-tabbing .vui-action-bar {
  /* Vui action bar items */
}
.user-tabbing .vui-action-bar ul li a:focus {
  outline: 1px dotted #212121;
  outline: 5px auto -webkit-focus-ring-color;
}
.user-tabbing .k-grid-header .k-header {
  /* Kendo grid header menu */
  /* Kendo grid header */
}
.user-tabbing .k-grid-header .k-header .k-header-column-menu:focus {
  outline: 1px dotted #212121;
  outline: 5px auto -webkit-focus-ring-color;
}
.user-tabbing .k-grid-header .k-header .k-link:focus {
  outline: 1px dotted #212121;
  outline: 5px auto -webkit-focus-ring-color;
}
.flex-to-absolute-positioning-container {
  flex-grow: 1;
  position: relative;
}
.flex-to-absolute-positioning-container-inner {
  position: absolute;
  width: 100%;
  height: 100%;
}
div[vui-datagrid] .k-grid .k-header.k-grid-toolbar {
  background-color: transparent !important;
}
div[vui-datagrid] .k-block,
div[vui-datagrid] .k-widget:not(.k-tooltip) {
  background-color: transparent !important;
}
div[vui-datagrid] .k-grid-content {
  background: white;
}
div[vui-datagrid] .k-grid.no-items-to-display .k-virtual-scrollable-wrap:after {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  content: attr(no-items-text);
}
div[vui-datagrid] .k-grid.no-items-to-display .k-pager-wrap.k-grid-pager .k-pager-info {
  display: none;
}
.grid-container-with-cell-ellipsis td {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.flex-grid,
.vx-portlets .col .vx-portlet .vx-portlet-content [kendo-grid],
.vx-portlets .col .vx-portlet .vx-portlet-content [vui-datagrid] {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex: 1 1 auto;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 100%;
}
.flex-grid .k-grid.k-widget {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex: 1 1 auto;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  height: 100%;
}
.flex-grid .k-grid.k-widget .k-grid-header,
.flex-grid .k-grid.k-widget .k-grid-footer {
  flex-shrink: 0;
}
.flex-grid .k-grid.k-widget .k-grid-content {
  -webkit-flex: 1 1 auto;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: auto !important;
  position: relative;
}
.flex-grid .k-grid.k-widget .k-grid-content .k-virtual-scrollable-wrap {
  height: 100% !important;
  position: absolute;
  right: 16px;
  left: 0;
}
.flex-grid .k-grid.k-widget .k-grid-content .k-scrollbar-vertical > div {
  background-color: white;
}
div.vui-checkbox-container {
  position: relative;
  width: 100%;
  min-width: 40px;
  height: 28px;
  margin-left: -12px;
  margin-right: -12px;
  text-overflow: clip;
  /*firefox has funny dots*/
}
div.vui-checkbox-container > input:last-child {
  cursor: default;
  width: 100%;
  height: 100%;
  opacity: 0;
  text-indent: -9999em;
  /*hides the blinking caret on IE*/
}
div.vui-checkbox-container > input[type="checkbox"] {
  position: absolute;
  top: 8px;
  left: 12px;
}
.k-grid .k-group-cell {
  text-overflow: clip;
}
.vui-datagrid.k-widget {
  background: white;
}
.shadow-box,
.vui-datagrid.k-grid.k-widget .k-grid-content tbody tr td.k-state-focused,
div[vui-datagrid] .k-grid.k-widget .k-grid-content tbody tr td.k-state-focused,
.vui-datagrid.k-grid.k-widget .k-grid-content-locked tbody tr td.k-state-focused,
div[vui-datagrid] .k-grid.k-widget .k-grid-content-locked tbody tr td.k-state-focused,
.vui-datagrid.k-grid.k-widget .k-grid-header th.k-header.k-state-focused,
div[vui-datagrid] .k-grid.k-widget .k-grid-header th.k-header.k-state-focused {
  box-shadow: inset 0 0 0 1px #33ccff;
}
.vui-datagrid.k-grid.k-widget .k-grid-header,
div[vui-datagrid] .k-grid.k-widget .k-grid-header {
  border-right: 0;
}
.k-grid .k-grid-header {
  position: relative;
}
.k-grid .k-grid-header .k-grid-header-wrap {
  border-right: 0;
}
.k-grid .k-grid-header:before,
.k-grid .k-grid-header:after {
  content: " ";
  height: 100%;
  width: 1px;
  background: #ccc;
  position: absolute;
  top: 0;
  z-index: 2;
}
.k-grid .k-grid-header:before {
  left: 0;
}
.k-grid .k-grid-header:after {
  right: 0;
}
.action-confirmation .vx-alert-body {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}
.action-confirmation .vx-alert-text {
  -webkit-flex: 1 1 auto;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  margin: auto;
  white-space: pre-line;
  text-align: left;
}
.action-confirmation .vx-alert-icon {
  margin: auto;
  padding: 0 10px 5px;
}
.action-confirmation .vx-alert-footer {
  padding: 5px 15px 8px;
  text-align: right;
}
.action-confirmation .vx-alert-footer span {
  padding: 0 3px;
}
.action-confirmation .vx-modal-titlebar {
  font-size: 12px;
}
[vx-alert] .vx-modal-backdrop {
  z-index: 1100;
}
[vx-alert] .vx-alert-modal {
  z-index: 1100;
}
.icon-warning-32 {
  background-image: url(../img/warning-32x32.png);
  display: inline-block;
  vertical-align: middle;
  background-repeat: no-repeat;
  margin: 1px 4px 0px;
  height: 32px;
  width: 32px;
}
.informational-dialog-text {
  white-space: pre-line;
}
.vui-popup.vui-dialog {
  min-height: initial;
  overflow: hidden;
}
.vui-wizard .wizard-panel-TOC .wizard-steps-list li a.no-content:before {
  content: '';
}
/* Copyright 2015 VMware, Inc. All rights reserved. -- VMware Confidential */
/*
 * Styles for the event console.
 */
.split-view .details {
  /* Related events list modifications to look like in the flex */
}
.split-view .details .ask-vmware {
  margin-left: auto;
}
.split-view .details .property-value .property.date-time {
  font-weight: initial;
}
.split-view .details .causes-list {
  margin: 0px 0px 0px 20px;
}
.split-view .details .causes-list li {
  line-height: 25px;
}
.split-view .details div[vui-datagrid].related-events .k-grid.k-widget {
  border: 1px solid #d8d8d8;
}
.split-view .details div[vui-datagrid].related-events .k-grid-content {
  height: 100% !important;
  overflow-y: auto;
}
.split-view .details div[vui-datagrid].related-events .k-grid-header,
.split-view .details div[vui-datagrid].related-events .k-grid-pager {
  display: none;
}
.app-feedback a {
  position: absolute;
  z-index: 2000;
  top: 15px;
  left: 100%;
  margin-left: -25px;
}
.vfeed-dialog.vui-popup.vui-dialog > .panel-content {
  -webkit-flex: 1 1 auto;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  height: auto;
}
.vfeed-dialog.vui-popup.vui-dialog .vfeed-content {
  padding-left: 4px;
  line-height: 16px;
}
.vfeed-dialog.vui-popup.vui-dialog .vfeed-content .feedback-form {
  padding: 0px;
}
.vfeed-dialog.vui-popup.vui-dialog .vfeed-content .canvas {
  cursor: pointer;
  border-width: 1px 0 1px 1px;
  border-color: gray;
  border-style: solid;
  padding-right: 20px;
}
.vfeed-dialog.vui-popup.vui-dialog .vfeed-content .content-padding {
  padding-left: 4px;
}
.vfeed-dialog.vui-popup.vui-dialog .vfeed-content .smiley {
  padding: 4px;
  cursor: pointer;
}
.vfeed-dialog.vui-popup.vui-dialog .vfeed-content form .form-group {
  display: block;
  padding: 0;
  margin-bottom: 0;
  margin-right: 0;
}
.vfeed-dialog.vui-popup.vui-dialog .vfeed-content form .form-group.form-group-horizontal {
  margin: 4px 0 0 0;
  border: none;
}
.vfeed-dialog.vui-popup.vui-dialog .vfeed-content form .form-group.form-group-horizontal:first-child {
  margin: 0;
}
.vfeed-dialog.vui-popup.vui-dialog .vfeed-content form .form-group textarea,
.vfeed-dialog.vui-popup.vui-dialog .vfeed-content .form .form-group textarea {
  margin: 0;
}
.vfeed-dialog.vui-popup.vui-dialog .vfeed-content textarea {
  padding: 0 6px;
}
.vfeed-dialog.vui-popup.vui-dialog .vfeed-content form .form-group a {
  margin-right: 0;
}
.vfeed-dialog.vui-popup.vui-dialog .vfeed-content form .form-group input[type=text] {
  margin-top: 0;
}
.vfeed-dialog.vui-popup.vui-dialog .vfeed-content form .form-group .smiley-container {
  display: inline-block;
}
.vfeed-dialog.vui-popup.vui-dialog .vfeed-content input[type=email] {
  width: 95%;
}
.vui-notification {
  z-index: 2;
}
.vui-notification header {
  display: flex;
  align-items: center;
}
.vui-notification header > span {
  flex-shrink: 0;
}
.vui-notification header > span ~ span:not(:last-child) {
  flex: 1 1 100%;
  margin-right: 30px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.vui-notification .panel-content p {
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100%;
}
/* Copyright 2015 VMware, Inc. All rights reserved. -- VMware Confidential */
/*
 * Styles for the master-details layout used in events- and tasks- consoles.
 */
.user-tabbing {
  /* Kendo horizontal spliter */
  /* Kendo vertical spliter */
}
.user-tabbing div.k-splitbar.k-splitbar-horizontal.k-state-focused {
  outline: 1px dotted #212121;
  outline: 5px auto -webkit-focus-ring-color;
}
.user-tabbing div.k-splitbar.k-splitbar-vertical.k-state-focused {
  outline: 1px dotted #212121;
  outline: 5px auto -webkit-focus-ring-color;
}
.split-view {
  /* Expand splitter-content vertically and remove borders */
  /* Details panel styles */
}
.split-view .k-splitter {
  height: 100%;
}
.split-view .k-widget {
  border-style: initial;
}
.split-view .details {
  background-color: white;
  overflow: auto;
}
.split-view .details .no-items-selected {
  font-weight: bold;
  font-size: large;
  color: #A6A6A6;
}
.split-view .details .content {
  padding: 20px;
}
.split-view .details .content .half-size {
  width: 50%;
}
.horizontal-flex-container {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
}
.centered-content-container {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  /* align horizontal */
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  /* align vertical */
}
.property-value {
  padding: 5px;
}
.property-value .property {
  font-weight: bold;
  min-width: 90px;
  padding-right: 10px;
  /* This is needed otherwise min-width is ignored
         for <span> elements in IE. */
  display: inline-block;
}
.iso-chooser-splitter {
  min-height: 400px;
}
.iso-chooser-splitter [kendo-splitter] {
  width: 100%;
  min-height: 400px;
  border: 0;
  background: transparent;
  border-right: 1px solid #ccc;
}
.iso-chooser-splitter .chooser-panel {
  border: 1px solid #ccc;
  background: white;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}
.iso-chooser-splitter .chooser-panel-content {
  -webkit-flex: 1 1 auto;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  overflow-y: auto;
}
.iso-chooser-splitter .chooser-panel-title {
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  padding: 8px;
  border-bottom: 1px solid #ccc;
  background-color: #e7ebef;
}
.iso-chooser-splitter .file-chooser ul {
  list-style-type: none;
  margin: 0;
}
.iso-chooser-splitter .file-chooser li {
  line-height: 20px;
  padding: 2px;
  border-bottom: 1px solid #eee;
  cursor: pointer;
}
.iso-chooser-splitter .file-chooser li:hover {
  background-color: #c6e7ff;
}
.iso-chooser-splitter .file-chooser li.selected {
  background-color: #a3d7ff;
}
.iso-chooser-splitter .file-information .chooser-panel-content {
  padding: 3px;
}
@media only screen and (max-width: 1024px) {
  .custom-split-view {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
}
@media only screen and (min-width: 1025px) {
  .custom-split-view {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
  }
}
input[type=color].has-error,
input[type=date].has-error,
input[type=datetime-local].has-error,
input[type=datetime].has-error,
input[type=email].has-error,
input[type=month].has-error,
input[type=number].has-error,
input[type=password].has-error,
input[type=phone].has-error,
input[type=search].has-error,
input[type=tel].has-error,
input[type=text].has-error,
input[type=time].has-error,
input[type=url].has-error,
input[type=week].has-error,
textarea.has-error,
select.has-error,
input.has-error {
  border: 2px solid red;
}
input[type=color]:focus,
input[type=date]:focus,
input[type=datetime-local]:focus,
input[type=datetime]:focus,
input[type=email]:focus,
input[type=month]:focus,
input[type=number]:focus,
input[type=password]:focus,
input[type=phone]:focus,
input[type=search]:focus,
input[type=tel]:focus,
input[type=text]:focus,
input[type=time]:focus,
input[type=url]:focus,
input[type=week]:focus,
textarea:focus,
select:focus,
input:focus,
input[type=color]:active,
input[type=date]:active,
input[type=datetime-local]:active,
input[type=datetime]:active,
input[type=email]:active,
input[type=month]:active,
input[type=number]:active,
input[type=password]:active,
input[type=phone]:active,
input[type=search]:active,
input[type=tel]:active,
input[type=text]:active,
input[type=time]:active,
input[type=url]:active,
input[type=week]:active,
textarea:active,
select:active,
input:active {
  box-shadow: none;
}
.full-width {
  width: 100%;
  max-width: none !important;
}
label.normal-weight {
  font-weight: normal;
}
.value-in-units {
  display: inline;
}
.value-in-units .input-container {
  float: left;
  padding-right: 2px;
}
.value-in-units .input-group-btn {
  width: 20px;
}
.value-in-units .input-container {
  position: relative;
}
.value-in-units .recommended-values {
  position: absolute;
  z-index: 3;
  left: 0;
  right: 0;
  top: 100%;
  width: 100%;
  background-color: white;
  border: 1px solid black;
}
.value-in-units .recommended-values tr {
  cursor: pointer;
}
.value-in-units .recommended-values tr:hover {
  background-color: #5f7e9f;
  color: white;
}
.value-in-units .recommended-values tr.highlighted {
  background-color: #2b5480;
  color: white;
}
.value-in-units .recommended-values tr:focus {
  outline: auto;
}
.value-in-units .recommended-values td {
  padding: 5px;
  white-space: nowrap;
}
.value-in-units .recommended-values-openUpwards {
  top: initial;
  bottom: 100%;
}
.select select {
  height: 24px;
}
select[disabled] {
  opacity: .5;
}
/* this overrides VUI's tooltip class since clarity's tooltip class works differently */
/* The clr-tooltip class is necessary to avoid using Clarity forms while at the same
   time using the Clarity tooltip.
*/
form .form-group .tooltip,
.clr-tooltip {
  opacity: 1;
}
/* this is a pre-implementation of clarity's helper text
   it works when .form-tip is inside the label.tooltip used for
   form validation.

   probably should be removed once the official clarity helper
   text is implemented.
*/
.form-tip {
  font-size: 12px;
  color: #747474;
  visibility: hidden;
  line-height: 14px;
  padding-top: 3px;
  padding-left: 6px;
}
input[type=text]:focus ~ .form-tip {
  visibility: visible;
}
.vx-validate-tooltip span.tooltip-content span {
  margin: 0;
}
.vui-stack-view .stack-view-table .stack-view-child .stack-view-cell > :last-child,
.vui-stack-view .stack-view-table .stack-view-child-i .stack-view-cell > :last-child,
.vui-stack-view .stack-view-table .stack-view-parent .stack-view-cell > :last-child,
.vui-stack-view .stack-view-table .stack-view-parent-i .stack-view-cell > :last-child,
.vui-stack-view .stack-view-table .stack-view-row .stack-view-cell > :last-child,
.vui-stack-view .stack-view-table .stack-view-row-i .stack-view-cell > :last-child {
  margin-bottom: 0px;
}
.vui-stack-view * {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
div[vui-datagrid] .k-grid.k-widget {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.k-grid td {
  cursor: default;
}
.line-wrap {
  white-space: normal;
}
.mv5 {
  margin: 5px 0;
}
.k-grid-content {
  min-height: 35px;
}
div[vui-tree-view] .k-state-focused {
  outline: none !important;
}
.vui-layout-body {
  min-height: 0;
  min-width: 0;
  background: none;
  padding: 0px;
}
.vui-tertiary-tabs,
.vui-toc-tabs {
  min-height: 1px;
}
.vui-toc-tabs {
  overflow-x: hidden;
}
.perf-container .results-padding-LR table .results-cell-blue {
  padding-top: 8px;
  padding-bottom: 8px;
}
.perf-container .results-padding-LR table .results-cell-white {
  padding-top: 8px;
  padding-bottom: 8px;
}
.perf-container {
  max-width: 556px;
  color: #333;
  position: absolute;
  top: 60px;
  z-index: 1000;
}
.perf-container .results-padding-LR {
  background-color: #FFF;
}
.perf-container .results-padding-LR table .results-cell-blue {
  text-indent: 0px;
}
.perf-container ul {
  margin: 0;
}
.perf-container li {
  list-style: none;
}
div.km-popup-overlay.km-popover-root {
  height: 0px;
  width: 0px;
}
.clr-component,
.clr-component * {
  box-sizing: border-box;
}
.clr-component {
  display: block;
  line-height: 24px;
}
.close * {
  font-size: inherit;
}
action-bar .vui-action-bar {
  min-height: 22px;
}
.vui-action-bar ul li a {
  will-change: opacity;
}
.vui-action-bar ul li a:focus {
  box-shadow: none;
}
.k-grid-content-locked:last-child,
.k-grid-content:last-child {
  border-bottom: 1px solid #dddddd;
}
.k-splitbar.k-splitbar-vertical .k-icon.k-expand-next {
  background: none;
}
.alert-text {
  position: relative;
}
dl.stack-view {
  -webkit-mask-image: none;
  overflow-y: visible;
}
.cluster-settings-drs-view {
  display: flex;
  flex-direction: column;
  height: 100%;
  box-sizing: border-box;
}
.cluster-settings-drs-view clr-stack-view {
  flex: 1 1 auto;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}
.cluster-settings-drs-view clr-stack-view dl.stack-view {
  overflow: auto;
}
.vsphere-ha-settings-view {
  box-sizing: border-box;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.vsphere-ha-settings-view .ha-container {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
}
.vsphere-ha-settings-view .ha-container .settingsBlock {
  flex-shrink: 0;
}
.vsphere-ha-settings-view .ha-container .settingsBlock .failure-grid-container {
  flex: 1 1 auto;
}
.vsphere-ha-settings-view .ha-container clr-stack-view {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
}
.vsphere-ha-settings-view .ha-container clr-stack-view dl.stack-view {
  overflow: auto;
}
.related-items-portlet .object-link {
  display: flex;
}
.related-items-portlet .object-link span {
  flex-shrink: 0;
}
.related-items-portlet .object-link a {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
/**
 * Styles for the object link with icon
 */
.vui-stack-view .stack-view-table .stack-view-row .stack-view-cell .object-link [class*=icon] {
  position: static;
  margin: 0px 4px 0px 0px;
}
.vui-stack-view .stack-view-table .stack-view-row .stack-view-cell .object-link:first-child {
  margin-top: 0px;
}
.vui-stack-view .stack-view-table .stack-view-row .stack-view-cell .object-link {
  margin-top: 5px;
}
.flex-column {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}
.flex-row {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
}
.flex-auto {
  -webkit-flex: 1 1 auto;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
}
.flex-100-percent {
  -webkit-flex: 1 1 0;
  -ms-flex: 1 1 0;
  flex: 1 1 0;
}
.flex-none {
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
}
.flex-grow-auto {
  -webkit-flex: 1 0 auto;
  -ms-flex: 1 0 auto;
  flex: 1 0 auto;
}
.flex-shrink-auto {
  -webkit-flex: 0 1 auto;
  -ms-flex: 0 1 auto;
  flex: 0 1 auto;
}
/*
  Same as flex-grow-auto, but does not allow this element's children to overflow its size (in flex-direction only)
*/
.flex-basis-100-percent {
  -webkit-flex: 1 0 0;
  -ms-flex: 1 0 0;
  flex: 1 0 0;
}
.center-pane-container {
  height: 100%;
}
/*
* margin and padding utility classes using x6 recommended by design
* use these when you need to tweak the layout of a component in context
*/
/*
* margin and padding utility classes
* use these when you need to tweak the layout of a component in context
*/
.mts {
  margin-top: 2px;
}
.mtm {
  margin-top: 5px;
}
.mtl {
  margin-top: 10px;
}
.mrs {
  margin-right: 2px;
}
.mrm {
  margin-right: 5px;
}
.mrl {
  margin-right: 10px;
}
.mls {
  margin-left: 2px;
}
.mlm {
  margin-left: 5px;
}
.mll {
  margin-left: 10px;
}
.mbs {
  margin-bottom: 2px;
}
.mbm {
  margin-bottom: 5px;
}
.mbl {
  margin-bottom: 10px;
}
.mas {
  margin: 2px;
}
.mam {
  margin: 5px;
}
.mal {
  margin: 10px;
}
.mhs {
  margin: 0 2px;
}
.mhm {
  margin: 0 5px;
}
.mhl {
  margin: 0 10px;
}
.mvs {
  margin: 2px 0;
}
.mvm {
  margin: 5px 0;
}
.mvl {
  margin: 10px 0;
}
.pts {
  padding-top: 2px;
}
.ptm {
  padding-top: 5px;
}
.ptl {
  padding-top: 10px;
}
.prs {
  padding-right: 2px;
}
.prm {
  padding-right: 5px;
}
.prl {
  padding-right: 10px;
}
.pls {
  padding-left: 2px;
}
.plm {
  padding-left: 5px;
}
.pll {
  padding-left: 10px;
}
.pbs {
  padding-bottom: 2px;
}
.pbm {
  padding-bottom: 5px;
}
.pbl {
  padding-bottom: 10px;
}
.pas {
  padding: 2px;
}
.pam {
  padding: 5px;
}
.pal {
  padding: 10px;
}
.phs {
  padding: 0 2px;
}
.phm {
  padding: 0 5px;
}
.phl {
  padding: 0 10px;
}
.pvs {
  padding: 2px 0;
}
.pvm {
  padding: 5px 0;
}
.pvl {
  padding: 10px 0;
}
.main-nav {
  left: 0;
}
.app-frame-outer .app-frame-inner {
  margin: 0;
}
.flex-fill-parent {
  flex: 1 1 auto;
}
.flexbox-column {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  flex: 1 1 100%;
  box-sizing: border-box;
}
.flexbox-column[vui-splitter] .k-splitter {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  flex: 1 1 100%;
  box-sizing: border-box;
  height: auto !important;
}
.flexbox-column[vui-splitter] .k-splitter.k-widget {
  border: none;
  background-color: transparent;
}
.flexbox-row {
  display: flex;
  align-items: center;
  box-sizing: border-box;
}
.flex-1 {
  flex: 1 1 100%;
  box-sizing: border-box;
}
.overflow-auto {
  overflow: auto;
}
.skip-links-container {
  position: absolute;
  left: 2px;
  right: 0;
  z-index: 1500;
}
.skip-link {
  position: absolute;
  overflow: hidden;
  height: 1px;
}
.skip-link:active,
.skip-link:focus {
  border: 1px solid #d3d3d3;
  background: #fff;
  position: static;
  overflow: visible;
  height: auto;
  padding-left: 5px;
  padding-right: 5px;
}
#center-pane .center-pane-container.center-pane-with-a-title {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  flex: 1 1 100%;
  box-sizing: border-box;
  overflow: hidden;
}
#center-pane .center-pane-container.center-pane-with-a-title[vui-splitter] .k-splitter {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  flex: 1 1 100%;
  box-sizing: border-box;
  height: auto !important;
}
#center-pane .center-pane-container.center-pane-with-a-title[vui-splitter] .k-splitter.k-widget {
  border: none;
  background-color: transparent;
}
#center-pane .center-pane-container.center-pane-with-a-title .titlebar {
  display: flex;
  align-items: center;
  box-sizing: border-box;
  padding: 0 0 0 20px;
  height: 60px;
}
#center-pane .center-pane-container.center-pane-with-a-title .titlebar .titlebar-text {
  font-size: 22px;
  font-weight: 200;
}
#center-pane .center-pane-container.center-pane-with-a-title > .titlebar + .panel-inner {
  flex: 1 1 100%;
  box-sizing: border-box;
  overflow: hidden;
  margin: 0;
}
#center-pane .center-pane-container.center-pane-with-a-title > .titlebar + .panel-inner .tabbed-object-view {
  display: block;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  flex: 1 1 100%;
  box-sizing: border-box;
}
#center-pane .center-pane-container.center-pane-with-a-title > .titlebar + .panel-inner .tabbed-object-view[vui-splitter] .k-splitter {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  flex: 1 1 100%;
  box-sizing: border-box;
  height: auto !important;
}
#center-pane .center-pane-container.center-pane-with-a-title > .titlebar + .panel-inner .tabbed-object-view[vui-splitter] .k-splitter.k-widget {
  border: none;
  background-color: transparent;
}
#center-pane .center-pane-container.center-pane-with-a-title > .titlebar + .panel-inner .tabbed-object-view .outer-tab-content {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  flex: 1 1 100%;
  box-sizing: border-box;
}
#center-pane .center-pane-container.center-pane-with-a-title > .titlebar + .panel-inner .tabbed-object-view .outer-tab-content[vui-splitter] .k-splitter {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  flex: 1 1 100%;
  box-sizing: border-box;
  height: auto !important;
}
#center-pane .center-pane-container.center-pane-with-a-title > .titlebar + .panel-inner .tabbed-object-view .outer-tab-content[vui-splitter] .k-splitter.k-widget {
  border: none;
  background-color: transparent;
}
#center-pane .center-pane-container.center-pane-with-a-title > .titlebar + .panel-inner .tabbed-object-view .outer-tab-content > .fill-parent {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  flex: 1 1 100%;
  box-sizing: border-box;
}
#center-pane .center-pane-container.center-pane-with-a-title > .titlebar + .panel-inner .tabbed-object-view .outer-tab-content > .fill-parent[vui-splitter] .k-splitter {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  flex: 1 1 100%;
  box-sizing: border-box;
  height: auto !important;
}
#center-pane .center-pane-container.center-pane-with-a-title > .titlebar + .panel-inner .tabbed-object-view .outer-tab-content > .fill-parent[vui-splitter] .k-splitter.k-widget {
  border: none;
  background-color: transparent;
}
#center-pane .center-pane-container.center-pane-with-a-title > .titlebar + .panel-inner .tabbed-object-view .outer-tab-content > .fill-parent > .fill-parent {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  flex: 1 1 100%;
  box-sizing: border-box;
}
#center-pane .center-pane-container.center-pane-with-a-title > .titlebar + .panel-inner .tabbed-object-view .outer-tab-content > .fill-parent > .fill-parent[vui-splitter] .k-splitter {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  flex: 1 1 100%;
  box-sizing: border-box;
  height: auto !important;
}
#center-pane .center-pane-container.center-pane-with-a-title > .titlebar + .panel-inner .tabbed-object-view .outer-tab-content > .fill-parent > .fill-parent[vui-splitter] .k-splitter.k-widget {
  border: none;
  background-color: transparent;
}
#center-pane .center-pane-container.center-pane-with-a-title > .titlebar + .panel-inner .tabbed-object-view .outer-tab-content > .fill-parent > .fill-parent > .fill-parent {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  flex: 1 1 100%;
  box-sizing: border-box;
}
#center-pane .center-pane-container.center-pane-with-a-title > .titlebar + .panel-inner .tabbed-object-view .outer-tab-content > .fill-parent > .fill-parent > .fill-parent[vui-splitter] .k-splitter {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  flex: 1 1 100%;
  box-sizing: border-box;
  height: auto !important;
}
#center-pane .center-pane-container.center-pane-with-a-title > .titlebar + .panel-inner .tabbed-object-view .outer-tab-content > .fill-parent > .fill-parent > .fill-parent[vui-splitter] .k-splitter.k-widget {
  border: none;
  background-color: transparent;
}
#center-pane .center-pane-container.center-pane-with-a-title > .titlebar + .panel-inner .tabbed-object-view .outer-tab-content > .fill-parent > .fill-parent > .fill-parent > .fill-parent.vertical-flex-container {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  flex: 1 1 100%;
  box-sizing: border-box;
}
#center-pane .center-pane-container.center-pane-with-a-title > .titlebar + .panel-inner .tabbed-object-view .outer-tab-content > .fill-parent > .fill-parent > .fill-parent > .fill-parent.vertical-flex-container[vui-splitter] .k-splitter {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  flex: 1 1 100%;
  box-sizing: border-box;
  height: auto !important;
}
#center-pane .center-pane-container.center-pane-with-a-title > .titlebar + .panel-inner .tabbed-object-view .outer-tab-content > .fill-parent > .fill-parent > .fill-parent > .fill-parent.vertical-flex-container[vui-splitter] .k-splitter.k-widget {
  border: none;
  background-color: transparent;
}
#center-pane .center-pane-container.center-pane-with-a-title > .titlebar + .panel-inner .outer-tab-content-no-title {
  box-sizing: border-box;
  height: 100%;
  margin: 0;
  padding: 0 20px 10px 20px;
}
#center-pane .center-pane-container.center-pane-with-a-title .outer-tab-content-no-title {
  top: auto;
  height: auto !important;
}
.simple-search {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  flex: 1 1 100%;
  box-sizing: border-box;
  overflow: hidden;
}
.simple-search[vui-splitter] .k-splitter {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  flex: 1 1 100%;
  box-sizing: border-box;
  height: auto !important;
}
.simple-search[vui-splitter] .k-splitter.k-widget {
  border: none;
  background-color: transparent;
}
.simple-search .search-form-panel-wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.simple-search .simple-search-header {
  display: flex;
  padding-top: 20px;
  height: 40px;
  font-weight: 200;
  font-size: 22px;
  background: #fafafa;
  margin-left: 10px;
}
.simple-search .search-header {
  display: flex;
  flex-direction: column;
  margin: 12px 36px 12px 36px;
  background: #fafafa;
  font-weight: 200;
  min-height: min-content;
}
.simple-search .search-header clr-alert .alert {
  margin-top: 0px;
  margin-bottom: 12px;
}
.simple-search .search-header .saved-search-bar {
  width: 100%;
  font-size: 14px;
  align-self: flex-start;
}
.simple-search .search-header .search-title-bar {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  min-height: min-content;
}
.simple-search .search-header .search-title-bar .search-title {
  font-size: 22px;
  flex-grow: 1;
}
.simple-search .search-form-panel {
  padding: 0;
  margin: 0;
  max-height: none !important;
  box-sizing: border-box;
  height: auto;
}
.simple-search .search-form-panel form {
  padding: 0;
}
.simple-search .search-form-panel form .search-results-header {
  text-align: right;
}
.simple-search .search-form-panel form,
.simple-search .search-form-panel form .search-input-container {
  display: flex;
  align-items: baseline;
}
.simple-search .search-results-panel {
  box-sizing: border-box;
  overflow: hidden;
  flex: 1 1 100%;
  height: 100%;
  padding: 0;
  margin: 0;
}
.simple-search .search-results-panel .datagrid-container {
  margin-left: 36px;
  margin-right: 36px;
}
.simple-search .search-results-panel > div[vx-view-tabs] {
  height: 100%;
  box-sizing: border-box;
  display: block;
  position: relative;
  overflow: hidden;
}
.simple-search .search-results-panel > div[vx-view-tabs] > .fill-parent {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  flex: 1 1 100%;
  box-sizing: border-box;
  overflow: hidden;
  padding: 10px;
}
.simple-search .search-results-panel > div[vx-view-tabs] > .fill-parent[vui-splitter] .k-splitter {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  flex: 1 1 100%;
  box-sizing: border-box;
  height: auto !important;
}
.simple-search .search-results-panel > div[vx-view-tabs] > .fill-parent[vui-splitter] .k-splitter.k-widget {
  border: none;
  background-color: transparent;
}
.simple-search .search-results-panel > div[vx-view-tabs] > .fill-parent .nav.nav-tabs {
  height: 32px !important;
  display: flex !important;
  background: none !important;
  box-shadow: none !important;
}
.simple-search .search-results-panel > div[vx-view-tabs] > .fill-parent .nav.nav-tabs > li {
  margin-bottom: 0;
}
.simple-search .search-results-panel > div[vx-view-tabs] > .fill-parent .tab-content {
  flex: 1 1 100%;
  box-sizing: border-box;
  overflow: hidden;
  padding: 10px 0 0 0;
}
.simple-search .search-results-panel .search-results-panel-header {
  display: flex;
  align-items: center;
  box-sizing: border-box;
  padding: 0 0 0 20px;
  height: 60px;
  background: ghostwhite;
}
.simple-search .search-results-panel .search-results-panel-header .search-results-panel-header-text {
  font-size: 22px;
  font-weight: 200;
}
LEFT-PANEL .vui-panel OBJECT-NAVIGATOR .panel-inner .panel-content {
  padding-top: 0 !important;
}
.simple-search-left-pane .nav-bottom-block.panel-content.flex-column.fill-parent {
  box-sizing: border-box;
  overflow: hidden;
}
.simple-search-left-pane .nav-bottom-block.panel-content.flex-column.fill-parent .object-navigator-list {
  flex: 1 1 100%;
  box-sizing: border-box;
  height: auto;
  overflow: auto;
}
#MainTemplateController {
  display: flex;
  height: 100vh;
  flex-direction: column;
  overflow: hidden;
}
#MainTemplateController .app-header {
  display: flex;
  flex: 0 0 auto;
}
#MainTemplateController .app-header .main-nav {
  width: 100vw;
}
#MainTemplateController .app-frame-outer {
  box-sizing: border-box;
  width: 100vw;
  flex: 1 1 auto;
  height: calc(100% - 95px);
}
.clr-icon.clr-vmw-logo {
  background-size: 37px 32px;
}
.user-tabbing .filter-input {
  /* List view filter icon button */
}
.user-tabbing .filter-input .nav-icon:focus {
  outline: 1px dotted #212121;
  outline: 5px auto -webkit-focus-ring-color;
}
.filter-button {
  border-style: none;
  background-color: transparent;
  padding: 0px;
}
list-view {
  display: block;
}
list-view .filter-input {
  align-items: center;
  display: inline-flex;
  font: inherit;
}
list-view .filter-input:before {
  width: 24px;
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
list-view .filter-input input {
  margin-left: 4px;
  margin-bottom: 1px;
}
.user-tabbing .multi-level .toc-headers-view:focus {
  outline: 1px dotted #212121;
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -1px;
  overflow-x: hidden;
  overflow-y: auto;
}
.user-tabbing .multi-level .toc-headers-view:focus .state-focused {
  outline: 1px dotted #212121;
  outline: 5px auto -webkit-focus-ring-color;
  margin-left: 1px;
  margin-right: 1px;
}
.user-tabbing .multi-level .toc-headers-view:focus li.active {
  margin-top: 1px;
  margin-left: 1px;
  margin-right: 1px;
}
.user-tabbing .multi-level .toc-headers-view:focus li:hover {
  margin-left: 1px;
  margin-right: 1px;
}
.multi-level .vui-icon-caret-black-down,
.multi-level .vui-icon-caret-black-right {
  margin-right: 2px;
}
.multi-level .toc-tabs-title {
  line-height: 24px;
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.multi-level .vui-toc-tabs {
  box-sizing: border-box;
  width: 190px;
}
.multi-level .vui-toc-tabs ul.no-category-title > li > a {
  padding-left: 25px;
}
.multi-level .vui-toc-tabs ul:not(.no-category-title) > li > a {
  max-width: 138px;
  padding-left: 39px;
}
.multi-level .toc-tabs-title [class*=vui-icon] {
  margin-top: -2px !important;
}
.multi-level .vui-tab-content {
  display: block;
  left: 200px;
}
div[vx-object-selector] {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  flex: 1 1 100%;
  box-sizing: border-box;
  height: 100%;
}
div[vx-object-selector][vui-splitter] .k-splitter {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  flex: 1 1 100%;
  box-sizing: border-box;
  height: auto !important;
}
div[vx-object-selector][vui-splitter] .k-splitter.k-widget {
  border: none;
  background-color: transparent;
}
div[vx-object-selector] .nav.nav-tabs {
  height: 36px;
}
div[vx-object-selector] .vui-tabs-container {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  flex: 1 1 100%;
  box-sizing: border-box;
}
div[vx-object-selector] .vui-tabs-container[vui-splitter] .k-splitter {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  flex: 1 1 100%;
  box-sizing: border-box;
  height: auto !important;
}
div[vx-object-selector] .vui-tabs-container[vui-splitter] .k-splitter.k-widget {
  border: none;
  background-color: transparent;
}
div[vx-object-selector] .vui-tabs-container .vui-primary-tabs {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  flex: 1 1 100%;
  box-sizing: border-box;
  min-height: initial;
}
div[vx-object-selector] .vui-tabs-container .vui-primary-tabs[vui-splitter] .k-splitter {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  flex: 1 1 100%;
  box-sizing: border-box;
  height: auto !important;
}
div[vx-object-selector] .vui-tabs-container .vui-primary-tabs[vui-splitter] .k-splitter.k-widget {
  border: none;
  background-color: transparent;
}
div[vx-object-selector] .vui-tabs-container .vui-primary-tabs .vui-secondary-tabs {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  flex: 1 1 100%;
  box-sizing: border-box;
  padding: 0;
  min-height: initial;
  height: 100%;
}
div[vx-object-selector] .vui-tabs-container .vui-primary-tabs .vui-secondary-tabs[vui-splitter] .k-splitter {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  flex: 1 1 100%;
  box-sizing: border-box;
  height: auto !important;
}
div[vx-object-selector] .vui-tabs-container .vui-primary-tabs .vui-secondary-tabs[vui-splitter] .k-splitter.k-widget {
  border: none;
  background-color: transparent;
}
div[vx-object-selector] .vui-tabs-container .vui-primary-tabs .vui-tab-content {
  flex: 1 1 100%;
  box-sizing: border-box;
  height: 100%;
  position: relative;
  top: auto;
  padding: 0;
}
div[vx-object-selector] .vui-tabs-container .vui-primary-tabs .vui-tab-content > div[ng-include] {
  height: 100%;
  width: 100%;
  position: absolute;
}
div[vx-object-selector] list-view[selection-mode="multi"] thead th:first-child,
div[vx-object-selector] #selected-objects thead th:first-child {
  border-right-style: none !important;
}
div[vx-object-selector] .vui-secondary-tabs {
  padding: 8px 0;
  display: flex;
}
div[vx-object-selector] .vui-secondary-tabs .btn-group {
  align-self: flex-start;
  height: 26px;
  flex-shrink: 0;
  z-index: 3;
}
div[vx-object-selector] .vui-secondary-tabs .btn-group .btn {
  height: 24px !important;
  line-height: 24px !important;
}
div[vx-object-selector] .vui-secondary-tabs .btn-group {
  background-color: #0079b8;
  padding: 1px;
  border-radius: 3px;
  display: flex;
  overflow-x: hidden;
}
div[vx-object-selector] .vui-secondary-tabs .btn-group > button:not(:last-child),
div[vx-object-selector] .vui-secondary-tabs .btn-group > button.active:not(:last-child),
div[vx-object-selector] .vui-secondary-tabs .btn-group > button:last-child {
  border-color: #0079b8;
}
div[vx-object-selector] .vui-secondary-tabs .btn-group > .btn {
  text-transform: none;
  margin: 0;
  background-color: #fff;
  color: #0079b8;
  border: 0;
  cursor: pointer;
  padding: 0 15px;
  letter-spacing: 0.1px;
  font-size: 13px;
  font-weight: 400;
}
div[vx-object-selector] .vui-secondary-tabs .btn-group > .btn:not(:first-child) {
  margin-left: 0.5px;
}
div[vx-object-selector] .vui-secondary-tabs .btn-group > .btn:not(:last-child) {
  margin-right: 0.5px;
}
div[vx-object-selector] .vui-secondary-tabs .btn-group > .btn.active {
  color: #fff;
  background-color: #0079b8;
  cursor: not-allowed;
}
div[vx-object-selector] .vui-secondary-tabs .btn-group > .btn:not(.active):active {
  outline: none;
  box-shadow: inset 0 1px 0 #89cbdf;
}
div[vx-object-selector] .vui-secondary-tabs .btn-group > .btn:not(.active):hover {
  color: #004a70;
  background-color: #e1f1f6;
}
div[vx-tab-headers] .btn-group {
  display: inline-flex !important;
}
div[vx-tab-headers] .btn-group {
  align-self: flex-start;
  height: 24px;
}
div[vx-tab-headers] .btn-group .btn {
  height: 24px !important;
  line-height: 24px !important;
}
div[vx-tab-headers] .btn-group {
  background-color: #0079b8;
  padding: 1px;
  border-radius: 3px;
  display: flex;
  overflow-x: hidden;
}
div[vx-tab-headers] .btn-group > button:not(:last-child),
div[vx-tab-headers] .btn-group > button.active:not(:last-child),
div[vx-tab-headers] .btn-group > button:last-child {
  border-color: #0079b8;
}
div[vx-tab-headers] .btn-group > .btn {
  text-transform: none;
  margin: 0;
  background-color: #fff;
  color: #0079b8;
  border: 0;
  cursor: pointer;
  padding: 0 15px;
  letter-spacing: 0.1px;
  font-size: 13px;
  font-weight: 400;
}
div[vx-tab-headers] .btn-group > .btn:not(:first-child) {
  margin-left: 0.5px;
}
div[vx-tab-headers] .btn-group > .btn:not(:last-child) {
  margin-right: 0.5px;
}
div[vx-tab-headers] .btn-group > .btn.active {
  color: #fff;
  background-color: #0079b8;
  cursor: not-allowed;
}
div[vx-tab-headers] .btn-group > .btn:not(.active):active {
  outline: none;
  box-shadow: inset 0 1px 0 #89cbdf;
}
div[vx-tab-headers] .btn-group > .btn:not(.active):hover {
  color: #004a70;
  background-color: #e1f1f6;
}
/*TODO Update selectComputeResourcePage.html with a classname
       and replace this selector div[ng-controller*="SelectComputeResourcePageController"]*/
div[ng-controller*="SelectComputeResourcePageController"],
div[ng-controller*="SelectComputeResourcePageController"] > div[vx-object-selector],
div[ng-controller*="SelectComputeResourcePageController"] > div[vx-object-selector] > .vui-tabs-container {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  overflow: hidden;
}
.vui-secondary-tabs .btn-group.pills .btn {
  margin-right: 0;
  border-left: none;
}
.vui-secondary-tabs .btn-group.pills .btn.btn-sm {
  margin: 0;
  font-weight: 400;
  text-transform: none;
  font-size: 13px;
  line-height: 26px;
  height: 26px;
  flex-shrink: 0;
}
.vui-secondary-tabs .btn-group.pills .btn.active {
  color: #fff;
  background-color: #0079b8;
  cursor: not-allowed;
}
.text-overflow-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.selected-header-item {
  background: #e7e7e7;
  border-top: 1px solid #b8babb;
  border-bottom: 1px solid #b8babb;
  height: 24px;
  line-height: 24px;
  padding: 0 8px;
}
left-panel span.object-navigator-counter {
  box-sizing: border-box;
  position: relative;
  right: auto;
  top: auto;
  border-radius: 3px;
  padding: 0px 4px;
  margin: 0 1px 0 0;
  line-height: 20px;
  background: #ffffff;
  width: auto;
  min-width: 24px;
  flex-shrink: 0;
}
left-panel object-navigator .nav-top-block.obj-nav-counter,
left-panel object-navigator .nav-top-pane-relations-mode.primary-list-item,
left-panel object-navigator .primary-list-item {
  background: #e7e7e7;
  border-top: 1px solid #b8babb;
  border-bottom: 1px solid #b8babb;
  height: 24px;
  line-height: 24px;
  padding: 0 8px;
}
left-panel object-navigator .primary-list-item {
  padding: 0;
}
left-panel object-navigator .nav-top-block.obj-nav-counter {
  padding: 0 3px 0 10px;
}
left-panel object-navigator .obj-nav-counter {
  display: flex;
  align-items: center;
}
left-panel object-navigator .obj-nav-counter .obj-nav-counter-icon {
  display: flex;
  margin-left: 3px;
}
left-panel object-navigator .obj-nav-counter .obj-nav-counter-label {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  flex: 1 1 auto;
}
left-panel object-navigator div[vui-datagrid] .k-grid tbody tr td > div {
  display: flex;
  align-items: center;
}
left-panel object-navigator div[vui-datagrid] .k-grid tbody tr td > div .object-navigator-label {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  flex: 1 1 auto;
}
left-panel object-navigator div[vui-datagrid] .k-grid tbody tr td > div .vui-icon-object-nav-pan {
  cursor: pointer;
}
left-panel object-navigator div[vui-datagrid] .k-grid.k-widget .k-grid-content tbody tr td {
  padding-left: 2px;
  padding-right: 0;
}
left-panel object-navigator .object-navigator-list li a.object,
left-panel object-navigator .object-navigator-list div.object {
  padding: 2px;
  display: flex;
}
left-panel object-navigator .object-navigator-list li a.object span[ng-class="node.icon"],
left-panel object-navigator .object-navigator-list div.object span[ng-class="node.icon"] {
  flex-shrink: 0;
  margin: 1px 4px 0;
  min-width: 16px;
}
left-panel object-navigator .object-navigator-list li a.object .object-navigator-label,
left-panel object-navigator .object-navigator-list div.object .object-navigator-label {
  flex: 1 1 100%;
  margin-right: 17px;
}
left-panel object-navigator .object-navigator-list li a.object .object-navigator-label-search,
left-panel object-navigator .object-navigator-list div.object .object-navigator-label-search {
  flex: 1 1 100%;
  margin-right: 17px;
  margin-left: 20px;
}
.object-navigator-label {
  padding-right: 3px;
}
LEFT-PANEL .vui-panel OBJECT-NAVIGATOR .panel-inner div.panel-content.nav-bottom-block.objNavSeparator {
  overflow: hidden;
}
.fill-parent.non-scrollable {
  overflow: hidden;
}
LEFT-PANEL .vui-panel OBJECT-NAVIGATOR .panel-inner div.panel-content.nav-bottom-block.objNavSeparator {
  top: 26px;
}
left-panel object-navigator .rel-items-splitter-bottom .object-navigator-title ~ .panel-content.nav-bottom-block {
  top: 26px;
}
.user-tabbing bottom-panel {
  /* More tasks link */
}
.user-tabbing bottom-panel #bottom-panel {
  /* Recent Tasks and Alarms tabs */
}
.user-tabbing bottom-panel #bottom-panel vsc-recent-tasks-and-alarms-view clr-tabs button:focus {
  outline: 1px dotted #212121;
  outline: 5px auto -webkit-focus-ring-color;
}
.user-tabbing bottom-panel .more-tasks:focus {
  outline: 1px dotted #212121;
  outline: 5px auto -webkit-focus-ring-color;
}
bottom-panel #bottom-panel {
  height: 100%;
}
bottom-panel #bottom-panel vsc-recent-tasks-and-alarms-view {
  height: 100%;
}
bottom-panel #bottom-panel vsc-recent-tasks-and-alarms-view clr-tabs {
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
}
bottom-panel #bottom-panel vsc-recent-tasks-and-alarms-view clr-tabs button:focus {
  outline: 0;
}
bottom-panel #bottom-panel vsc-recent-tasks-and-alarms-view clr-tabs ul.nav {
  flex-shrink: 0;
  z-index: 200;
}
bottom-panel #bottom-panel vsc-recent-tasks-and-alarms-view clr-tabs clr-tab.activeTab {
  flex: 1 1 auto;
  position: relative;
}
bottom-panel #bottom-panel vsc-recent-tasks-and-alarms-view clr-tabs clr-tab.activeTab clr-tab-content {
  position: absolute;
  width: 100%;
  height: 100%;
}
bottom-panel #bottom-panel vsc-recent-tasks-and-alarms-view clr-tabs clr-tab:not(.activeTab) {
  display: none;
}
bottom-panel #bottom-panel .bottom-panel-tab-view {
  position: absolute;
  width: 100%;
  height: 100%;
}
bottom-panel .task-status-select {
  position: absolute;
  bottom: 4px;
  left: 5px;
  z-index: 1;
}
bottom-panel .more-tasks {
  position: absolute;
  bottom: 8px;
  right: 17px;
  font-size: 11px;
  z-index: 1;
}
bottom-panel .bottom-panel-tab-view .k-pager-info.k-label {
  line-height: 23px;
}
bottom-panel recent-tasks-view .k-pager-info.k-label {
  display: none;
}
bottom-panel recent-tasks-view .vui-datagrid.k-grid.k-widget .k-pager-wrap.k-grid-pager {
  height: 30px;
  line-height: 18px;
}
bottom-panel recent-tasks-view .vui-datagrid.k-grid.k-widget .k-pager-wrap.k-grid-pager vui-datagrid-footer-bar {
  float: none;
  height: 30px;
}
bottom-panel toggle-splitter {
  box-sizing: border-box !important;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  height: 36px;
  position: absolute;
  right: 0;
  top: 0;
  background: white;
  cursor: pointer;
  overflow: hidden;
  z-index: 2;
}
bottom-panel toggle-splitter:hover svg .cls-1 {
  fill: #24b3fa;
}
bottom-panel toggle-splitter svg {
  width: 12px;
  height: 12px;
  margin-right: 10px;
  -webkit-transition: all 300ms ease-in 0s;
  -moz-transition: all 300ms ease-in 0s;
  -o-transition: all 300ms ease-in 0s;
  transition: all 300ms ease-in 0s;
  display: inline-flex !important;
  overflow: hidden;
}
bottom-panel clr-tab-link {
  z-index: 1;
}
bottom-panel clr-tab-link .nav-link {
  display: inline-flex !important;
  align-items: center;
}
bottom-panel clr-tab-link .nav-link .badge {
  margin-left: 7px;
  margin-top: 2px;
  height: 18px;
  font-weight: 400;
}
bottom-panel.k-state-collapsed clr-tabs .nav-link,
bottom-panel.collapsed clr-tabs .nav-link {
  box-shadow: none !important;
}
bottom-panel.k-state-collapsed toggle-splitter svg,
bottom-panel.collapsed toggle-splitter svg {
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}
bottom-panel clr-tabs > .nav {
  padding-left: 10px;
}
bottom-panel clr-tabs clr-tab-content section {
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.k-splitter {
  box-sizing: border-box;
  position: relative;
  overflow: hidden;
}
.k-grid-content-expander {
  margin-left: 1px;
}
#center-pane .outer-tab-content {
  overflow-y: auto !important;
}
.k-grid .k-grid-pager {
  border-top-width: 1px !important;
}
.app-frame-outer .app-frame-inner {
  margin: 0 !important;
}
.k-splitbar .k-icon {
  display: none;
}
/**
 * Styles for the Resource Meter Control
 */
.resource-meter {
  margin-bottom: 6px;
}
.resource-meter span {
  font-size: 10px;
}
.resource-meter > .resource-meter-top-right-info,
.resource-meter-bottom-right-info {
  float: right;
}
.resource-meter .progress {
  margin-bottom: 0px;
  border-radius: 4px;
  border: 1px solid #cecece;
  background-image: linear-gradient(to bottom, #e2e2e2 0, #eaeaea 100%);
}
.resource-meter .normal .progress .progress-bar {
  background-image: linear-gradient(to bottom, #7ec2ed 0, #6fb3df 100%);
}
.resource-meter .overusage .progress .progress-bar {
  background-image: linear-gradient(to bottom, #ff5a5a 0, #ec3d3d 100%);
}
.vertical-resource-meter {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 20px;
  height: 12px;
  margin-bottom: 0px;
  margin-left: -5px;
  margin-right: 0px;
  -webkit-transform: rotate(-90deg);
  -moz-transform: rotate(-90deg);
  transform: rotate(-90deg);
  border: 1px solid;
  display: inline-block;
}
.vertical-resource-meter .meter-gauge {
  width: 100%;
  height: 10px;
  display: block;
  background: #dfdfdf;
}
.vertical-resource-meter .meter-gauge,
.vertical-resource-meter .meter-gauge .meter-gauge-value {
  box-shadow: 0 5px 5px -5px #999 inset;
}
.horizontal-resource-meter {
  width: 100%;
}
.horizontal-resource-meter .meter-gauge {
  border: none;
  background: #dfdfdf;
  height: 12px;
  display: block;
}
.horizontal-resource-meter .meter-gauge .meter-gauge-value {
  background: #4679b9;
}
.meter-gauge .meter-gauge-value {
  height: inherit;
  background-size: 100% 100%;
  display: block;
}
.vertical-resource-meter .resource-meter-usage-normal {
  background: #73FF27;
}
.vertical-resource-meter .resource-meter-usage-warning {
  background: #F9FF27;
}
.vertical-resource-meter .resource-meter-usage-danger {
  background: #FC6F6F;
}
.settingsBlock {
  margin: 0;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  height: 100%;
  width: 100%;
}
.settingsBlockTitle {
  font-weight: bold;
  color: #666666;
  margin-left: 7px;
}
.settingsBlockHeader {
  margin-bottom: 12px;
  background: #F5F5F5;
  /* Old browsers */
  background: -moz-linear-gradient(top, #F5F5F5 0%, #FFFFFF 100%);
  /* FF3.6+ */
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #F5F5F5), color-stop(100%, #FFFFFF));
  /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(top, #F5F5F5 0%, #FFFFFF 100%);
  /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(top, #F5F5F5 0%, #FFFFFF 100%);
  /* Opera 11.10+ */
  background: -ms-linear-gradient(top, #F5F5F5 0%, #FFFFFF 100%);
  /* IE10+ */
  background: linear-gradient(to bottom, #F5F5F5 0%, #FFFFFF 100%);
  /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='@headerGradientTopColor', endColorstr='@headerGradientBottomColor', GradientType=0);
  /* IE6-9 */
}
.settingsBlockButton {
  float: right;
  margin-left: 8px;
}
vx-tabbed-view.flexbox-column .nav.nav-tabs {
  height: auto;
}
.vui-stack-view .stack-view-table .stack-view-row-i .stack-view-cell [class*=icon],
.vui-stack-view .stack-view-table .stack-view-row .stack-view-cell [class*=icon] {
  position: static;
}
.vui-stack-view .stack-view-table .stack-view-row-i:first-of-type .stack-view-cell {
  padding: 5px 5px;
}
.vui-stack-view .stack-view-table .stack-view-row-i:first-of-type .stack-view-cell.warning {
  background-color: #fbd6d6;
}
.vui-stack-view .stack-view-row-i:not(:hover) .stack-view-row-action,
.vui-stack-view .vui-stack-view .stack-view-row:not(:hover) .stack-view-row-action {
  display: none;
}
.vui-stack-view-padded {
  margin-bottom: 11px;
}
.stack-view-cell.respect-newlines {
  white-space: pre-line;
}
.stack-view-cell .right-arrow,
.stack-view-cell .down-arrow {
  margin: 2px 0 0 0;
}
label.stack-view-cell {
  font-weight: normal;
}
.hide-expansion-arrow .stack-view-cell:first-child {
  pointer-events: none;
}
.hide-expansion-arrow .stack-view-cell:before {
  display: none !important;
}
.stack-view ul {
  margin: 0;
}
.stack-view ul li {
  list-style: none;
}
/* Use for all summary pages.
 * TODO: Define boundary between app styles and normal styles*/
.summary-toggle {
  display: flex;
  flex-direction: row;
  align-items: baseline;
}
.summary-toggle .form-block {
  margin-left: auto;
}
.summary-toggle .form-block .toggle-switch {
  margin-right: 0;
}
.summary-toggle .form-block .toggle-switch label {
  box-sizing: border-box;
}
.summary-toggle .form-block .toggle-switch label:before {
  box-sizing: border-box;
}
.summary-toggle .form-block .summary-toggle-label {
  line-height: 24px;
}
.summary-toggle vsc-sign-post {
  line-height: 1rem;
}
.summary {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
}
.summary-image-block {
  margin-right: 20px;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
}
.summary-info-block {
  -webkit-flex: 1 1 auto;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  padding-bottom: 10px;
}
.summary-info-block li {
  display: table-row-group;
}
.summary-info-block vx-summary-item {
  display: table-row;
}
.summary-info-block .summary-label {
  white-space: nowrap;
}
.summary-info-block .summary-value {
  padding-left: 8px;
}
.summary-info-block .summary-label,
.summary-info-block .summary-value {
  float: none;
  display: table-cell;
  width: auto;
}
h6.summary-name-label {
  font-weight: 200;
  display: inline-block;
}
.summary-label {
  float: left;
  width: 155px;
}
.summary-value {
  min-width: 10px;
  display: inline-block;
}
.summary-image-thumbnail,
.summary-image-state-label {
  width: 160px;
  height: 120px;
  border: 1px solid black;
}
.unlock-vm {
  position: absolute;
  margin-top: 10px;
}
.locked-vm-signpost-content {
  margin-top: 10px;
}
.launch-web-console-text-disabled {
  color: #bbb;
  pointer-events: none;
}
.launch-web-console {
  position: absolute;
  margin-top: 10px;
}
.launch-remote-console {
  position: absolute;
  margin-top: 30px;
}
.launch-remote-console-text {
  vertical-align: bottom;
}
.launch-remote-console-icon {
  margin-left: 5px;
}
.summary-image-state-label {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.summary-image-thumbnail-container {
  position: relative;
  cursor: pointer;
  min-height: 120px;
  min-width: 160px;
}
.summary-image-caption {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 5px 0;
}
/*TODO: Can't this just use the Bootstrap meter?*/
.summary-meters-block {
  min-width: 230px;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
}
.summary-action-container {
  -webkit-justify-content: flex-end;
  -ms-flex-pack: flex-end;
  justify-content: flex-end;
}
.summary-action-container a.disabled {
  color: #bbb;
  pointer-events: none;
}
.summary-action-container > a {
  margin-left: 8px;
}
.summary-badges {
  display: inline-flex;
  min-height: 24px;
}
.badge-item {
  margin-right: 7px;
  width: 24px;
  height: 24px;
}
.summary-icons-block {
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
}
.summary-icons-block td {
  padding: 4px;
}
.summary-icons-block .resource-label {
  text-transform: uppercase;
  font-size: 11px;
}
.summary-icons-block .resource-value {
  font-weight: 500;
  font-size: 14px;
}
.summary-items-list {
  list-style-type: none;
  margin: 0 0 10px 0;
}
.titlebar .object {
  align-items: center;
}
.titlebar .object > .titlebar-icon {
  transform: scale(1.2);
  margin-right: 10px;
  flex: 0 0 auto;
}
.titlebar .object > .titlebar-title {
  line-height: 1rem;
  color: #000;
  font-size: 22px;
  font-weight: 200;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  margin-top: 0;
}
.titlebar .object > .titlebar-title .sr-only {
  -webkit-user-select: none;
  /* Chrome all / Safari all */
  -moz-user-select: none;
  /* Firefox all */
  -ms-user-select: none;
  /* IE 10+ */
  user-select: none;
}
.titlebar .object > .titlebar-divider {
  width: 1px;
  height: 26px;
  background: #bbb;
  margin: 0 18px;
}
.titlebar .object > .titlebar-actionable .summary-action-link {
  font-size: 12px;
  letter-spacing: 1px;
  color: #0079b8;
}
.titlebar .object > .titlebar-actionable .summary-action-link .actionable-title {
  text-transform: uppercase;
  font-weight: normal;
}
.titlebar .object > .titlebar-actionable .summary-action-link .actionable-toggle {
  vertical-align: inherit;
}
.titlebar .object > .titlebar-actionable .summary-action-link:hover,
.titlebar .object > .titlebar-actionable .summary-action-link:active {
  color: #004a70;
  cursor: pointer;
  text-decoration: none;
}
.icon-dropdownToggle::before {
  font-family: "FontAwesome";
  content: "\f107";
}
.ip-sign-post,
.vm-tools-sign-post {
  -webkit-user-select: inherit;
}
.ip-sign-post header,
.vm-tools-sign-post header {
  font-weight: bold;
  margin-bottom: 8px;
}
.ip-sign-post ul,
.vm-tools-sign-post ul {
  margin-left: 0px;
  list-style-type: none;
}
.vm-tools-info-label {
  display: table-cell;
  width: 100px;
}
.vm-tools-info-row {
  display: table-row-group;
}
.view-all-issues {
  margin-top: 6px;
}
@media (min-width: 1000px) {
  .vx-portlet-two-columns .col {
    max-width: 50%;
  }
}
.table-layout {
  border: none;
}
.table-layout td {
  border: none;
  padding-right: 5px;
  padding-bottom: 5px;
}
[vx-tab-headers] {
  padding: 10px 0;
}
[vx-tab-content] {
  position: relative;
}
.user-tabbing [vx-navi-tab-headers],
.user-tabbing .vx-tab-wrapper {
  /* Vui tabs */
  /* Vui second level tabs */
}
.user-tabbing [vx-navi-tab-headers] .nav > li:focus,
.user-tabbing .vx-tab-wrapper .nav > li:focus {
  outline: 1px dotted #212121;
  outline-offset: -1px;
  outline: 5px auto -webkit-focus-ring-color;
}
.user-tabbing [vx-navi-tab-headers] .nav-secondary > button:focus,
.user-tabbing .vx-tab-wrapper .nav-secondary > button:focus {
  outline: 1px dotted #212121;
  outline: 5px auto -webkit-focus-ring-color;
}
[vx-tab-pane] {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
[vx-tabs] [vx-tab-pane] {
  display: none;
}
[vx-tabs] [vx-tab-pane].active {
  display: block;
}
[vx-tabs-flexy] [vx-tab-pane].active {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}
.vui-panel .vx-tab-wrapper[level="first"],
.vui-wizard .vx-tab-wrapper[level="first"],
.modal-dialog .vx-tab-wrapper[level="first"],
.vui-panel [vx-navi-tab-headers][level="first"],
.vui-wizard [vx-navi-tab-headers][level="first"],
.modal-dialog [vx-navi-tab-headers][level="first"],
.vui-panel .wizard-modal-content[level="first"],
.vui-wizard .wizard-modal-content[level="first"],
.modal-dialog .wizard-modal-content[level="first"],
.vui-panel .modal-content[level="first"],
.vui-wizard .modal-content[level="first"],
.modal-dialog .modal-content[level="first"],
.vui-panel .vx-tab-wrapper[level="details"],
.vui-wizard .vx-tab-wrapper[level="details"],
.modal-dialog .vx-tab-wrapper[level="details"],
.vui-panel [vx-navi-tab-headers][level="details"],
.vui-wizard [vx-navi-tab-headers][level="details"],
.modal-dialog [vx-navi-tab-headers][level="details"],
.vui-panel .wizard-modal-content[level="details"],
.vui-wizard .wizard-modal-content[level="details"],
.modal-dialog .wizard-modal-content[level="details"],
.vui-panel .modal-content[level="details"],
.vui-wizard .modal-content[level="details"],
.modal-dialog .modal-content[level="details"] {
  padding: 16px 20px;
}
.vui-panel .vx-tab-wrapper[level="first"] .nav.nav-tabs,
.vui-wizard .vx-tab-wrapper[level="first"] .nav.nav-tabs,
.modal-dialog .vx-tab-wrapper[level="first"] .nav.nav-tabs,
.vui-panel [vx-navi-tab-headers][level="first"] .nav.nav-tabs,
.vui-wizard [vx-navi-tab-headers][level="first"] .nav.nav-tabs,
.modal-dialog [vx-navi-tab-headers][level="first"] .nav.nav-tabs,
.vui-panel .wizard-modal-content[level="first"] .nav.nav-tabs,
.vui-wizard .wizard-modal-content[level="first"] .nav.nav-tabs,
.modal-dialog .wizard-modal-content[level="first"] .nav.nav-tabs,
.vui-panel .modal-content[level="first"] .nav.nav-tabs,
.vui-wizard .modal-content[level="first"] .nav.nav-tabs,
.modal-dialog .modal-content[level="first"] .nav.nav-tabs,
.vui-panel .vx-tab-wrapper[level="details"] .nav.nav-tabs,
.vui-wizard .vx-tab-wrapper[level="details"] .nav.nav-tabs,
.modal-dialog .vx-tab-wrapper[level="details"] .nav.nav-tabs,
.vui-panel [vx-navi-tab-headers][level="details"] .nav.nav-tabs,
.vui-wizard [vx-navi-tab-headers][level="details"] .nav.nav-tabs,
.modal-dialog [vx-navi-tab-headers][level="details"] .nav.nav-tabs,
.vui-panel .wizard-modal-content[level="details"] .nav.nav-tabs,
.vui-wizard .wizard-modal-content[level="details"] .nav.nav-tabs,
.modal-dialog .wizard-modal-content[level="details"] .nav.nav-tabs,
.vui-panel .modal-content[level="details"] .nav.nav-tabs,
.vui-wizard .modal-content[level="details"] .nav.nav-tabs,
.modal-dialog .modal-content[level="details"] .nav.nav-tabs {
  height: 29px;
  border-bottom: 1px solid #ddd;
  background: none;
  padding-bottom: 0;
  overflow: hidden;
}
.vui-panel .vx-tab-wrapper[level="first"] .nav.nav-tabs > LI,
.vui-wizard .vx-tab-wrapper[level="first"] .nav.nav-tabs > LI,
.modal-dialog .vx-tab-wrapper[level="first"] .nav.nav-tabs > LI,
.vui-panel [vx-navi-tab-headers][level="first"] .nav.nav-tabs > LI,
.vui-wizard [vx-navi-tab-headers][level="first"] .nav.nav-tabs > LI,
.modal-dialog [vx-navi-tab-headers][level="first"] .nav.nav-tabs > LI,
.vui-panel .wizard-modal-content[level="first"] .nav.nav-tabs > LI,
.vui-wizard .wizard-modal-content[level="first"] .nav.nav-tabs > LI,
.modal-dialog .wizard-modal-content[level="first"] .nav.nav-tabs > LI,
.vui-panel .modal-content[level="first"] .nav.nav-tabs > LI,
.vui-wizard .modal-content[level="first"] .nav.nav-tabs > LI,
.modal-dialog .modal-content[level="first"] .nav.nav-tabs > LI,
.vui-panel .vx-tab-wrapper[level="details"] .nav.nav-tabs > LI,
.vui-wizard .vx-tab-wrapper[level="details"] .nav.nav-tabs > LI,
.modal-dialog .vx-tab-wrapper[level="details"] .nav.nav-tabs > LI,
.vui-panel [vx-navi-tab-headers][level="details"] .nav.nav-tabs > LI,
.vui-wizard [vx-navi-tab-headers][level="details"] .nav.nav-tabs > LI,
.modal-dialog [vx-navi-tab-headers][level="details"] .nav.nav-tabs > LI,
.vui-panel .wizard-modal-content[level="details"] .nav.nav-tabs > LI,
.vui-wizard .wizard-modal-content[level="details"] .nav.nav-tabs > LI,
.modal-dialog .wizard-modal-content[level="details"] .nav.nav-tabs > LI,
.vui-panel .modal-content[level="details"] .nav.nav-tabs > LI,
.vui-wizard .modal-content[level="details"] .nav.nav-tabs > LI,
.modal-dialog .modal-content[level="details"] .nav.nav-tabs > LI {
  font-size: 14px;
  height: inherit;
  margin-bottom: 0;
}
.vui-panel .vx-tab-wrapper[level="first"] .nav.nav-tabs > LI:not(:first-child),
.vui-wizard .vx-tab-wrapper[level="first"] .nav.nav-tabs > LI:not(:first-child),
.modal-dialog .vx-tab-wrapper[level="first"] .nav.nav-tabs > LI:not(:first-child),
.vui-panel [vx-navi-tab-headers][level="first"] .nav.nav-tabs > LI:not(:first-child),
.vui-wizard [vx-navi-tab-headers][level="first"] .nav.nav-tabs > LI:not(:first-child),
.modal-dialog [vx-navi-tab-headers][level="first"] .nav.nav-tabs > LI:not(:first-child),
.vui-panel .wizard-modal-content[level="first"] .nav.nav-tabs > LI:not(:first-child),
.vui-wizard .wizard-modal-content[level="first"] .nav.nav-tabs > LI:not(:first-child),
.modal-dialog .wizard-modal-content[level="first"] .nav.nav-tabs > LI:not(:first-child),
.vui-panel .modal-content[level="first"] .nav.nav-tabs > LI:not(:first-child),
.vui-wizard .modal-content[level="first"] .nav.nav-tabs > LI:not(:first-child),
.modal-dialog .modal-content[level="first"] .nav.nav-tabs > LI:not(:first-child),
.vui-panel .vx-tab-wrapper[level="details"] .nav.nav-tabs > LI:not(:first-child),
.vui-wizard .vx-tab-wrapper[level="details"] .nav.nav-tabs > LI:not(:first-child),
.modal-dialog .vx-tab-wrapper[level="details"] .nav.nav-tabs > LI:not(:first-child),
.vui-panel [vx-navi-tab-headers][level="details"] .nav.nav-tabs > LI:not(:first-child),
.vui-wizard [vx-navi-tab-headers][level="details"] .nav.nav-tabs > LI:not(:first-child),
.modal-dialog [vx-navi-tab-headers][level="details"] .nav.nav-tabs > LI:not(:first-child),
.vui-panel .wizard-modal-content[level="details"] .nav.nav-tabs > LI:not(:first-child),
.vui-wizard .wizard-modal-content[level="details"] .nav.nav-tabs > LI:not(:first-child),
.modal-dialog .wizard-modal-content[level="details"] .nav.nav-tabs > LI:not(:first-child),
.vui-panel .modal-content[level="details"] .nav.nav-tabs > LI:not(:first-child),
.vui-wizard .modal-content[level="details"] .nav.nav-tabs > LI:not(:first-child),
.modal-dialog .modal-content[level="details"] .nav.nav-tabs > LI:not(:first-child) {
  margin-left: 15px;
}
.vui-panel .vx-tab-wrapper[level="first"] .nav.nav-tabs > LI:not(:last-child),
.vui-wizard .vx-tab-wrapper[level="first"] .nav.nav-tabs > LI:not(:last-child),
.modal-dialog .vx-tab-wrapper[level="first"] .nav.nav-tabs > LI:not(:last-child),
.vui-panel [vx-navi-tab-headers][level="first"] .nav.nav-tabs > LI:not(:last-child),
.vui-wizard [vx-navi-tab-headers][level="first"] .nav.nav-tabs > LI:not(:last-child),
.modal-dialog [vx-navi-tab-headers][level="first"] .nav.nav-tabs > LI:not(:last-child),
.vui-panel .wizard-modal-content[level="first"] .nav.nav-tabs > LI:not(:last-child),
.vui-wizard .wizard-modal-content[level="first"] .nav.nav-tabs > LI:not(:last-child),
.modal-dialog .wizard-modal-content[level="first"] .nav.nav-tabs > LI:not(:last-child),
.vui-panel .modal-content[level="first"] .nav.nav-tabs > LI:not(:last-child),
.vui-wizard .modal-content[level="first"] .nav.nav-tabs > LI:not(:last-child),
.modal-dialog .modal-content[level="first"] .nav.nav-tabs > LI:not(:last-child),
.vui-panel .vx-tab-wrapper[level="details"] .nav.nav-tabs > LI:not(:last-child),
.vui-wizard .vx-tab-wrapper[level="details"] .nav.nav-tabs > LI:not(:last-child),
.modal-dialog .vx-tab-wrapper[level="details"] .nav.nav-tabs > LI:not(:last-child),
.vui-panel [vx-navi-tab-headers][level="details"] .nav.nav-tabs > LI:not(:last-child),
.vui-wizard [vx-navi-tab-headers][level="details"] .nav.nav-tabs > LI:not(:last-child),
.modal-dialog [vx-navi-tab-headers][level="details"] .nav.nav-tabs > LI:not(:last-child),
.vui-panel .wizard-modal-content[level="details"] .nav.nav-tabs > LI:not(:last-child),
.vui-wizard .wizard-modal-content[level="details"] .nav.nav-tabs > LI:not(:last-child),
.modal-dialog .wizard-modal-content[level="details"] .nav.nav-tabs > LI:not(:last-child),
.vui-panel .modal-content[level="details"] .nav.nav-tabs > LI:not(:last-child),
.vui-wizard .modal-content[level="details"] .nav.nav-tabs > LI:not(:last-child),
.modal-dialog .modal-content[level="details"] .nav.nav-tabs > LI:not(:last-child) {
  margin-right: 15px;
}
.vui-panel .vx-tab-wrapper[level="first"] .nav.nav-tabs > LI > A,
.vui-wizard .vx-tab-wrapper[level="first"] .nav.nav-tabs > LI > A,
.modal-dialog .vx-tab-wrapper[level="first"] .nav.nav-tabs > LI > A,
.vui-panel [vx-navi-tab-headers][level="first"] .nav.nav-tabs > LI > A,
.vui-wizard [vx-navi-tab-headers][level="first"] .nav.nav-tabs > LI > A,
.modal-dialog [vx-navi-tab-headers][level="first"] .nav.nav-tabs > LI > A,
.vui-panel .wizard-modal-content[level="first"] .nav.nav-tabs > LI > A,
.vui-wizard .wizard-modal-content[level="first"] .nav.nav-tabs > LI > A,
.modal-dialog .wizard-modal-content[level="first"] .nav.nav-tabs > LI > A,
.vui-panel .modal-content[level="first"] .nav.nav-tabs > LI > A,
.vui-wizard .modal-content[level="first"] .nav.nav-tabs > LI > A,
.modal-dialog .modal-content[level="first"] .nav.nav-tabs > LI > A,
.vui-panel .vx-tab-wrapper[level="details"] .nav.nav-tabs > LI > A,
.vui-wizard .vx-tab-wrapper[level="details"] .nav.nav-tabs > LI > A,
.modal-dialog .vx-tab-wrapper[level="details"] .nav.nav-tabs > LI > A,
.vui-panel [vx-navi-tab-headers][level="details"] .nav.nav-tabs > LI > A,
.vui-wizard [vx-navi-tab-headers][level="details"] .nav.nav-tabs > LI > A,
.modal-dialog [vx-navi-tab-headers][level="details"] .nav.nav-tabs > LI > A,
.vui-panel .wizard-modal-content[level="details"] .nav.nav-tabs > LI > A,
.vui-wizard .wizard-modal-content[level="details"] .nav.nav-tabs > LI > A,
.modal-dialog .wizard-modal-content[level="details"] .nav.nav-tabs > LI > A,
.vui-panel .modal-content[level="details"] .nav.nav-tabs > LI > A,
.vui-wizard .modal-content[level="details"] .nav.nav-tabs > LI > A,
.modal-dialog .modal-content[level="details"] .nav.nav-tabs > LI > A {
  color: #565656;
  margin: 0;
  padding-top: 1px;
  padding-bottom: 16px;
}
.vui-panel .vx-tab-wrapper[level="first"] .nav.nav-tabs > LI.active > A,
.vui-wizard .vx-tab-wrapper[level="first"] .nav.nav-tabs > LI.active > A,
.modal-dialog .vx-tab-wrapper[level="first"] .nav.nav-tabs > LI.active > A,
.vui-panel [vx-navi-tab-headers][level="first"] .nav.nav-tabs > LI.active > A,
.vui-wizard [vx-navi-tab-headers][level="first"] .nav.nav-tabs > LI.active > A,
.modal-dialog [vx-navi-tab-headers][level="first"] .nav.nav-tabs > LI.active > A,
.vui-panel .wizard-modal-content[level="first"] .nav.nav-tabs > LI.active > A,
.vui-wizard .wizard-modal-content[level="first"] .nav.nav-tabs > LI.active > A,
.modal-dialog .wizard-modal-content[level="first"] .nav.nav-tabs > LI.active > A,
.vui-panel .modal-content[level="first"] .nav.nav-tabs > LI.active > A,
.vui-wizard .modal-content[level="first"] .nav.nav-tabs > LI.active > A,
.modal-dialog .modal-content[level="first"] .nav.nav-tabs > LI.active > A,
.vui-panel .vx-tab-wrapper[level="details"] .nav.nav-tabs > LI.active > A,
.vui-wizard .vx-tab-wrapper[level="details"] .nav.nav-tabs > LI.active > A,
.modal-dialog .vx-tab-wrapper[level="details"] .nav.nav-tabs > LI.active > A,
.vui-panel [vx-navi-tab-headers][level="details"] .nav.nav-tabs > LI.active > A,
.vui-wizard [vx-navi-tab-headers][level="details"] .nav.nav-tabs > LI.active > A,
.modal-dialog [vx-navi-tab-headers][level="details"] .nav.nav-tabs > LI.active > A,
.vui-panel .wizard-modal-content[level="details"] .nav.nav-tabs > LI.active > A,
.vui-wizard .wizard-modal-content[level="details"] .nav.nav-tabs > LI.active > A,
.modal-dialog .wizard-modal-content[level="details"] .nav.nav-tabs > LI.active > A,
.vui-panel .modal-content[level="details"] .nav.nav-tabs > LI.active > A,
.vui-wizard .modal-content[level="details"] .nav.nav-tabs > LI.active > A,
.modal-dialog .modal-content[level="details"] .nav.nav-tabs > LI.active > A {
  font-size: inherit;
  font-weight: inherit;
  color: #000;
}
.vui-panel .vx-tab-wrapper[level="first"] .nav.nav-tabs > LI.active > A:hover,
.vui-wizard .vx-tab-wrapper[level="first"] .nav.nav-tabs > LI.active > A:hover,
.modal-dialog .vx-tab-wrapper[level="first"] .nav.nav-tabs > LI.active > A:hover,
.vui-panel [vx-navi-tab-headers][level="first"] .nav.nav-tabs > LI.active > A:hover,
.vui-wizard [vx-navi-tab-headers][level="first"] .nav.nav-tabs > LI.active > A:hover,
.modal-dialog [vx-navi-tab-headers][level="first"] .nav.nav-tabs > LI.active > A:hover,
.vui-panel .wizard-modal-content[level="first"] .nav.nav-tabs > LI.active > A:hover,
.vui-wizard .wizard-modal-content[level="first"] .nav.nav-tabs > LI.active > A:hover,
.modal-dialog .wizard-modal-content[level="first"] .nav.nav-tabs > LI.active > A:hover,
.vui-panel .modal-content[level="first"] .nav.nav-tabs > LI.active > A:hover,
.vui-wizard .modal-content[level="first"] .nav.nav-tabs > LI.active > A:hover,
.modal-dialog .modal-content[level="first"] .nav.nav-tabs > LI.active > A:hover,
.vui-panel .vx-tab-wrapper[level="details"] .nav.nav-tabs > LI.active > A:hover,
.vui-wizard .vx-tab-wrapper[level="details"] .nav.nav-tabs > LI.active > A:hover,
.modal-dialog .vx-tab-wrapper[level="details"] .nav.nav-tabs > LI.active > A:hover,
.vui-panel [vx-navi-tab-headers][level="details"] .nav.nav-tabs > LI.active > A:hover,
.vui-wizard [vx-navi-tab-headers][level="details"] .nav.nav-tabs > LI.active > A:hover,
.modal-dialog [vx-navi-tab-headers][level="details"] .nav.nav-tabs > LI.active > A:hover,
.vui-panel .wizard-modal-content[level="details"] .nav.nav-tabs > LI.active > A:hover,
.vui-wizard .wizard-modal-content[level="details"] .nav.nav-tabs > LI.active > A:hover,
.modal-dialog .wizard-modal-content[level="details"] .nav.nav-tabs > LI.active > A:hover,
.vui-panel .modal-content[level="details"] .nav.nav-tabs > LI.active > A:hover,
.vui-wizard .modal-content[level="details"] .nav.nav-tabs > LI.active > A:hover,
.modal-dialog .modal-content[level="details"] .nav.nav-tabs > LI.active > A:hover {
  border-bottom-width: 3px;
}
.vui-panel .vx-tab-wrapper[level="second"],
.vui-wizard .vx-tab-wrapper[level="second"],
.modal-dialog .vx-tab-wrapper[level="second"],
.vui-panel [vx-navi-tab-headers][level="second"],
.vui-wizard [vx-navi-tab-headers][level="second"],
.modal-dialog [vx-navi-tab-headers][level="second"],
.vui-panel .wizard-modal-content[level="second"],
.vui-wizard .wizard-modal-content[level="second"],
.modal-dialog .wizard-modal-content[level="second"],
.vui-panel .modal-content[level="second"],
.vui-wizard .modal-content[level="second"],
.modal-dialog .modal-content[level="second"] {
  padding: 8px 0;
  display: flex;
}
.vui-panel .vx-tab-wrapper[level="second"] .btn-group,
.vui-wizard .vx-tab-wrapper[level="second"] .btn-group,
.modal-dialog .vx-tab-wrapper[level="second"] .btn-group,
.vui-panel [vx-navi-tab-headers][level="second"] .btn-group,
.vui-wizard [vx-navi-tab-headers][level="second"] .btn-group,
.modal-dialog [vx-navi-tab-headers][level="second"] .btn-group,
.vui-panel .wizard-modal-content[level="second"] .btn-group,
.vui-wizard .wizard-modal-content[level="second"] .btn-group,
.modal-dialog .wizard-modal-content[level="second"] .btn-group,
.vui-panel .modal-content[level="second"] .btn-group,
.vui-wizard .modal-content[level="second"] .btn-group,
.modal-dialog .modal-content[level="second"] .btn-group {
  background-color: #0079b8;
  padding: 1px;
  border-radius: 3px;
  display: flex;
  overflow-x: hidden;
}
.vui-panel .vx-tab-wrapper[level="second"] .btn-group > button:not(:last-child),
.vui-wizard .vx-tab-wrapper[level="second"] .btn-group > button:not(:last-child),
.modal-dialog .vx-tab-wrapper[level="second"] .btn-group > button:not(:last-child),
.vui-panel [vx-navi-tab-headers][level="second"] .btn-group > button:not(:last-child),
.vui-wizard [vx-navi-tab-headers][level="second"] .btn-group > button:not(:last-child),
.modal-dialog [vx-navi-tab-headers][level="second"] .btn-group > button:not(:last-child),
.vui-panel .wizard-modal-content[level="second"] .btn-group > button:not(:last-child),
.vui-wizard .wizard-modal-content[level="second"] .btn-group > button:not(:last-child),
.modal-dialog .wizard-modal-content[level="second"] .btn-group > button:not(:last-child),
.vui-panel .modal-content[level="second"] .btn-group > button:not(:last-child),
.vui-wizard .modal-content[level="second"] .btn-group > button:not(:last-child),
.modal-dialog .modal-content[level="second"] .btn-group > button:not(:last-child),
.vui-panel .vx-tab-wrapper[level="second"] .btn-group > button.active:not(:last-child),
.vui-wizard .vx-tab-wrapper[level="second"] .btn-group > button.active:not(:last-child),
.modal-dialog .vx-tab-wrapper[level="second"] .btn-group > button.active:not(:last-child),
.vui-panel [vx-navi-tab-headers][level="second"] .btn-group > button.active:not(:last-child),
.vui-wizard [vx-navi-tab-headers][level="second"] .btn-group > button.active:not(:last-child),
.modal-dialog [vx-navi-tab-headers][level="second"] .btn-group > button.active:not(:last-child),
.vui-panel .wizard-modal-content[level="second"] .btn-group > button.active:not(:last-child),
.vui-wizard .wizard-modal-content[level="second"] .btn-group > button.active:not(:last-child),
.modal-dialog .wizard-modal-content[level="second"] .btn-group > button.active:not(:last-child),
.vui-panel .modal-content[level="second"] .btn-group > button.active:not(:last-child),
.vui-wizard .modal-content[level="second"] .btn-group > button.active:not(:last-child),
.modal-dialog .modal-content[level="second"] .btn-group > button.active:not(:last-child),
.vui-panel .vx-tab-wrapper[level="second"] .btn-group > button:last-child,
.vui-wizard .vx-tab-wrapper[level="second"] .btn-group > button:last-child,
.modal-dialog .vx-tab-wrapper[level="second"] .btn-group > button:last-child,
.vui-panel [vx-navi-tab-headers][level="second"] .btn-group > button:last-child,
.vui-wizard [vx-navi-tab-headers][level="second"] .btn-group > button:last-child,
.modal-dialog [vx-navi-tab-headers][level="second"] .btn-group > button:last-child,
.vui-panel .wizard-modal-content[level="second"] .btn-group > button:last-child,
.vui-wizard .wizard-modal-content[level="second"] .btn-group > button:last-child,
.modal-dialog .wizard-modal-content[level="second"] .btn-group > button:last-child,
.vui-panel .modal-content[level="second"] .btn-group > button:last-child,
.vui-wizard .modal-content[level="second"] .btn-group > button:last-child,
.modal-dialog .modal-content[level="second"] .btn-group > button:last-child {
  border-color: #0079b8;
}
.vui-panel .vx-tab-wrapper[level="second"] .btn-group > .btn,
.vui-wizard .vx-tab-wrapper[level="second"] .btn-group > .btn,
.modal-dialog .vx-tab-wrapper[level="second"] .btn-group > .btn,
.vui-panel [vx-navi-tab-headers][level="second"] .btn-group > .btn,
.vui-wizard [vx-navi-tab-headers][level="second"] .btn-group > .btn,
.modal-dialog [vx-navi-tab-headers][level="second"] .btn-group > .btn,
.vui-panel .wizard-modal-content[level="second"] .btn-group > .btn,
.vui-wizard .wizard-modal-content[level="second"] .btn-group > .btn,
.modal-dialog .wizard-modal-content[level="second"] .btn-group > .btn,
.vui-panel .modal-content[level="second"] .btn-group > .btn,
.vui-wizard .modal-content[level="second"] .btn-group > .btn,
.modal-dialog .modal-content[level="second"] .btn-group > .btn {
  text-transform: none;
  margin: 0;
  background-color: #fff;
  color: #0079b8;
  border: 0;
  cursor: pointer;
  padding: 0 15px;
  letter-spacing: 0.1px;
  font-size: 13px;
  font-weight: 400;
}
.vui-panel .vx-tab-wrapper[level="second"] .btn-group > .btn:not(:first-child),
.vui-wizard .vx-tab-wrapper[level="second"] .btn-group > .btn:not(:first-child),
.modal-dialog .vx-tab-wrapper[level="second"] .btn-group > .btn:not(:first-child),
.vui-panel [vx-navi-tab-headers][level="second"] .btn-group > .btn:not(:first-child),
.vui-wizard [vx-navi-tab-headers][level="second"] .btn-group > .btn:not(:first-child),
.modal-dialog [vx-navi-tab-headers][level="second"] .btn-group > .btn:not(:first-child),
.vui-panel .wizard-modal-content[level="second"] .btn-group > .btn:not(:first-child),
.vui-wizard .wizard-modal-content[level="second"] .btn-group > .btn:not(:first-child),
.modal-dialog .wizard-modal-content[level="second"] .btn-group > .btn:not(:first-child),
.vui-panel .modal-content[level="second"] .btn-group > .btn:not(:first-child),
.vui-wizard .modal-content[level="second"] .btn-group > .btn:not(:first-child),
.modal-dialog .modal-content[level="second"] .btn-group > .btn:not(:first-child) {
  margin-left: 0.5px;
}
.vui-panel .vx-tab-wrapper[level="second"] .btn-group > .btn:not(:last-child),
.vui-wizard .vx-tab-wrapper[level="second"] .btn-group > .btn:not(:last-child),
.modal-dialog .vx-tab-wrapper[level="second"] .btn-group > .btn:not(:last-child),
.vui-panel [vx-navi-tab-headers][level="second"] .btn-group > .btn:not(:last-child),
.vui-wizard [vx-navi-tab-headers][level="second"] .btn-group > .btn:not(:last-child),
.modal-dialog [vx-navi-tab-headers][level="second"] .btn-group > .btn:not(:last-child),
.vui-panel .wizard-modal-content[level="second"] .btn-group > .btn:not(:last-child),
.vui-wizard .wizard-modal-content[level="second"] .btn-group > .btn:not(:last-child),
.modal-dialog .wizard-modal-content[level="second"] .btn-group > .btn:not(:last-child),
.vui-panel .modal-content[level="second"] .btn-group > .btn:not(:last-child),
.vui-wizard .modal-content[level="second"] .btn-group > .btn:not(:last-child),
.modal-dialog .modal-content[level="second"] .btn-group > .btn:not(:last-child) {
  margin-right: 0.5px;
}
.vui-panel .vx-tab-wrapper[level="second"] .btn-group > .btn.active,
.vui-wizard .vx-tab-wrapper[level="second"] .btn-group > .btn.active,
.modal-dialog .vx-tab-wrapper[level="second"] .btn-group > .btn.active,
.vui-panel [vx-navi-tab-headers][level="second"] .btn-group > .btn.active,
.vui-wizard [vx-navi-tab-headers][level="second"] .btn-group > .btn.active,
.modal-dialog [vx-navi-tab-headers][level="second"] .btn-group > .btn.active,
.vui-panel .wizard-modal-content[level="second"] .btn-group > .btn.active,
.vui-wizard .wizard-modal-content[level="second"] .btn-group > .btn.active,
.modal-dialog .wizard-modal-content[level="second"] .btn-group > .btn.active,
.vui-panel .modal-content[level="second"] .btn-group > .btn.active,
.vui-wizard .modal-content[level="second"] .btn-group > .btn.active,
.modal-dialog .modal-content[level="second"] .btn-group > .btn.active {
  color: #fff;
  background-color: #0079b8;
  cursor: not-allowed;
}
.vui-panel .vx-tab-wrapper[level="second"] .btn-group > .btn:not(.active):active,
.vui-wizard .vx-tab-wrapper[level="second"] .btn-group > .btn:not(.active):active,
.modal-dialog .vx-tab-wrapper[level="second"] .btn-group > .btn:not(.active):active,
.vui-panel [vx-navi-tab-headers][level="second"] .btn-group > .btn:not(.active):active,
.vui-wizard [vx-navi-tab-headers][level="second"] .btn-group > .btn:not(.active):active,
.modal-dialog [vx-navi-tab-headers][level="second"] .btn-group > .btn:not(.active):active,
.vui-panel .wizard-modal-content[level="second"] .btn-group > .btn:not(.active):active,
.vui-wizard .wizard-modal-content[level="second"] .btn-group > .btn:not(.active):active,
.modal-dialog .wizard-modal-content[level="second"] .btn-group > .btn:not(.active):active,
.vui-panel .modal-content[level="second"] .btn-group > .btn:not(.active):active,
.vui-wizard .modal-content[level="second"] .btn-group > .btn:not(.active):active,
.modal-dialog .modal-content[level="second"] .btn-group > .btn:not(.active):active {
  outline: none;
  box-shadow: inset 0 1px 0 #89cbdf;
}
.vui-panel .vx-tab-wrapper[level="second"] .btn-group > .btn:not(.active):hover,
.vui-wizard .vx-tab-wrapper[level="second"] .btn-group > .btn:not(.active):hover,
.modal-dialog .vx-tab-wrapper[level="second"] .btn-group > .btn:not(.active):hover,
.vui-panel [vx-navi-tab-headers][level="second"] .btn-group > .btn:not(.active):hover,
.vui-wizard [vx-navi-tab-headers][level="second"] .btn-group > .btn:not(.active):hover,
.modal-dialog [vx-navi-tab-headers][level="second"] .btn-group > .btn:not(.active):hover,
.vui-panel .wizard-modal-content[level="second"] .btn-group > .btn:not(.active):hover,
.vui-wizard .wizard-modal-content[level="second"] .btn-group > .btn:not(.active):hover,
.modal-dialog .wizard-modal-content[level="second"] .btn-group > .btn:not(.active):hover,
.vui-panel .modal-content[level="second"] .btn-group > .btn:not(.active):hover,
.vui-wizard .modal-content[level="second"] .btn-group > .btn:not(.active):hover,
.modal-dialog .modal-content[level="second"] .btn-group > .btn:not(.active):hover {
  color: #004a70;
  background-color: #e1f1f6;
}
.vui-panel .vx-tab-wrapper[level="details"],
.vui-wizard .vx-tab-wrapper[level="details"],
.modal-dialog .vx-tab-wrapper[level="details"],
.vui-panel [vx-navi-tab-headers][level="details"],
.vui-wizard [vx-navi-tab-headers][level="details"],
.modal-dialog [vx-navi-tab-headers][level="details"],
.vui-panel .wizard-modal-content[level="details"],
.vui-wizard .wizard-modal-content[level="details"],
.modal-dialog .wizard-modal-content[level="details"],
.vui-panel .modal-content[level="details"],
.vui-wizard .modal-content[level="details"],
.modal-dialog .modal-content[level="details"] {
  padding: 0 0 6px 0;
}
.vui-panel .vx-tab-wrapper,
.vui-wizard .vx-tab-wrapper,
.modal-dialog .vx-tab-wrapper {
  box-sizing: border-box;
}
.vui-secondary-tabs .btn-group.pills .btn {
  float: none;
}
.vui-panel [vx-navi-tab-headers][level=second] {
  display: block;
}
.vui-panel [vx-navi-tab-headers][level=second] .nav-secondary {
  display: inline-flex;
  overflow: visible;
}
.vx-error-tooltip.tooltip {
  z-index: 10000;
  border-radius: 5px;
  visibility: visible;
  position: fixed;
}
.vx-error-tooltip.tooltip .tooltip-inner {
  background-color: #d02621;
  color: white;
}
.vx-error-tooltip.tooltip.right .tooltip-arrow {
  border-right-color: #d02621;
}
.object-navigator .vui-primary-tabs {
  min-height: 0;
}
.object-navigator .k-splitbar,
.object-navigator .k-splitter {
  box-sizing: border-box;
}
.non-scrollable {
  overflow: hidden;
}
.related-items-pane-splitter {
  height: 100%;
}
.related-items-pane-splitter [kendo-splitter] {
  height: 100%;
  border: 0;
  background: transparent;
}
.nav-top-pane-relations-mode {
  height: 100%;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}
.nav-bottom-pane-relations-mode {
  overflow: auto;
  height: calc(100% - 26px);
}
#related-items-virtual-list td {
  white-space: nowrap;
}
.user-tabbing LEFT-PANEL .vui-panel OBJECT-NAVIGATOR {
  /* Object list top header with counter */
}
.user-tabbing LEFT-PANEL .vui-panel OBJECT-NAVIGATOR .vui-tabs-container .vui-primary-tabs {
  /* Tabs of inventory trees */
}
.user-tabbing LEFT-PANEL .vui-panel OBJECT-NAVIGATOR .vui-tabs-container .vui-primary-tabs .nav > li:focus > a {
  outline: 1px dotted #212121;
  outline: 5px auto -webkit-focus-ring-color;
}
.user-tabbing LEFT-PANEL .vui-panel OBJECT-NAVIGATOR .obj-nav-counter:focus {
  outline: 1px dotted #212121;
  outline-offset: -1px;
  outline: 5px auto -webkit-focus-ring-color;
}
.user-tabbing LEFT-PANEL .vui-panel OBJECT-NAVIGATOR .object-navigator-list {
  /* Object navigator list items */
  /* Object navigator expand/collapse icon */
}
.user-tabbing LEFT-PANEL .vui-panel OBJECT-NAVIGATOR .object-navigator-list a.object:focus {
  outline: 1px dotted #212121;
  outline: 5px auto -webkit-focus-ring-color;
}
.user-tabbing LEFT-PANEL .vui-panel OBJECT-NAVIGATOR .object-navigator-list [vx-collapse-icon] i:focus {
  outline: 1px dotted #212121;
  outline: 5px auto -webkit-focus-ring-color;
}
LEFT-PANEL .vui-panel OBJECT-NAVIGATOR .panel-inner .titlebar {
  display: none;
}
LEFT-PANEL .vui-panel OBJECT-NAVIGATOR .panel-inner .panel-content {
  top: 0;
  margin: 0;
}
LEFT-PANEL .vui-panel OBJECT-NAVIGATOR .panel-inner .object-navigator-history-prev-button {
  margin-right: 2px;
}
LEFT-PANEL .vui-panel OBJECT-NAVIGATOR .panel-inner .object-navigator-history-prev-button button.object-navigator-history-prev-button {
  border: 1px solid #D2D2D2;
  background: #eee;
}
LEFT-PANEL .vui-panel OBJECT-NAVIGATOR .panel-inner .object-navigator-history-prev-button button.btn.btn-default.dropdown-toggle.object-navigator-history-prev-button {
  border: 1px solid #C5C5C5;
  box-sizing: border-box;
  margin: 0;
  background-image: none;
  width: 160px;
}
LEFT-PANEL .vui-panel OBJECT-NAVIGATOR .panel-inner .object-navigator-history-prev-button .btn.btn-default.dropdown-toggle.object-navigator-history-prev-button,
LEFT-PANEL .vui-panel OBJECT-NAVIGATOR .panel-inner .object-navigator-history-prev-button .btn.btn-default.dropdown-toggle.object-navigator-history-prev-button:focus,
LEFT-PANEL .vui-panel OBJECT-NAVIGATOR .panel-inner .object-navigator-history-prev-button .btn.btn-default.dropdown-toggle.object-navigator-history-prev-button:hover,
LEFT-PANEL .vui-panel OBJECT-NAVIGATOR .panel-inner .object-navigator-history-prev-button .btn.btn-default.dropdown-toggle.object-navigator-history-prev-button:active,
LEFT-PANEL .vui-panel OBJECT-NAVIGATOR .panel-inner .object-navigator-history-prev-button .btn.btn-default.dropdown-toggle.object-navigator-history-next-button,
LEFT-PANEL .vui-panel OBJECT-NAVIGATOR .panel-inner .object-navigator-history-prev-button .btn.btn-default.dropdown-toggle.object-navigator-history-next-button:focus,
LEFT-PANEL .vui-panel OBJECT-NAVIGATOR .panel-inner .object-navigator-history-prev-button .btn.btn-default.dropdown-toggle.object-navigator-history-next-button:hover,
LEFT-PANEL .vui-panel OBJECT-NAVIGATOR .panel-inner .object-navigator-history-prev-button .btn.btn-default.dropdown-toggle.object-navigator-history-next-button:active {
  background-color: #eee;
}
LEFT-PANEL .vui-panel OBJECT-NAVIGATOR .panel-inner .object-navigator-history-prev-button button.btn.btn-default.dropdown-toggle.object-navigator-history-next-button {
  border: 1px solid #C5C5C5;
  min-width: auto;
  margin: 0;
  box-sizing: border-box;
}
LEFT-PANEL .vui-panel OBJECT-NAVIGATOR .panel-inner .object-navigator-history-prev-button .object-navigator .nav-tabs > li.active > a {
  background-color: #eee;
}
LEFT-PANEL .vui-panel OBJECT-NAVIGATOR .panel-inner .object-navigator-history-prev-button .object-navigator .k-treeview .k-item {
  line-height: 26px;
}
LEFT-PANEL .vui-panel OBJECT-NAVIGATOR .panel-inner .object-navigator-history-prev-button .object-navigator .object-navigator-list {
  margin: 2px 0;
}
LEFT-PANEL .vui-panel OBJECT-NAVIGATOR .panel-inner .object-navigator-history-prev-button .object-navigator .object-navigator-list > li > a.object span.vui-icon-object-nav-pan {
  right: 2px;
  top: 8px;
  margin-top: 0;
  opacity: 0.3;
}
LEFT-PANEL .vui-panel OBJECT-NAVIGATOR .panel-inner .object-navigator-history-prev-button .object-navigator .object-navigator-list > li > a.object:hover {
  background-color: #eee;
}
LEFT-PANEL .vui-panel OBJECT-NAVIGATOR .panel-inner .object-navigator-history-prev-button .object-navigator .object-navigator-list > li > a.object:hover span.vui-icon-object-nav-pan {
  background-position: -120px -1px;
  opacity: 1;
}
LEFT-PANEL .vui-panel OBJECT-NAVIGATOR .panel-inner .object-navigator-history-prev-button .object-navigator .object-navigator-list > li > a.object .vui-icon-object-nav-pan,
LEFT-PANEL .vui-panel OBJECT-NAVIGATOR .panel-inner .object-navigator-history-prev-button .object-navigator .object-navigator-list > li > a.object .vui-icon-object-nav-pan-hover {
  margin-top: 5px;
  margin-right: 2px;
}
LEFT-PANEL .vui-panel OBJECT-NAVIGATOR .panel-inner .object-navigator-history-prev-button .object-navigator .object-navigator-list > li > a.object:hover {
  background-color: #eee;
  color: inherit;
}
LEFT-PANEL .vui-panel OBJECT-NAVIGATOR .panel-inner .object-navigator-history-prev-button .object-navigator .object-navigator-list > li {
  color: #ddd;
  line-height: 26px;
}
LEFT-PANEL .vui-panel OBJECT-NAVIGATOR .panel-inner .object-navigator-history-prev-button .object-navigator-heading {
  text-transform: uppercase;
}
LEFT-PANEL .vui-panel OBJECT-NAVIGATOR .panel-inner .object-navigator-history-prev-button .object-navigator .btn:focus {
  box-shadow: none;
}
LEFT-PANEL .vui-panel OBJECT-NAVIGATOR .panel-inner .object-navigator-history-prev-button .object-navigator-counter {
  margin-top: 2px;
  background: #DDDDDD;
  text-align: center;
  color: #313131;
  border: 1px solid rgba(81, 81, 81, 0.16);
}
LEFT-PANEL .vui-panel OBJECT-NAVIGATOR .panel-inner .object-navigator .nav.nav-tabs {
  margin-top: 0;
  padding-top: 17px;
  padding-bottom: 9px;
  border-bottom: 1px solid #ddd;
}
LEFT-PANEL .vui-panel OBJECT-NAVIGATOR .panel-inner .object-navigator .nav.nav-tabs > li > a,
LEFT-PANEL .vui-panel OBJECT-NAVIGATOR .panel-inner .object-navigator .nav.nav-tabs li.active > a,
LEFT-PANEL .vui-panel OBJECT-NAVIGATOR .panel-inner .object-navigator .nav.nav-tabs > li a:hover,
LEFT-PANEL .vui-panel OBJECT-NAVIGATOR .panel-inner .object-navigator .nav.nav-tabs li.active a:hover {
  height: 18px;
  padding-bottom: 15px;
}
LEFT-PANEL .vui-panel OBJECT-NAVIGATOR .panel-inner .object-navigator div.vui-primary-tabs .vui-tab-content {
  top: 60px;
  padding-left: 14px;
  overflow: initial;
}
LEFT-PANEL .vui-panel OBJECT-NAVIGATOR .panel-inner .object-navigator div.vui-primary-tabs .vui-tab-content .scrollableInventoryTree {
  position: absolute;
  overflow: auto;
  margin: -14px;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
}
.nav-without-scroll .nav {
  overflow-y: hidden;
}
/*
Icon classes can be used entirely standalone. They are named after their original file names.

```html
<!-- `display: block` sprite -->
<div class="icon-home"></div>

<!-- `display: inline-block` sprite -->
<img class="icon-home" />
```
*/
.vx-icon-CPU {
  background-image: url(/ui/resources/img/js-ui-lib-sprite.png);
  background-position: -143px -502px;
  width: 16px;
  height: 16px;
}
.vx-icon-VServiceIcon {
  background-image: url(/ui/resources/img/js-ui-lib-sprite.png);
  background-position: -447px -470px;
  width: 16px;
  height: 16px;
}
.vx-icon-acknowledgedAlarm {
  background-image: url(/ui/resources/img/js-ui-lib-sprite.png);
  background-position: -47px -438px;
  width: 16px;
  height: 16px;
}
.vx-icon-addIcon {
  background-image: url(/ui/resources/img/js-ui-lib-sprite.png);
  background-position: -63px -470px;
  width: 16px;
  height: 16px;
}
.vx-icon-addRolesIcon {
  background-image: url(/ui/resources/img/js-ui-lib-sprite.png);
  background-position: -319px -486px;
  width: 16px;
  height: 16px;
}
.vx-icon-attach {
  background-image: url(/ui/resources/img/js-ui-lib-sprite.png);
  background-position: -207px -550px;
  width: 16px;
  height: 16px;
}
.vx-icon-battery {
  background-image: url(/ui/resources/img/js-ui-lib-sprite.png);
  background-position: -191px -550px;
  width: 16px;
  height: 16px;
}
.vx-icon-bios {
  background-image: url(/ui/resources/img/js-ui-lib-sprite.png);
  background-position: -175px -550px;
  width: 16px;
  height: 16px;
}
.vx-icon-cable {
  background-image: url(/ui/resources/img/js-ui-lib-sprite.png);
  background-position: -159px -550px;
  width: 16px;
  height: 16px;
}
.vx-icon-cancel {
  background-image: url(/ui/resources/img/js-ui-lib-sprite.png);
  background-position: -447px -534px;
  width: 16px;
  height: 16px;
}
.vx-icon-cancel_disabled {
  background-image: url(/ui/resources/img/js-ui-lib-sprite.png);
  background-position: -383px -534px;
  width: 16px;
  height: 16px;
}
.vx-icon-cancel_hover {
  background-image: url(/ui/resources/img/js-ui-lib-sprite.png);
  background-position: -367px -534px;
  width: 16px;
  height: 16px;
}
.vx-icon-category_add {
  background-image: url(/ui/resources/img/js-ui-lib-sprite.png);
  background-position: -271px -518px;
  width: 16px;
  height: 16px;
}
.vx-icon-cloneLibraryItem {
  background-image: url(/ui/resources/img/js-ui-lib-sprite.png);
  background-position: -239px -518px;
  width: 16px;
  height: 16px;
}
.vx-icon-cloneRolesIcon {
  background-image: url(/ui/resources/img/js-ui-lib-sprite.png);
  background-position: -233px -412px;
  width: 20px;
  height: 20px;
}
.vx-icon-closex {
  background-image: url(/ui/resources/img/js-ui-lib-sprite.png);
  background-position: -223px -518px;
  width: 16px;
  height: 16px;
}
.vx-icon-cluster-edit {
  background-image: url(/ui/resources/img/js-ui-lib-sprite.png);
  background-position: -111px -518px;
  width: 16px;
  height: 16px;
}
.vx-icon-clusterComputeResourceRed {
  background-image: url(/ui/resources/img/js-ui-lib-sprite.png);
  background-position: -383px -502px;
  width: 16px;
  height: 16px;
}
.vx-icon-clusterComputeResourceYellow {
  background-image: url(/ui/resources/img/js-ui-lib-sprite.png);
  background-position: -287px -502px;
  width: 16px;
  height: 16px;
}
.vx-icon-compliant {
  background-image: url(/ui/resources/img/js-ui-lib-sprite.png);
  background-position: -175px -502px;
  width: 16px;
  height: 16px;
}
.vx-icon-convert_attributes {
  background-image: url(/ui/resources/img/js-ui-lib-sprite.png);
  background-position: -159px -502px;
  width: 16px;
  height: 16px;
}
.vx-icon-createTag {
  background-image: url(/ui/resources/img/js-ui-lib-sprite.png);
  background-position: -111px -502px;
  width: 16px;
  height: 16px;
}
.vx-icon-datacenterAlert {
  background-image: url(/ui/resources/img/js-ui-lib-sprite.png);
  background-position: -431px -486px;
  width: 16px;
  height: 16px;
}
.vx-icon-datacenterWarning {
  background-image: url(/ui/resources/img/js-ui-lib-sprite.png);
  background-position: -415px -486px;
  width: 16px;
  height: 16px;
}
.vx-icon-datastore-enter-maintenance-mode {
  background-image: url(/ui/resources/img/js-ui-lib-sprite.png);
  background-position: -399px -486px;
  width: 16px;
  height: 16px;
}
.vx-icon-datastore-exit-maintenance-mode {
  background-image: url(/ui/resources/img/js-ui-lib-sprite.png);
  background-position: -191px -486px;
  width: 16px;
  height: 16px;
}
.vx-icon-datastore {
  background-image: url(/ui/resources/img/js-ui-lib-sprite.png);
  background-position: -315px -383px;
  width: 24px;
  height: 24px;
}
.vx-icon-datastoreInaccessible {
  background-image: url(/ui/resources/img/js-ui-lib-sprite.png);
  background-position: -351px -470px;
  width: 16px;
  height: 16px;
}
.vx-icon-datastoreWarning {
  background-image: url(/ui/resources/img/js-ui-lib-sprite.png);
  background-position: -207px -470px;
  width: 16px;
  height: 16px;
}
.vx-icon-defaultShortcut {
  background-image: url(/ui/resources/img/js-ui-lib-sprite.png);
  background-position: -299px -347px;
  width: 32px;
  height: 32px;
}
.vx-icon-deleteIcon {
  background-image: url(/ui/resources/img/js-ui-lib-sprite.png);
  background-position: -191px -470px;
  width: 16px;
  height: 16px;
}
.vx-icon-dell_logo {
  background-image: url(/ui/resources/img/js-ui-lib-sprite.png);
  background-position: -167px -318px;
  width: 162px;
  height: 29px;
}
.vx-icon-detach {
  background-image: url(/ui/resources/img/js-ui-lib-sprite.png);
  background-position: -175px -470px;
  width: 16px;
  height: 16px;
}
.vx-icon-detach_host_profile {
  background-image: url(/ui/resources/img/js-ui-lib-sprite.png);
  background-position: -159px -470px;
  width: 16px;
  height: 16px;
}
.vx-icon-downArrowBlack {
  background-image: url(/ui/resources/img/js-ui-lib-sprite.png);
  background-position: -453px -318px;
  width: 10px;
  height: 10px;
}
.vx-icon-dropdownArrow {
  background-image: url(/ui/resources/img/js-ui-lib-sprite.png);
  background-position: -437px -332px;
  width: 7px;
  height: 4px;
}
.vx-icon-dsAddFolder {
  background-image: url(/ui/resources/img/js-ui-lib-sprite.png);
  background-position: -253px -412px;
  width: 20px;
  height: 20px;
}
.vx-icon-dsDownload {
  background-image: url(/ui/resources/img/js-ui-lib-sprite.png);
  background-position: -273px -412px;
  width: 20px;
  height: 20px;
}
.vx-icon-dsUpload {
  background-image: url(/ui/resources/img/js-ui-lib-sprite.png);
  background-position: -293px -412px;
  width: 20px;
  height: 20px;
}
.vx-icon-dvPortGroupAlert {
  background-image: url(/ui/resources/img/js-ui-lib-sprite.png);
  background-position: -207px -438px;
  width: 16px;
  height: 16px;
}
.vx-icon-dvPortGroupWarning {
  background-image: url(/ui/resources/img/js-ui-lib-sprite.png);
  background-position: -191px -438px;
  width: 16px;
  height: 16px;
}
.vx-icon-dvsAlert {
  background-image: url(/ui/resources/img/js-ui-lib-sprite.png);
  background-position: -63px -438px;
  width: 16px;
  height: 16px;
}
.vx-icon-dvsWarning {
  background-image: url(/ui/resources/img/js-ui-lib-sprite.png);
  background-position: -389px -412px;
  width: 16px;
  height: 16px;
}
.vx-icon-edit16x16 {
  background-image: url(/ui/resources/img/js-ui-lib-sprite.png);
  background-position: -367px -486px;
  width: 16px;
  height: 16px;
}
.vx-icon-editRolesIcon {
  background-image: url(/ui/resources/img/js-ui-lib-sprite.png);
  background-position: -405px -412px;
  width: 16px;
  height: 16px;
}
.vx-icon-edit_host_customizations {
  background-image: url(/ui/resources/img/js-ui-lib-sprite.png);
  background-position: -421px -412px;
  width: 16px;
  height: 16px;
}
.vx-icon-erase-partitions {
  background-image: url(/ui/resources/img/js-ui-lib-sprite.png);
  background-position: -437px -412px;
  width: 16px;
  height: 16px;
}
.vx-icon-error {
  background-image: url(/ui/resources/img/js-ui-lib-sprite.png);
  background-position: -453px -412px;
  width: 16px;
  height: 16px;
}
.vx-icon-errorStack {
  background-image: url(/ui/resources/img/js-ui-lib-sprite.png);
  background-position: -284px -550px;
  width: 12px;
  height: 14px;
}
.vx-icon-evc-enabled {
  background-image: url(/ui/resources/img/js-ui-lib-sprite.png);
  background-position: -387px -383px;
  width: 24px;
  height: 24px;
}
.vx-icon-events {
  background-image: url(/ui/resources/img/js-ui-lib-sprite.png);
  background-position: -79px -438px;
  width: 16px;
  height: 16px;
}
.vx-icon-export {
  background-image: url(/ui/resources/img/js-ui-lib-sprite.png);
  background-position: -95px -438px;
  width: 16px;
  height: 16px;
}
.vx-icon-exportLibraryItem {
  background-image: url(/ui/resources/img/js-ui-lib-sprite.png);
  background-position: -111px -438px;
  width: 16px;
  height: 16px;
}
.vx-icon-exportOvf {
  background-image: url(/ui/resources/img/js-ui-lib-sprite.png);
  background-position: -127px -438px;
  width: 16px;
  height: 16px;
}
.vx-icon-fan {
  background-image: url(/ui/resources/img/js-ui-lib-sprite.png);
  background-position: -143px -438px;
  width: 16px;
  height: 16px;
}
.vx-icon-file {
  background-image: url(/ui/resources/img/js-ui-lib-sprite.png);
  background-position: -159px -438px;
  width: 16px;
  height: 16px;
}
.vx-icon-floppyImage {
  background-image: url(/ui/resources/img/js-ui-lib-sprite.png);
  background-position: -175px -438px;
  width: 16px;
  height: 16px;
}
.vx-icon-glyphicons-halflings-white {
  background-image: url(/ui/resources/img/js-ui-lib-sprite.png);
  background-position: 0px 0px;
  width: 469px;
  height: 159px;
}
.vx-icon-glyphicons-halflings {
  background-image: url(/ui/resources/img/js-ui-lib-sprite.png);
  background-position: 0px -159px;
  width: 469px;
  height: 159px;
}
.vx-icon-group {
  background-image: url(/ui/resources/img/js-ui-lib-sprite.png);
  background-position: -223px -438px;
  width: 16px;
  height: 16px;
}
.vx-icon-host-drs-group {
  background-image: url(/ui/resources/img/js-ui-lib-sprite.png);
  background-position: -239px -438px;
  width: 16px;
  height: 16px;
}
.vx-icon-hostAlert {
  background-image: url(/ui/resources/img/js-ui-lib-sprite.png);
  background-position: -255px -438px;
  width: 16px;
  height: 16px;
}
.vx-icon-hostConnect {
  background-image: url(/ui/resources/img/js-ui-lib-sprite.png);
  background-position: -271px -438px;
  width: 16px;
  height: 16px;
}
.vx-icon-hostDisconnect {
  background-image: url(/ui/resources/img/js-ui-lib-sprite.png);
  background-position: -287px -438px;
  width: 16px;
  height: 16px;
}
.vx-icon-hostDisconnected {
  background-image: url(/ui/resources/img/js-ui-lib-sprite.png);
  background-position: -303px -438px;
  width: 16px;
  height: 16px;
}
.vx-icon-hostEnterMaintenanceMode {
  background-image: url(/ui/resources/img/js-ui-lib-sprite.png);
  background-position: -319px -438px;
  width: 16px;
  height: 16px;
}
.vx-icon-hostEnterQuarantine {
  background-image: url(/ui/resources/img/js-ui-lib-sprite.png);
  background-position: -335px -438px;
  width: 16px;
  height: 16px;
}
.vx-icon-hostExitMaintenanceMode {
  background-image: url(/ui/resources/img/js-ui-lib-sprite.png);
  background-position: -351px -438px;
  width: 16px;
  height: 16px;
}
.vx-icon-hostExitQuarantine {
  background-image: url(/ui/resources/img/js-ui-lib-sprite.png);
  background-position: -367px -438px;
  width: 16px;
  height: 16px;
}
.vx-icon-hostMaintenanceMode {
  background-image: url(/ui/resources/img/js-ui-lib-sprite.png);
  background-position: -383px -438px;
  width: 16px;
  height: 16px;
}
.vx-icon-hostPortraitLarge {
  background-image: url(/ui/resources/img/js-ui-lib-sprite.png);
  background-position: -47px -318px;
  width: 120px;
  height: 120px;
}
.vx-icon-hostPowerOn {
  background-image: url(/ui/resources/img/js-ui-lib-sprite.png);
  background-position: -415px -438px;
  width: 16px;
  height: 16px;
}
.vx-icon-hostShutDown {
  background-image: url(/ui/resources/img/js-ui-lib-sprite.png);
  background-position: -431px -438px;
  width: 16px;
  height: 16px;
}
.vx-icon-hostStandby {
  background-image: url(/ui/resources/img/js-ui-lib-sprite.png);
  background-position: -447px -438px;
  width: 16px;
  height: 16px;
}
.vx-icon-hostWarning {
  background-image: url(/ui/resources/img/js-ui-lib-sprite.png);
  background-position: -47px -454px;
  width: 16px;
  height: 16px;
}
.vx-icon-host_profile {
  background-image: url(/ui/resources/img/js-ui-lib-sprite.png);
  background-position: -63px -454px;
  width: 16px;
  height: 16px;
}
.vx-icon-host_profile_association {
  background-image: url(/ui/resources/img/js-ui-lib-sprite.png);
  background-position: -79px -454px;
  width: 16px;
  height: 16px;
}
.vx-icon-ic_info_16x {
  background-image: url(/ui/resources/img/js-ui-lib-sprite.png);
  background-position: -95px -454px;
  width: 16px;
  height: 16px;
}
.vx-icon-importLibraryItem {
  background-image: url(/ui/resources/img/js-ui-lib-sprite.png);
  background-position: -111px -454px;
  width: 16px;
  height: 16px;
}
.vx-icon-info_normal {
  background-image: url(/ui/resources/img/js-ui-lib-sprite.png);
  background-position: -127px -454px;
  width: 16px;
  height: 16px;
}
.vx-icon-install-vmware-tools {
  background-image: url(/ui/resources/img/js-ui-lib-sprite.png);
  background-position: -143px -454px;
  width: 16px;
  height: 16px;
}
.vx-icon-inventory {
  background-image: url(/ui/resources/img/js-ui-lib-sprite.png);
  background-position: -159px -454px;
  width: 16px;
  height: 16px;
}
.vx-icon-ipPool {
  background-image: url(/ui/resources/img/js-ui-lib-sprite.png);
  background-position: -175px -454px;
  width: 16px;
  height: 16px;
}
.vx-icon-isoImage {
  background-image: url(/ui/resources/img/js-ui-lib-sprite.png);
  background-position: -191px -454px;
  width: 16px;
  height: 16px;
}
.vx-icon-issueStatusError {
  background-image: url(/ui/resources/img/js-ui-lib-sprite.png);
  background-position: -207px -454px;
  width: 16px;
  height: 16px;
}
.vx-icon-issueStatusInfo {
  background-image: url(/ui/resources/img/js-ui-lib-sprite.png);
  background-position: -223px -454px;
  width: 16px;
  height: 16px;
}
.vx-icon-issueStatusNone {
  background-image: url(/ui/resources/img/js-ui-lib-sprite.png);
  background-position: -239px -454px;
  width: 16px;
  height: 16px;
}
.vx-icon-issueStatusQuestion {
  background-image: url(/ui/resources/img/js-ui-lib-sprite.png);
  background-position: -255px -454px;
  width: 16px;
  height: 16px;
}
.vx-icon-issueStatusWarning {
  background-image: url(/ui/resources/img/js-ui-lib-sprite.png);
  background-position: -271px -454px;
  width: 16px;
  height: 16px;
}
.vx-icon-license {
  background-image: url(/ui/resources/img/js-ui-lib-sprite.png);
  background-position: -303px -454px;
  width: 16px;
  height: 16px;
}
.vx-icon-licenseAssigned {
  background-image: url(/ui/resources/img/js-ui-lib-sprite.png);
  background-position: -319px -454px;
  width: 16px;
  height: 16px;
}
.vx-icon-mark-local {
  background-image: url(/ui/resources/img/js-ui-lib-sprite.png);
  background-position: -335px -454px;
  width: 16px;
  height: 16px;
}
.vx-icon-mark-remote {
  background-image: url(/ui/resources/img/js-ui-lib-sprite.png);
  background-position: -351px -454px;
  width: 16px;
  height: 16px;
}
.vx-icon-memory {
  background-image: url(/ui/resources/img/js-ui-lib-sprite.png);
  background-position: -367px -454px;
  width: 16px;
  height: 16px;
}
.vx-icon-moveDown {
  background-image: url(/ui/resources/img/js-ui-lib-sprite.png);
  background-position: -383px -454px;
  width: 16px;
  height: 16px;
}
.vx-icon-moveUp {
  background-image: url(/ui/resources/img/js-ui-lib-sprite.png);
  background-position: -399px -454px;
  width: 16px;
  height: 16px;
}
.vx-icon-network {
  background-image: url(/ui/resources/img/js-ui-lib-sprite.png);
  background-position: -415px -454px;
  width: 16px;
  height: 16px;
}
.vx-icon-networkAlert {
  background-image: url(/ui/resources/img/js-ui-lib-sprite.png);
  background-position: -431px -454px;
  width: 16px;
  height: 16px;
}
.vx-icon-networkAndSecurity-small {
  background-image: url(/ui/resources/img/js-ui-lib-sprite.png);
  background-position: -447px -454px;
  width: 16px;
  height: 16px;
}
.vx-icon-networkWarning {
  background-image: url(/ui/resources/img/js-ui-lib-sprite.png);
  background-position: -47px -470px;
  width: 16px;
  height: 16px;
}
.vx-icon-networkingInventorySpec {
  background-image: url(/ui/resources/img/js-ui-lib-sprite.png);
  background-position: -291px -383px;
  width: 24px;
  height: 24px;
}
.vx-icon-new-cluster {
  background-image: url(/ui/resources/img/js-ui-lib-sprite.png);
  background-position: -79px -470px;
  width: 16px;
  height: 16px;
}
.vx-icon-new-ds-cluster {
  background-image: url(/ui/resources/img/js-ui-lib-sprite.png);
  background-position: -95px -470px;
  width: 16px;
  height: 16px;
}
.vx-icon-next_disabled {
  background-image: url(/ui/resources/img/js-ui-lib-sprite.png);
  background-position: -111px -470px;
  width: 16px;
  height: 16px;
}
.vx-icon-next_over {
  background-image: url(/ui/resources/img/js-ui-lib-sprite.png);
  background-position: -127px -470px;
  width: 16px;
  height: 16px;
}
.vx-icon-next_up {
  background-image: url(/ui/resources/img/js-ui-lib-sprite.png);
  background-position: -143px -470px;
  width: 16px;
  height: 16px;
}
.vx-icon-number_1 {
  background-image: url(/ui/resources/img/js-ui-lib-sprite.png);
  background-position: -391px -347px;
  width: 28px;
  height: 29px;
}
.vx-icon-number_2 {
  background-image: url(/ui/resources/img/js-ui-lib-sprite.png);
  background-position: -167px -383px;
  width: 28px;
  height: 29px;
}
.vx-icon-number_3 {
  background-image: url(/ui/resources/img/js-ui-lib-sprite.png);
  background-position: -363px -347px;
  width: 28px;
  height: 29px;
}
.vx-icon-number_4 {
  background-image: url(/ui/resources/img/js-ui-lib-sprite.png);
  background-position: -419px -347px;
  width: 28px;
  height: 29px;
}
.vx-icon-acked-alarms {
  background-image: url(/ui/resources/img/js-ui-lib-sprite.png);
  background-position: -442px -318px;
  width: 11px;
  height: 11px;
}
.vx-icon-acknowledged {
  background-image: url(/ui/resources/img/js-ui-lib-sprite.png);
  background-position: -223px -550px;
  width: 16px;
  height: 16px;
}
.vx-icon-alarm {
  background-image: url(/ui/resources/img/js-ui-lib-sprite.png);
  background-position: -373px -412px;
  width: 16px;
  height: 17px;
}
.vx-icon-alarm_definition {
  background-image: url(/ui/resources/img/js-ui-lib-sprite.png);
  background-position: -95px -502px;
  width: 16px;
  height: 16px;
}
.vx-icon-alarm_definition_disabled {
  background-image: url(/ui/resources/img/js-ui-lib-sprite.png);
  background-position: -335px -486px;
  width: 16px;
  height: 16px;
}
.vx-icon-green2yellow {
  background-image: url(/ui/resources/img/js-ui-lib-sprite.png);
  background-position: -329px -330px;
  width: 36px;
  height: 12px;
}
.vx-icon-red2yellow {
  background-image: url(/ui/resources/img/js-ui-lib-sprite.png);
  background-position: -365px -330px;
  width: 36px;
  height: 12px;
}
.vx-icon-reset_green {
  background-image: url(/ui/resources/img/js-ui-lib-sprite.png);
  background-position: -399px -438px;
  width: 16px;
  height: 16px;
}
.vx-icon-yellow2green {
  background-image: url(/ui/resources/img/js-ui-lib-sprite.png);
  background-position: -406px -318px;
  width: 36px;
  height: 12px;
}
.vx-icon-yellow2red {
  background-image: url(/ui/resources/img/js-ui-lib-sprite.png);
  background-position: -401px -330px;
  width: 36px;
  height: 12px;
}
.vx-icon-other-16x {
  background-image: url(/ui/resources/img/js-ui-lib-sprite.png);
  background-position: -223px -470px;
  width: 16px;
  height: 16px;
}
.vx-icon-other {
  background-image: url(/ui/resources/img/js-ui-lib-sprite.png);
  background-position: -239px -470px;
  width: 16px;
  height: 16px;
}
.vx-icon-ovf-unknown {
  background-image: url(/ui/resources/img/js-ui-lib-sprite.png);
  background-position: -255px -470px;
  width: 16px;
  height: 16px;
}
.vx-icon-pause {
  background-image: url(/ui/resources/img/js-ui-lib-sprite.png);
  background-position: -271px -470px;
  width: 16px;
  height: 16px;
}
.vx-icon-pause_disabled {
  background-image: url(/ui/resources/img/js-ui-lib-sprite.png);
  background-position: -287px -470px;
  width: 16px;
  height: 16px;
}
.vx-icon-pause_hover {
  background-image: url(/ui/resources/img/js-ui-lib-sprite.png);
  background-position: -303px -470px;
  width: 16px;
  height: 16px;
}
.vx-icon-pci-pt-reboot {
  background-image: url(/ui/resources/img/js-ui-lib-sprite.png);
  background-position: -319px -470px;
  width: 16px;
  height: 16px;
}
.vx-icon-pci {
  background-image: url(/ui/resources/img/js-ui-lib-sprite.png);
  background-position: -335px -470px;
  width: 16px;
  height: 16px;
}
.vx-icon-physicalInventorySpec {
  background-image: url(/ui/resources/img/js-ui-lib-sprite.png);
  background-position: -339px -383px;
  width: 24px;
  height: 24px;
}
.vx-icon-power {
  background-image: url(/ui/resources/img/js-ui-lib-sprite.png);
  background-position: -367px -470px;
  width: 16px;
  height: 16px;
}
.vx-icon-previous_disabled {
  background-image: url(/ui/resources/img/js-ui-lib-sprite.png);
  background-position: -383px -470px;
  width: 16px;
  height: 16px;
}
.vx-icon-previous_over {
  background-image: url(/ui/resources/img/js-ui-lib-sprite.png);
  background-position: -399px -470px;
  width: 16px;
  height: 16px;
}
.vx-icon-previous_up {
  background-image: url(/ui/resources/img/js-ui-lib-sprite.png);
  background-position: -415px -470px;
  width: 16px;
  height: 16px;
}
.vx-icon-published-library {
  background-image: url(/ui/resources/img/js-ui-lib-sprite.png);
  background-position: -431px -470px;
  width: 16px;
  height: 16px;
}
.vx-icon-refresh {
  background-image: url(/ui/resources/img/js-ui-lib-sprite.png);
  background-position: -313px -412px;
  width: 20px;
  height: 20px;
}
.vx-icon-refreshBright {
  background-image: url(/ui/resources/img/js-ui-lib-sprite.png);
  background-position: -47px -486px;
  width: 16px;
  height: 16px;
}
.vx-icon-refreshDark {
  background-image: url(/ui/resources/img/js-ui-lib-sprite.png);
  background-position: -63px -486px;
  width: 16px;
  height: 16px;
}
.vx-icon-removeIcon {
  background-image: url(/ui/resources/img/js-ui-lib-sprite.png);
  background-position: -79px -486px;
  width: 16px;
  height: 16px;
}
.vx-icon-removeRolesIcon {
  background-image: url(/ui/resources/img/js-ui-lib-sprite.png);
  background-position: -95px -486px;
  width: 16px;
  height: 16px;
}
.vx-icon-rename {
  background-image: url(/ui/resources/img/js-ui-lib-sprite.png);
  background-position: -111px -486px;
  width: 16px;
  height: 16px;
}
.vx-icon-restartWarning {
  background-image: url(/ui/resources/img/js-ui-lib-sprite.png);
  background-position: -127px -486px;
  width: 16px;
  height: 16px;
}
.vx-icon-resume {
  background-image: url(/ui/resources/img/js-ui-lib-sprite.png);
  background-position: -143px -486px;
  width: 16px;
  height: 16px;
}
.vx-icon-resume_disabled {
  background-image: url(/ui/resources/img/js-ui-lib-sprite.png);
  background-position: -159px -486px;
  width: 16px;
  height: 16px;
}
.vx-icon-resume_hover {
  background-image: url(/ui/resources/img/js-ui-lib-sprite.png);
  background-position: -175px -486px;
  width: 16px;
  height: 16px;
}
.vx-icon-roles32x32 {
  background-image: url(/ui/resources/img/js-ui-lib-sprite.png);
  background-position: -267px -347px;
  width: 32px;
  height: 32px;
}
.vx-icon-rp {
  background-image: url(/ui/resources/img/js-ui-lib-sprite.png);
  background-position: -207px -486px;
  width: 16px;
  height: 16px;
}
.vx-icon-rpEdit {
  background-image: url(/ui/resources/img/js-ui-lib-sprite.png);
  background-position: -223px -486px;
  width: 16px;
  height: 16px;
}
.vx-icon-rpNew {
  background-image: url(/ui/resources/img/js-ui-lib-sprite.png);
  background-position: -239px -486px;
  width: 16px;
  height: 16px;
}
.vx-icon-rpRed {
  background-image: url(/ui/resources/img/js-ui-lib-sprite.png);
  background-position: -255px -486px;
  width: 16px;
  height: 16px;
}
.vx-icon-rpYellow {
  background-image: url(/ui/resources/img/js-ui-lib-sprite.png);
  background-position: -271px -486px;
  width: 16px;
  height: 16px;
}
.vx-icon-rule-conflict {
  background-image: url(/ui/resources/img/js-ui-lib-sprite.png);
  background-position: -287px -486px;
  width: 16px;
  height: 16px;
}
.vx-icon-rule {
  background-image: url(/ui/resources/img/js-ui-lib-sprite.png);
  background-position: -303px -486px;
  width: 16px;
  height: 16px;
}
.vx-icon-saveSmall {
  background-image: url(/ui/resources/img/js-ui-lib-sprite.png);
  background-position: -47px -566px;
  width: 15px;
  height: 16px;
}
.vx-icon-search {
  background-image: url(/ui/resources/img/js-ui-lib-sprite.png);
  background-position: -239px -550px;
  width: 17px;
  height: 15px;
}
.vx-icon-sel {
  background-image: url(/ui/resources/img/js-ui-lib-sprite.png);
  background-position: -351px -486px;
  width: 16px;
  height: 16px;
}
.vx-icon-separator {
  background-image: url(/ui/resources/img/js-ui-lib-sprite.png);
  background-position: -437px -330px;
  width: 19px;
  height: 2px;
}
.vx-icon-serviceEdit {
  background-image: url(/ui/resources/img/js-ui-lib-sprite.png);
  background-position: -383px -486px;
  width: 16px;
  height: 16px;
}
.vx-icon-serviceRestart {
  background-image: url(/ui/resources/img/js-ui-lib-sprite.png);
  background-position: -213px -412px;
  width: 20px;
  height: 20px;
}
.vx-icon-serviceStart {
  background-image: url(/ui/resources/img/js-ui-lib-sprite.png);
  background-position: -353px -412px;
  width: 20px;
  height: 20px;
}
.vx-icon-serviceStop {
  background-image: url(/ui/resources/img/js-ui-lib-sprite.png);
  background-position: -333px -412px;
  width: 20px;
  height: 20px;
}
.vx-icon-smiley-happy {
  background-image: url(/ui/resources/img/js-ui-lib-sprite.png);
  background-position: -447px -486px;
  width: 16px;
  height: 16px;
}
.vx-icon-smiley-neutral {
  background-image: url(/ui/resources/img/js-ui-lib-sprite.png);
  background-position: -47px -502px;
  width: 16px;
  height: 16px;
}
.vx-icon-smiley-sad {
  background-image: url(/ui/resources/img/js-ui-lib-sprite.png);
  background-position: -63px -502px;
  width: 16px;
  height: 16px;
}
.vx-icon-software {
  background-image: url(/ui/resources/img/js-ui-lib-sprite.png);
  background-position: -79px -502px;
  width: 16px;
  height: 16px;
}
.vx-icon-statusCompliant {
  background-image: url(/ui/resources/img/js-ui-lib-sprite.png);
  background-position: -270px -550px;
  width: 14px;
  height: 14px;
}
.vx-icon-statusCompliant24x {
  background-image: url(/ui/resources/img/js-ui-lib-sprite.png);
  background-position: -267px -383px;
  width: 24px;
  height: 24px;
}
.vx-icon-statusNotApplicable {
  background-image: url(/ui/resources/img/js-ui-lib-sprite.png);
  background-position: -127px -502px;
  width: 16px;
  height: 16px;
}
.vx-icon-statusNotCompliant {
  background-image: url(/ui/resources/img/js-ui-lib-sprite.png);
  background-position: -256px -550px;
  width: 14px;
  height: 14px;
}
.vx-icon-statusNotCompliant24x {
  background-image: url(/ui/resources/img/js-ui-lib-sprite.png);
  background-position: -243px -383px;
  width: 24px;
  height: 24px;
}
.vx-icon-statusUnknown24x {
  background-image: url(/ui/resources/img/js-ui-lib-sprite.png);
  background-position: -195px -383px;
  width: 24px;
  height: 24px;
}
.vx-icon-storage {
  background-image: url(/ui/resources/img/js-ui-lib-sprite.png);
  background-position: -191px -502px;
  width: 16px;
  height: 16px;
}
.vx-icon-storagePod {
  background-image: url(/ui/resources/img/js-ui-lib-sprite.png);
  background-position: -207px -502px;
  width: 16px;
  height: 16px;
}
.vx-icon-storagePodAlert {
  background-image: url(/ui/resources/img/js-ui-lib-sprite.png);
  background-position: -223px -502px;
  width: 16px;
  height: 16px;
}
.vx-icon-storagePodWarning {
  background-image: url(/ui/resources/img/js-ui-lib-sprite.png);
  background-position: -239px -502px;
  width: 16px;
  height: 16px;
}
.vx-icon-storage_profile {
  background-image: url(/ui/resources/img/js-ui-lib-sprite.png);
  background-position: -255px -502px;
  width: 16px;
  height: 16px;
}
.vx-icon-subscribed-library {
  background-image: url(/ui/resources/img/js-ui-lib-sprite.png);
  background-position: -271px -502px;
  width: 16px;
  height: 16px;
}
.vx-icon-summary-issues {
  background-image: url(/ui/resources/img/js-ui-lib-sprite.png);
  background-position: 0px -318px;
  width: 47px;
  height: 409px;
}
.vx-icon-sync {
  background-image: url(/ui/resources/img/js-ui-lib-sprite.png);
  background-position: -303px -502px;
  width: 16px;
  height: 16px;
}
.vx-icon-system_board {
  background-image: url(/ui/resources/img/js-ui-lib-sprite.png);
  background-position: -319px -502px;
  width: 16px;
  height: 16px;
}
.vx-icon-tag {
  background-image: url(/ui/resources/img/js-ui-lib-sprite.png);
  background-position: -335px -502px;
  width: 16px;
  height: 16px;
}
.vx-icon-tag_assign {
  background-image: url(/ui/resources/img/js-ui-lib-sprite.png);
  background-position: -351px -502px;
  width: 16px;
  height: 16px;
}
.vx-icon-tag_remove {
  background-image: url(/ui/resources/img/js-ui-lib-sprite.png);
  background-position: -367px -502px;
  width: 16px;
  height: 16px;
}
.vx-icon-task_shortcut {
  background-image: url(/ui/resources/img/js-ui-lib-sprite.png);
  background-position: -235px -347px;
  width: 32px;
  height: 32px;
}
.vx-icon-temp {
  background-image: url(/ui/resources/img/js-ui-lib-sprite.png);
  background-position: -399px -502px;
  width: 16px;
  height: 16px;
}
.vx-icon-updateLibraryItem {
  background-image: url(/ui/resources/img/js-ui-lib-sprite.png);
  background-position: -415px -502px;
  width: 16px;
  height: 16px;
}
.vx-icon-uplinkPortGroup {
  background-image: url(/ui/resources/img/js-ui-lib-sprite.png);
  background-position: -431px -502px;
  width: 16px;
  height: 16px;
}
.vx-icon-uplinkPortGroupAlert {
  background-image: url(/ui/resources/img/js-ui-lib-sprite.png);
  background-position: -447px -502px;
  width: 16px;
  height: 16px;
}
.vx-icon-uplinkPortGroupWarning {
  background-image: url(/ui/resources/img/js-ui-lib-sprite.png);
  background-position: -47px -518px;
  width: 16px;
  height: 16px;
}
.vx-icon-user {
  background-image: url(/ui/resources/img/js-ui-lib-sprite.png);
  background-position: -63px -518px;
  width: 16px;
  height: 16px;
}
.vx-icon-vApp-template {
  background-image: url(/ui/resources/img/js-ui-lib-sprite.png);
  background-position: -79px -518px;
  width: 16px;
  height: 16px;
}
.vx-icon-vAppManaged {
  background-image: url(/ui/resources/img/js-ui-lib-sprite.png);
  background-position: -95px -518px;
  width: 16px;
  height: 16px;
}
.vx-icon-vAppManagedBy24 {
  background-image: url(/ui/resources/img/js-ui-lib-sprite.png);
  background-position: -363px -383px;
  width: 24px;
  height: 24px;
}
.vx-icon-vAppRunning {
  background-image: url(/ui/resources/img/js-ui-lib-sprite.png);
  background-position: -127px -518px;
  width: 16px;
  height: 16px;
}
.vx-icon-vAppRunningManaged {
  background-image: url(/ui/resources/img/js-ui-lib-sprite.png);
  background-position: -143px -518px;
  width: 16px;
  height: 16px;
}
.vx-icon-vAppSuspended {
  background-image: url(/ui/resources/img/js-ui-lib-sprite.png);
  background-position: -159px -518px;
  width: 16px;
  height: 16px;
}
.vx-icon-vAppSuspendedManaged {
  background-image: url(/ui/resources/img/js-ui-lib-sprite.png);
  background-position: -175px -518px;
  width: 16px;
  height: 16px;
}
.vx-icon-vCenterExtension {
  background-image: url(/ui/resources/img/js-ui-lib-sprite.png);
  background-position: -191px -518px;
  width: 16px;
  height: 16px;
}
.vx-icon-vcenter {
  background-image: url(/ui/resources/img/js-ui-lib-sprite.png);
  background-position: -207px -518px;
  width: 16px;
  height: 16px;
}
.vx-icon-vi_shortcut {
  background-image: url(/ui/resources/img/js-ui-lib-sprite.png);
  background-position: -331px -347px;
  width: 32px;
  height: 32px;
}
.vx-icon-virtualInventorySpec {
  background-image: url(/ui/resources/img/js-ui-lib-sprite.png);
  background-position: -219px -383px;
  width: 24px;
  height: 24px;
}
.vx-icon-virtualMachineFolder {
  background-image: url(/ui/resources/img/js-ui-lib-sprite.png);
  background-position: -255px -518px;
  width: 16px;
  height: 16px;
}
.vx-icon-virtualMachineManagedBy24 {
  background-image: url(/ui/resources/img/js-ui-lib-sprite.png);
  background-position: -411px -383px;
  width: 24px;
  height: 24px;
}
.vx-icon-vm-drs-group {
  background-image: url(/ui/resources/img/js-ui-lib-sprite.png);
  background-position: -287px -518px;
  width: 16px;
  height: 16px;
}
.vx-icon-vm-template {
  background-image: url(/ui/resources/img/js-ui-lib-sprite.png);
  background-position: -303px -518px;
  width: 16px;
  height: 16px;
}
.vx-icon-vmDisk {
  background-image: url(/ui/resources/img/js-ui-lib-sprite.png);
  background-position: -319px -518px;
  width: 16px;
  height: 16px;
}
.vx-icon-vmEdit {
  background-image: url(/ui/resources/img/js-ui-lib-sprite.png);
  background-position: -335px -518px;
  width: 16px;
  height: 16px;
}
.vx-icon-vmLog {
  background-image: url(/ui/resources/img/js-ui-lib-sprite.png);
  background-position: -351px -518px;
  width: 16px;
  height: 16px;
}
.vx-icon-vmManaged {
  background-image: url(/ui/resources/img/js-ui-lib-sprite.png);
  background-position: -367px -518px;
  width: 16px;
  height: 16px;
}
.vx-icon-vmNvram {
  background-image: url(/ui/resources/img/js-ui-lib-sprite.png);
  background-position: -383px -518px;
  width: 16px;
  height: 16px;
}
.vx-icon-vmOn {
  background-image: url(/ui/resources/img/js-ui-lib-sprite.png);
  background-position: -399px -518px;
  width: 16px;
  height: 16px;
}
.vx-icon-vmOnManaged {
  background-image: url(/ui/resources/img/js-ui-lib-sprite.png);
  background-position: -415px -518px;
  width: 16px;
  height: 16px;
}
.vx-icon-vmOnPrimaryFT {
  background-image: url(/ui/resources/img/js-ui-lib-sprite.png);
  background-position: -431px -518px;
  width: 16px;
  height: 16px;
}
.vx-icon-vmOnSecondaryFT {
  background-image: url(/ui/resources/img/js-ui-lib-sprite.png);
  background-position: -447px -518px;
  width: 16px;
  height: 16px;
}
.vx-icon-vmPrimaryFT {
  background-image: url(/ui/resources/img/js-ui-lib-sprite.png);
  background-position: -47px -534px;
  width: 16px;
  height: 16px;
}
.vx-icon-vmRed {
  background-image: url(/ui/resources/img/js-ui-lib-sprite.png);
  background-position: -63px -534px;
  width: 16px;
  height: 16px;
}
.vx-icon-vmRedManaged {
  background-image: url(/ui/resources/img/js-ui-lib-sprite.png);
  background-position: -79px -534px;
  width: 16px;
  height: 16px;
}
.vx-icon-vmRedPrimaryFT {
  background-image: url(/ui/resources/img/js-ui-lib-sprite.png);
  background-position: -95px -534px;
  width: 16px;
  height: 16px;
}
.vx-icon-vmRedSecondaryFT {
  background-image: url(/ui/resources/img/js-ui-lib-sprite.png);
  background-position: -111px -534px;
  width: 16px;
  height: 16px;
}
.vx-icon-vmRestartSecondaryFT {
  background-image: url(/ui/resources/img/js-ui-lib-sprite.png);
  background-position: -127px -534px;
  width: 16px;
  height: 16px;
}
.vx-icon-vmSecondaryFT {
  background-image: url(/ui/resources/img/js-ui-lib-sprite.png);
  background-position: -143px -534px;
  width: 16px;
  height: 16px;
}
.vx-icon-vmSnapshot {
  background-image: url(/ui/resources/img/js-ui-lib-sprite.png);
  background-position: -159px -534px;
  width: 16px;
  height: 16px;
}
.vx-icon-vmStuck {
  background-image: url(/ui/resources/img/js-ui-lib-sprite.png);
  background-position: -175px -534px;
  width: 16px;
  height: 16px;
}
.vx-icon-vmStuckManaged {
  background-image: url(/ui/resources/img/js-ui-lib-sprite.png);
  background-position: -191px -534px;
  width: 16px;
  height: 16px;
}
.vx-icon-vmStuckPrimaryFT {
  background-image: url(/ui/resources/img/js-ui-lib-sprite.png);
  background-position: -207px -534px;
  width: 16px;
  height: 16px;
}
.vx-icon-vmSuspended {
  background-image: url(/ui/resources/img/js-ui-lib-sprite.png);
  background-position: -223px -534px;
  width: 16px;
  height: 16px;
}
.vx-icon-vmSuspendedManaged {
  background-image: url(/ui/resources/img/js-ui-lib-sprite.png);
  background-position: -239px -534px;
  width: 16px;
  height: 16px;
}
.vx-icon-vmSuspendedPrimaryFT {
  background-image: url(/ui/resources/img/js-ui-lib-sprite.png);
  background-position: -255px -534px;
  width: 16px;
  height: 16px;
}
.vx-icon-vmSuspendedSecondaryFT {
  background-image: url(/ui/resources/img/js-ui-lib-sprite.png);
  background-position: -271px -534px;
  width: 16px;
  height: 16px;
}
.vx-icon-vmTemplate {
  background-image: url(/ui/resources/img/js-ui-lib-sprite.png);
  background-position: -287px -534px;
  width: 16px;
  height: 16px;
}
.vx-icon-vmYellow {
  background-image: url(/ui/resources/img/js-ui-lib-sprite.png);
  background-position: -303px -534px;
  width: 16px;
  height: 16px;
}
.vx-icon-vmYellowManaged {
  background-image: url(/ui/resources/img/js-ui-lib-sprite.png);
  background-position: -319px -534px;
  width: 16px;
  height: 16px;
}
.vx-icon-vmYellowPrimaryFT {
  background-image: url(/ui/resources/img/js-ui-lib-sprite.png);
  background-position: -335px -534px;
  width: 16px;
  height: 16px;
}
.vx-icon-vmYellowSecondaryFT {
  background-image: url(/ui/resources/img/js-ui-lib-sprite.png);
  background-position: -351px -534px;
  width: 16px;
  height: 16px;
}
.vx-icon-vm_logo {
  background-image: url(/ui/resources/img/js-ui-lib-sprite.png);
  background-position: -167px -347px;
  width: 36px;
  height: 36px;
}
.vx-icon-vmwareLogoAppHeader {
  background-image: url(/ui/resources/img/js-ui-lib-sprite.png);
  background-position: -329px -318px;
  width: 77px;
  height: 12px;
}
.vx-icon-voltage {
  background-image: url(/ui/resources/img/js-ui-lib-sprite.png);
  background-position: -399px -534px;
  width: 16px;
  height: 16px;
}
.vx-icon-vsanWitnessHost {
  background-image: url(/ui/resources/img/js-ui-lib-sprite.png);
  background-position: -415px -534px;
  width: 16px;
  height: 16px;
}
.vx-icon-warn {
  background-image: url(/ui/resources/img/js-ui-lib-sprite.png);
  background-position: -431px -534px;
  width: 16px;
  height: 16px;
}
.vx-icon-warning-32x32 {
  background-image: url(/ui/resources/img/js-ui-lib-sprite.png);
  background-position: -203px -347px;
  width: 32px;
  height: 32px;
}
.vx-icon-watchdog {
  background-image: url(/ui/resources/img/js-ui-lib-sprite.png);
  background-position: -47px -550px;
  width: 16px;
  height: 16px;
}
.vx-icon-whiteHome {
  background-image: url(/ui/resources/img/js-ui-lib-sprite.png);
  background-position: -63px -550px;
  width: 16px;
  height: 16px;
}
.vx-icon-window-popout {
  background-image: url(/ui/resources/img/js-ui-lib-sprite.png);
  background-position: -79px -550px;
  width: 16px;
  height: 16px;
}
.vx-icon-witnessHostDisconnected {
  background-image: url(/ui/resources/img/js-ui-lib-sprite.png);
  background-position: -95px -550px;
  width: 16px;
  height: 16px;
}
.vx-icon-witnessHostMaintenance {
  background-image: url(/ui/resources/img/js-ui-lib-sprite.png);
  background-position: -111px -550px;
  width: 16px;
  height: 16px;
}
.vx-icon-witnessHostRed {
  background-image: url(/ui/resources/img/js-ui-lib-sprite.png);
  background-position: -127px -550px;
  width: 16px;
  height: 16px;
}
.vx-icon-witnessHostWarning {
  background-image: url(/ui/resources/img/js-ui-lib-sprite.png);
  background-position: -143px -550px;
  width: 16px;
  height: 16px;
}
.vx-icon-wizardStep1 {
  background-image: url(/ui/resources/img/js-ui-lib-sprite.png);
  background-position: -435px -383px;
  width: 24px;
  height: 23px;
}
.vx-icon-wizardStep2 {
  background-image: url(/ui/resources/img/js-ui-lib-sprite.png);
  background-position: -189px -412px;
  width: 24px;
  height: 20px;
}
.vx-icon-wizardStep3 {
  background-image: url(/ui/resources/img/js-ui-lib-sprite.png);
  background-position: -447px -347px;
  width: 22px;
  height: 25px;
}
.vx-icon-wizardStep4 {
  background-image: url(/ui/resources/img/js-ui-lib-sprite.png);
  background-position: -167px -412px;
  width: 22px;
  height: 23px;
}
.user-tabbing .vx-portlet {
  /* Portlet title */
  /* Portlet footer links/actions  */
}
.user-tabbing .vx-portlet .portlet-titlebar:focus {
  outline: 1px dotted #212121;
  outline-offset: -1px;
  outline: 5px auto -webkit-focus-ring-color;
}
.user-tabbing .vx-portlet .vx-portlet-footer a:focus {
  outline: 1px dotted #212121;
  outline: 5px auto -webkit-focus-ring-color;
}
.vx-portlets {
  display: flex;
  flex-direction: row;
  flex: 1 1 auto;
  height: 100%;
  margin-top: 10px;
}
.vx-portlets:not(.ui-sorting) .col .vx-portlet .portlet-titlebar .arrow {
  -webkit-transition: all 300ms ease-in 0s;
  -moz-transition: all 300ms ease-in 0s;
  -o-transition: all 300ms ease-in 0s;
  transition: all 300ms ease-in 0s;
}
.vx-portlets .col {
  flex: 1 1 100%;
  display: flex;
  flex-direction: column;
}
.vx-portlets .col:not(:first-child) {
  margin-left: 12px;
}
.vx-portlets .col .vx-portlet {
  box-shadow: 1px 1px 4px 0px rgba(0, 0, 0, 0.1);
  min-height: 30px;
  border: 1px solid lightGrey;
  border-radius: 3px;
  overflow: auto;
  background: white;
  margin: 0 0 12px 0;
  flex-shrink: 0;
  box-sizing: border-box;
}
.vx-portlets .col .vx-portlet.placeholder {
  opacity: 0.5;
  background: #e9f6fe;
}
.vx-portlets .col .vx-portlet .portlet-titlebar {
  cursor: pointer;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  margin: 0;
  padding: 12px 10px;
  box-sizing: border-box;
  position: relative;
  overflow: hidden;
}
.vx-portlets .col .vx-portlet .portlet-titlebar:hover .arrow svg polyline,
.vx-portlets .col .vx-portlet .portlet-titlebar:hover .arrow svg path {
  fill: #004a70;
}
.vx-portlets .col .vx-portlet .portlet-titlebar .titlebar-text {
  flex: 1 1 100%;
  display: inline-flex !important;
  white-space: nowrap;
  overflow: hidden !important;
  padding: 0 !important;
  box-sizing: border-box;
  max-width: initial !important;
}
.vx-portlets .col .vx-portlet .portlet-titlebar .arrow {
  display: inline-flex;
}
.vx-portlets .col .vx-portlet .portlet-titlebar .arrow svg path {
  fill: #0079b8;
}
.vx-portlets .col .vx-portlet .vx-portlet-content {
  padding: 0 10px 5px 10px;
}
.vx-portlets .col .vx-portlet .vx-portlet-footer {
  padding: 0 10px 5px 10px;
  display: inline-flex;
}
.vx-portlets .col .vx-portlet .vx-portlet-footer .vx-portlet-footer-link {
  margin-right: 10px;
}
.vx-portlets .col .vx-portlet.collapsed > .portlet-titlebar .arrow {
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}
.vx-portlets .col .vx-portlet.collapsed .vx-portlet-content,
.vx-portlets .col .vx-portlet.collapsed .vx-portlet-footer {
  display: none;
}
.vx-portlets .col .vx-portlet.vx-property-view {
  width: auto;
  height: auto;
  flex-shrink: 0;
}
@media (max-width: 1000px) {
  .vx-portlets,
  .vx-portlets .col {
    display: block;
    margin: 0 !important;
  }
}
.portlets-activity-indicator {
  height: 200px;
  position: relative;
}
.vsphere-icon-Add_Host {
  background: url("/ui/resources/img/svg-sprite.svg") 87.88714734% 16.39344262% no-repeat;
  width: 18px;
  height: 18px;
}
.vsphere-icon-Host_Connect {
  background: url("/ui/resources/img/svg-sprite.svg") 67.71159875% 82% no-repeat;
  width: 18px;
  height: 18px;
}
.vsphere-icon-Host_Disconnect {
  background: url("/ui/resources/img/svg-sprite.svg") 78.9968652% 64.29508197% no-repeat;
  width: 18px;
  height: 18px;
}
.vsphere-icon-Host_Maintenance_Mode_Enter {
  background: url("/ui/resources/img/svg-sprite.svg") 33.85579937% 70.19672131% no-repeat;
  width: 18px;
  height: 18px;
}
.vsphere-icon-Host_Maintenance_Mode_Exit {
  background: url("/ui/resources/img/svg-sprite.svg") 33.85579937% 76.09836066% no-repeat;
  width: 18px;
  height: 18px;
}
.vsphere-icon-Host_Policy {
  background: url("/ui/resources/img/svg-sprite.svg") 78.9968652% 76.09836066% no-repeat;
  width: 18px;
  height: 18px;
}
.vsphere-icon-Host_Reboot {
  background: url("/ui/resources/img/svg-sprite.svg") 48.90282132% 23.27868852% no-repeat;
  width: 18px;
  height: 18px;
}
.vsphere-icon-LAG {
  background: url("/ui/resources/img/svg-sprite.svg") 11.28526646% 64.29508197% no-repeat;
  width: 18px;
  height: 18px;
}
.vsphere-icon-Lic_key_add {
  background: url("/ui/resources/img/svg-sprite.svg") 85.51839465% 52.63157895% no-repeat;
  width: 38px;
  height: 38px;
}
.vsphere-icon-Lic_key_existing {
  background: url("/ui/resources/img/svg-sprite.svg") 72.80936455% 52.63157895% no-repeat;
  width: 38px;
  height: 38px;
}
.vsphere-icon-Lic_key_inactive {
  background: url("/ui/resources/img/svg-sprite.svg") 30.76923077% 24.9122807% no-repeat;
  width: 38px;
  height: 38px;
}
.vsphere-icon-Lic_key_remove {
  background: url("/ui/resources/img/svg-sprite.svg") 76.82274247% 35.0877193% no-repeat;
  width: 38px;
  height: 38px;
}
.vsphere-icon-Lic_key_upgraded {
  background: url("/ui/resources/img/svg-sprite.svg") 60.10033445% 52.63157895% no-repeat;
  width: 38px;
  height: 38px;
}
.vsphere-icon-NIC_blue {
  background: url("/ui/resources/img/svg-sprite.svg") 84.63949843% 76.09836066% no-repeat;
  width: 18px;
  height: 18px;
}
.vsphere-icon-NIC_single {
  background: url("/ui/resources/img/svg-sprite.svg") 5.64263323% 82% no-repeat;
  width: 18px;
  height: 18px;
}
.vsphere-icon-NICs {
  background: url("/ui/resources/img/svg-sprite.svg") 16.92789969% 82% no-repeat;
  width: 18px;
  height: 18px;
}
.vsphere-icon-NiC_green {
  background: url("/ui/resources/img/svg-sprite.svg") 16.92789969% 87.90163934% no-repeat;
  width: 18px;
  height: 18px;
}
.vsphere-icon-action-remove {
  background: url("/ui/resources/img/svg-sprite.svg") 5.64263323% 93.80327869% no-repeat;
  width: 18px;
  height: 18px;
}
.vsphere-icon-add {
  background: url("/ui/resources/img/svg-sprite.svg") 87.88714734% 22.29508197% no-repeat;
  width: 18px;
  height: 18px;
}
.vsphere-icon-app-defense {
  background: url("/ui/resources/img/svg-sprite.svg") 0 35.87670541% no-repeat;
  width: 92px;
  height: 125.1px;
}
.vsphere-icon-assign_license {
  background: url("/ui/resources/img/svg-sprite.svg") 45.14106583% 44.26229508% no-repeat;
  width: 18px;
  height: 18px;
}
.vsphere-icon-caret_collapsed {
  background: url("/ui/resources/img/svg-sprite.svg") 28.84012539% 52.78688525% no-repeat;
  width: 18px;
  height: 18px;
}
.vsphere-icon-caret_expanded {
  background: url("/ui/resources/img/svg-sprite.svg") 34.48275862% 52.78688525% no-repeat;
  width: 18px;
  height: 18px;
}
.vsphere-icon-cloud-network {
  background: url("/ui/resources/img/svg-sprite.svg") 40.12539185% 52.78688525% no-repeat;
  width: 18px;
  height: 18px;
}
.vsphere-icon-cloud-network-alert {
  background: url("/ui/resources/img/svg-sprite.svg") 45.76802508% 52.78688525% no-repeat;
  width: 18px;
  height: 18px;
}
.vsphere-icon-cloud-network-warning {
  background: url("/ui/resources/img/svg-sprite.svg") 0 64.29508197% no-repeat;
  width: 18px;
  height: 18px;
}
.vsphere-icon-cluster {
  background: url("/ui/resources/img/svg-sprite.svg") 5.64263323% 64.29508197% no-repeat;
  width: 18px;
  height: 18px;
}
.vsphere-icon-cluster-24x {
  background: url("/ui/resources/img/svg-sprite.svg") 37.94212219% 36.7003367% no-repeat;
  width: 26px;
  height: 26px;
}
.vsphere-icon-cluster-error {
  background: url("/ui/resources/img/svg-sprite.svg") 16.92789969% 64.29508197% no-repeat;
  width: 18px;
  height: 18px;
}
.vsphere-icon-cluster-warning {
  background: url("/ui/resources/img/svg-sprite.svg") 22.57053292% 64.29508197% no-repeat;
  width: 18px;
  height: 18px;
}
.vsphere-icon-collapse {
  background: url("/ui/resources/img/svg-sprite.svg") 81.61585366% 40.12738854% no-repeat;
  width: 9px;
  height: 9px;
}
.vsphere-icon-content-library {
  background: url("/ui/resources/img/svg-sprite.svg") 33.85579937% 64.29508197% no-repeat;
  width: 18px;
  height: 18px;
}
.vsphere-icon-content-library-new-subscription {
  background: url("/ui/resources/img/svg-sprite.svg") 39.4984326% 64.29508197% no-repeat;
  width: 18px;
  height: 18px;
}
.vsphere-icon-content-library-publish {
  background: url("/ui/resources/img/svg-sprite.svg") 45.14106583% 64.29508197% no-repeat;
  width: 18px;
  height: 18px;
}
.vsphere-icon-cpu-summary {
  background: url("/ui/resources/img/svg-sprite.svg") 41.80064309% 23.90572391% no-repeat;
  width: 26px;
  height: 26px;
}
.vsphere-icon-datacenter {
  background: url("/ui/resources/img/svg-sprite.svg") 56.42633229% 64.29508197% no-repeat;
  width: 18px;
  height: 18px;
}
.vsphere-icon-datacenter-error {
  background: url("/ui/resources/img/svg-sprite.svg") 62.06896552% 64.29508197% no-repeat;
  width: 18px;
  height: 18px;
}
.vsphere-icon-datacenter-warning {
  background: url("/ui/resources/img/svg-sprite.svg") 67.71159875% 64.29508197% no-repeat;
  width: 18px;
  height: 18px;
}
.vsphere-icon-datastore {
  background: url("/ui/resources/img/svg-sprite.svg") 73.35423197% 64.29508197% no-repeat;
  width: 18px;
  height: 18px;
}
.vsphere-icon-datastore-24x {
  background: url("/ui/resources/img/svg-sprite.svg") 86.07717042% 33.67003367% no-repeat;
  width: 26px;
  height: 26px;
}
.vsphere-icon-datastore-cluster {
  background: url("/ui/resources/img/svg-sprite.svg") 84.63949843% 64.29508197% no-repeat;
  width: 18px;
  height: 18px;
}
.vsphere-icon-datastore-cluster-error {
  background: url("/ui/resources/img/svg-sprite.svg") 0 70.19672131% no-repeat;
  width: 18px;
  height: 18px;
}
.vsphere-icon-datastore-cluster-warning {
  background: url("/ui/resources/img/svg-sprite.svg") 5.64263323% 70.19672131% no-repeat;
  width: 18px;
  height: 18px;
}
.vsphere-icon-datastore-error {
  background: url("/ui/resources/img/svg-sprite.svg") 11.28526646% 70.19672131% no-repeat;
  width: 18px;
  height: 18px;
}
.vsphere-icon-datastore-inaccessible {
  background: url("/ui/resources/img/svg-sprite.svg") 16.92789969% 70.19672131% no-repeat;
  width: 18px;
  height: 18px;
}
.vsphere-icon-datastore-maintenance {
  background: url("/ui/resources/img/svg-sprite.svg") 22.57053292% 70.19672131% no-repeat;
  width: 18px;
  height: 18px;
}
.vsphere-icon-datastore-warning {
  background: url("/ui/resources/img/svg-sprite.svg") 28.21316614% 70.19672131% no-repeat;
  width: 18px;
  height: 18px;
}
.vsphere-icon-dell {
  background: url("/ui/resources/img/svg-sprite.svg") 62.61324042% 36.63003663% no-repeat;
  width: 50px;
  height: 50px;
}
.vsphere-icon-devcenter {
  background: url("/ui/resources/img/svg-sprite.svg") 39.4984326% 70.19672131% no-repeat;
  width: 18px;
  height: 18px;
}
.vsphere-icon-drag-and-drop-accept {
  background: url("/ui/resources/img/svg-sprite.svg") 45.14106583% 70.19672131% no-repeat;
  width: 18px;
  height: 18px;
}
.vsphere-icon-drag-and-drop-reject {
  background: url("/ui/resources/img/svg-sprite.svg") 50.78369906% 70.19672131% no-repeat;
  width: 18px;
  height: 18px;
}
.vsphere-icon-dv-switch {
  background: url("/ui/resources/img/svg-sprite.svg") 56.42633229% 70.19672131% no-repeat;
  width: 18px;
  height: 18px;
}
.vsphere-icon-dv-switch-alert {
  background: url("/ui/resources/img/svg-sprite.svg") 62.06896552% 70.19672131% no-repeat;
  width: 18px;
  height: 18px;
}
.vsphere-icon-dv-switch-warning {
  background: url("/ui/resources/img/svg-sprite.svg") 67.71159875% 70.19672131% no-repeat;
  width: 18px;
  height: 18px;
}
.vsphere-icon-dvsPnicError {
  background: url("/ui/resources/img/svg-sprite.svg") 22.29102167% 98.41423948% no-repeat;
  width: 14px;
  height: 14px;
}
.vsphere-icon-event-console {
  background: url("/ui/resources/img/svg-sprite.svg") 78.9968652% 70.19672131% no-repeat;
  width: 18px;
  height: 18px;
}
.vsphere-icon-export-line_16x16 {
  background: url("/ui/resources/img/svg-sprite.svg") 84.63949843% 70.19672131% no-repeat;
  width: 18px;
  height: 18px;
}
.vsphere-icon-filebrowser-copy {
  background: url("/ui/resources/img/svg-sprite.svg") 0 76.09836066% no-repeat;
  width: 18px;
  height: 18px;
}
.vsphere-icon-filebrowser-download {
  background: url("/ui/resources/img/svg-sprite.svg") 5.64263323% 76.09836066% no-repeat;
  width: 18px;
  height: 18px;
}
.vsphere-icon-filebrowser-move {
  background: url("/ui/resources/img/svg-sprite.svg") 11.28526646% 76.09836066% no-repeat;
  width: 18px;
  height: 18px;
}
.vsphere-icon-filebrowser-upload {
  background: url("/ui/resources/img/svg-sprite.svg") 16.92789969% 76.09836066% no-repeat;
  width: 18px;
  height: 18px;
}
.vsphere-icon-folder {
  background: url("/ui/resources/img/svg-sprite.svg") 22.57053292% 76.09836066% no-repeat;
  width: 18px;
  height: 18px;
}
.vsphere-icon-folder-add {
  background: url("/ui/resources/img/svg-sprite.svg") 28.21316614% 76.09836066% no-repeat;
  width: 18px;
  height: 18px;
}
.vsphere-icon-fujitsu {
  background: url("/ui/resources/img/svg-sprite.svg") 76.03452653% 18.31501832% no-repeat;
  width: 100.66px;
  height: 50px;
}
.vsphere-icon-help-info {
  background: url("/ui/resources/img/svg-sprite.svg") 39.4984326% 76.09836066% no-repeat;
  width: 18px;
  height: 18px;
}
.vsphere-icon-help-info-hover {
  background: url("/ui/resources/img/svg-sprite.svg") 45.14106583% 76.09836066% no-repeat;
  width: 18px;
  height: 18px;
}
.vsphere-icon-host {
  background: url("/ui/resources/img/svg-sprite.svg") 50.78369906% 76.09836066% no-repeat;
  width: 18px;
  height: 18px;
}
.vsphere-icon-host-disconnected {
  background: url("/ui/resources/img/svg-sprite.svg") 56.42633229% 76.09836066% no-repeat;
  width: 18px;
  height: 18px;
}
.vsphere-icon-host-error {
  background: url("/ui/resources/img/svg-sprite.svg") 62.06896552% 76.09836066% no-repeat;
  width: 18px;
  height: 18px;
}
.vsphere-icon-host-maintenance {
  background: url("/ui/resources/img/svg-sprite.svg") 67.71159875% 76.09836066% no-repeat;
  width: 18px;
  height: 18px;
}
.vsphere-icon-host-warning {
  background: url("/ui/resources/img/svg-sprite.svg") 73.35423197% 76.09836066% no-repeat;
  width: 18px;
  height: 18px;
}
.vsphere-icon-hp {
  background: url("/ui/resources/img/svg-sprite.svg") 0 0 no-repeat;
  width: 179.7px;
  height: 71px;
}
.vsphere-icon-ibm {
  background: url("/ui/resources/img/svg-sprite.svg") 83.15209847% 0 no-repeat;
  width: 120.89px;
  height: 50px;
}
.vsphere-icon-info {
  background: url("/ui/resources/img/svg-sprite.svg") 0 82% no-repeat;
  width: 18px;
  height: 18px;
}
.vsphere-icon-memory-summary {
  background: url("/ui/resources/img/svg-sprite.svg") 46.3022508% 36.7003367% no-repeat;
  width: 26px;
  height: 26px;
}
.vsphere-icon-network {
  background: url("/ui/resources/img/svg-sprite.svg") 11.28526646% 82% no-repeat;
  width: 18px;
  height: 18px;
}
.vsphere-icon-network-24x {
  background: url("/ui/resources/img/svg-sprite.svg") 29.58199357% 45.45454545% no-repeat;
  width: 26px;
  height: 26px;
}
.vsphere-icon-network-error {
  background: url("/ui/resources/img/svg-sprite.svg") 22.57053292% 82% no-repeat;
  width: 18px;
  height: 18px;
}
.vsphere-icon-network-warning {
  background: url("/ui/resources/img/svg-sprite.svg") 28.21316614% 82% no-repeat;
  width: 18px;
  height: 18px;
}
.vsphere-icon-ovf-deploy {
  background: url("/ui/resources/img/svg-sprite.svg") 33.85579937% 82% no-repeat;
  width: 18px;
  height: 18px;
}
.vsphere-icon-plugin {
  background: url("/ui/resources/img/svg-sprite.svg") 39.4984326% 82% no-repeat;
  width: 18px;
  height: 18px;
}
.vsphere-icon-portChannel {
  background: url("/ui/resources/img/svg-sprite.svg") 45.14106583% 82% no-repeat;
  width: 18px;
  height: 18px;
}
.vsphere-icon-port_blocked {
  background: url("/ui/resources/img/svg-sprite.svg") 50.15479876% 57.92880259% no-repeat;
  width: 14px;
  height: 14px;
}
.vsphere-icon-port_blocked_SRIOV {
  background: url("/ui/resources/img/svg-sprite.svg") 56.42633229% 82% no-repeat;
  width: 18px;
  height: 18px;
}
.vsphere-icon-port_blocked_SRIOV12x {
  background: url("/ui/resources/img/svg-sprite.svg") 45.82043344% 57.92880259% no-repeat;
  width: 14px;
  height: 14px;
}
.vsphere-icon-port_blocked_left {
  background: url("/ui/resources/img/svg-sprite.svg") 26.625387% 98.41423948% no-repeat;
  width: 14px;
  height: 14px;
}
.vsphere-icon-port_blue-grey {
  background: url("/ui/resources/img/svg-sprite.svg") 41.48606811% 57.92880259% no-repeat;
  width: 14px;
  height: 14px;
}
.vsphere-icon-port_blue-grey_left {
  background: url("/ui/resources/img/svg-sprite.svg") 98.63467492% 93.2038835% no-repeat;
  width: 14px;
  height: 14px;
}
.vsphere-icon-port_count {
  background: url("/ui/resources/img/svg-sprite.svg") 84.63949843% 82% no-repeat;
  width: 18px;
  height: 18px;
}
.vsphere-icon-port_green {
  background: url("/ui/resources/img/svg-sprite.svg") 32.81733746% 57.92880259% no-repeat;
  width: 14px;
  height: 14px;
}
.vsphere-icon-port_green_SRIOV {
  background: url("/ui/resources/img/svg-sprite.svg") 5.64263323% 87.90163934% no-repeat;
  width: 18px;
  height: 18px;
}
.vsphere-icon-port_green_SRIOV12x {
  background: url("/ui/resources/img/svg-sprite.svg") 28.48297214% 57.92880259% no-repeat;
  width: 14px;
  height: 14px;
}
.vsphere-icon-port_green_left {
  background: url("/ui/resources/img/svg-sprite.svg") 50.77399381% 52.10355987% no-repeat;
  width: 14px;
  height: 14px;
}
.vsphere-icon-port_red_X {
  background: url("/ui/resources/img/svg-sprite.svg") 50.15479876% 43.68932039% no-repeat;
  width: 14px;
  height: 14px;
}
.vsphere-icon-port_red_X_left {
  background: url("/ui/resources/img/svg-sprite.svg") 86.79876161% 27.83171521% no-repeat;
  width: 14px;
  height: 14px;
}
.vsphere-icon-port_white {
  background: url("/ui/resources/img/svg-sprite.svg") 37.15170279% 57.92880259% no-repeat;
  width: 14px;
  height: 14px;
}
.vsphere-icon-port_white_left {
  background: url("/ui/resources/img/svg-sprite.svg") 93.0619195% 87.37864078% no-repeat;
  width: 14px;
  height: 14px;
}
.vsphere-icon-portgroup {
  background: url("/ui/resources/img/svg-sprite.svg") 45.14106583% 87.90163934% no-repeat;
  width: 18px;
  height: 18px;
}
.vsphere-icon-powered-on {
  background: url("/ui/resources/img/svg-sprite.svg") 50.78369906% 87.90163934% no-repeat;
  width: 18px;
  height: 18px;
}
.vsphere-icon-precheck-error {
  background: url("/ui/resources/img/svg-sprite.svg") 56.42633229% 87.90163934% no-repeat;
  width: 18px;
  height: 18px;
}
.vsphere-icon-precheck-ok {
  background: url("/ui/resources/img/svg-sprite.svg") 62.06896552% 87.90163934% no-repeat;
  width: 18px;
  height: 18px;
}
.vsphere-icon-precheck-warning {
  background: url("/ui/resources/img/svg-sprite.svg") 67.71159875% 87.90163934% no-repeat;
  width: 18px;
  height: 18px;
}
.vsphere-icon-remove {
  background: url("/ui/resources/img/svg-sprite.svg") 73.35423197% 87.90163934% no-repeat;
  width: 18px;
  height: 18px;
}
.vsphere-icon-rescan-hba {
  background: url("/ui/resources/img/svg-sprite.svg") 78.9968652% 87.90163934% no-repeat;
  width: 18px;
  height: 18px;
}
.vsphere-icon-rescan-storage {
  background: url("/ui/resources/img/svg-sprite.svg") 84.63949843% 87.90163934% no-repeat;
  width: 18px;
  height: 18px;
}
.vsphere-icon-resource-pool {
  background: url("/ui/resources/img/svg-sprite.svg") 94.22884013% 0 no-repeat;
  width: 18px;
  height: 18px;
}
.vsphere-icon-roles-clone,
.vsphere-icon-roles-clone\:regular {
  background: url("/ui/resources/img/svg-sprite.svg") 94.22884013% 5.90163934% no-repeat;
  width: 18px;
  height: 18px;
}
.vsphere-icon-roles-clone:hover,
.vsphere-icon-roles-clone\:hover {
  background: url("/ui/resources/img/svg-sprite.svg") 94.22884013% 11.80327869% no-repeat;
  width: 18px;
  height: 18px;
}
.vsphere-icon-rules-profile {
  background: url("/ui/resources/img/svg-sprite.svg") 94.22884013% 17.70491803% no-repeat;
  width: 18px;
  height: 18px;
}
.vsphere-icon-scsi-adapter {
  background: url("/ui/resources/img/svg-sprite.svg") 94.22884013% 23.60655738% no-repeat;
  width: 18px;
  height: 18px;
}
.vsphere-icon-scsi-adapter-warning {
  background: url("/ui/resources/img/svg-sprite.svg") 94.22884013% 29.50819672% no-repeat;
  width: 18px;
  height: 18px;
}
.vsphere-icon-snapshot {
  background: url("/ui/resources/img/svg-sprite.svg") 94.22884013% 35.40983607% no-repeat;
  width: 18px;
  height: 18px;
}
.vsphere-icon-snapshot_here {
  background: url("/ui/resources/img/svg-sprite.svg") 94.22884013% 41.31147541% no-repeat;
  width: 18px;
  height: 18px;
}
.vsphere-icon-snapshot_run {
  background: url("/ui/resources/img/svg-sprite.svg") 94.22884013% 47.21311475% no-repeat;
  width: 18px;
  height: 18px;
}
.vsphere-icon-snapshot_suspend {
  background: url("/ui/resources/img/svg-sprite.svg") 94.22884013% 53.1147541% no-repeat;
  width: 18px;
  height: 18px;
}
.vsphere-icon-status-error {
  background: url("/ui/resources/img/svg-sprite.svg") 94.22884013% 59.01639344% no-repeat;
  width: 18px;
  height: 18px;
}
.vsphere-icon-status-ok {
  background: url("/ui/resources/img/svg-sprite.svg") 94.22884013% 64.91803279% no-repeat;
  width: 18px;
  height: 18px;
}
.vsphere-icon-status-unknown {
  background: url("/ui/resources/img/svg-sprite.svg") 94.22884013% 70.81967213% no-repeat;
  width: 18px;
  height: 18px;
}
.vsphere-icon-status-warning {
  background: url("/ui/resources/img/svg-sprite.svg") 94.22884013% 76.72131148% no-repeat;
  width: 18px;
  height: 18px;
}
.vsphere-icon-storage-device-mount {
  background: url("/ui/resources/img/svg-sprite.svg") 94.22884013% 82.62295082% no-repeat;
  width: 18px;
  height: 18px;
}
.vsphere-icon-storage-device-unmount {
  background: url("/ui/resources/img/svg-sprite.svg") 0 93.80327869% no-repeat;
  width: 18px;
  height: 18px;
}
.vsphere-icon-storage-summary {
  background: url("/ui/resources/img/svg-sprite.svg") 29.58199357% 36.7003367% no-repeat;
  width: 26px;
  height: 26px;
}
.vsphere-icon-storage-system-refresh {
  background: url("/ui/resources/img/svg-sprite.svg") 11.28526646% 93.80327869% no-repeat;
  width: 18px;
  height: 18px;
}
.vsphere-icon-suspended {
  background: url("/ui/resources/img/svg-sprite.svg") 16.92789969% 93.80327869% no-repeat;
  width: 18px;
  height: 18px;
}
.vsphere-icon-synchronize {
  background: url("/ui/resources/img/svg-sprite.svg") 22.57053292% 93.80327869% no-repeat;
  width: 18px;
  height: 18px;
}
.vsphere-icon-task-console {
  background: url("/ui/resources/img/svg-sprite.svg") 28.21316614% 93.80327869% no-repeat;
  width: 18px;
  height: 18px;
}
.vsphere-icon-toolbar-add,
.vsphere-icon-toolbar-add\:regular {
  background: url("/ui/resources/img/svg-sprite.svg") 33.85579937% 93.80327869% no-repeat;
  width: 18px;
  height: 18px;
}
.vsphere-icon-toolbar-add-folder {
  background: url("/ui/resources/img/svg-sprite.svg") 39.4984326% 93.80327869% no-repeat;
  width: 18px;
  height: 18px;
}
.vsphere-icon-toolbar-add-vm {
  background: url("/ui/resources/img/svg-sprite.svg") 45.14106583% 93.80327869% no-repeat;
  width: 18px;
  height: 18px;
}
.vsphere-icon-toolbar-add:hover,
.vsphere-icon-toolbar-add\:hover {
  background: url("/ui/resources/img/svg-sprite.svg") 50.78369906% 93.80327869% no-repeat;
  width: 18px;
  height: 18px;
}
.vsphere-icon-toolbar-edit {
  background: url("/ui/resources/img/svg-sprite.svg") 56.42633229% 93.80327869% no-repeat;
  width: 18px;
  height: 18px;
}
.vsphere-icon-toolbar-host-question {
  background: url("/ui/resources/img/svg-sprite.svg") 62.06896552% 93.80327869% no-repeat;
  width: 18px;
  height: 18px;
}
.vsphere-icon-toolbar-more {
  background: url("/ui/resources/img/svg-sprite.svg") 67.71159875% 93.80327869% no-repeat;
  width: 18px;
  height: 18px;
}
.vsphere-icon-toolbar-network-add {
  background: url("/ui/resources/img/svg-sprite.svg") 73.35423197% 93.80327869% no-repeat;
  width: 18px;
  height: 18px;
}
.vsphere-icon-toolbar-remove {
  background: url("/ui/resources/img/svg-sprite.svg") 78.9968652% 93.80327869% no-repeat;
  width: 18px;
  height: 18px;
}
.vsphere-icon-toolbar-roles {
  background: url("/ui/resources/img/svg-sprite.svg") 84.63949843% 93.80327869% no-repeat;
  width: 18px;
  height: 18px;
}
.vsphere-icon-uplink-port-group {
  background: url("/ui/resources/img/svg-sprite.svg") 90.28213166% 93.80327869% no-repeat;
  width: 18px;
  height: 18px;
}
.vsphere-icon-uplink-port-group-alert {
  background: url("/ui/resources/img/svg-sprite.svg") 99.87147335% 0 no-repeat;
  width: 18px;
  height: 18px;
}
.vsphere-icon-uplink-port-group-warning {
  background: url("/ui/resources/img/svg-sprite.svg") 99.87147335% 5.90163934% no-repeat;
  width: 18px;
  height: 18px;
}
.vsphere-icon-vapp {
  background: url("/ui/resources/img/svg-sprite.svg") 99.87147335% 11.80327869% no-repeat;
  width: 18px;
  height: 18px;
}
.vsphere-icon-vapp-add {
  background: url("/ui/resources/img/svg-sprite.svg") 99.87147335% 17.70491803% no-repeat;
  width: 18px;
  height: 18px;
}
.vsphere-icon-vapp-edit {
  background: url("/ui/resources/img/svg-sprite.svg") 99.87147335% 23.60655738% no-repeat;
  width: 18px;
  height: 18px;
}
.vsphere-icon-vapp-managed {
  background: url("/ui/resources/img/svg-sprite.svg") 99.87147335% 29.50819672% no-repeat;
  width: 18px;
  height: 18px;
}
.vsphere-icon-vapp-running {
  background: url("/ui/resources/img/svg-sprite.svg") 99.87147335% 35.40983607% no-repeat;
  width: 18px;
  height: 18px;
}
.vsphere-icon-vapp-running-managed {
  background: url("/ui/resources/img/svg-sprite.svg") 99.87147335% 41.31147541% no-repeat;
  width: 18px;
  height: 18px;
}
.vsphere-icon-vapp-suspended {
  background: url("/ui/resources/img/svg-sprite.svg") 99.87147335% 47.21311475% no-repeat;
  width: 18px;
  height: 18px;
}
.vsphere-icon-vapp-suspended-managed {
  background: url("/ui/resources/img/svg-sprite.svg") 99.87147335% 53.1147541% no-repeat;
  width: 18px;
  height: 18px;
}
.vsphere-icon-vc-extension {
  background: url("/ui/resources/img/svg-sprite.svg") 99.87147335% 59.01639344% no-repeat;
  width: 18px;
  height: 18px;
}
.vsphere-icon-vcenter {
  background: url("/ui/resources/img/svg-sprite.svg") 99.87147335% 64.91803279% no-repeat;
  width: 18px;
  height: 18px;
}
.vsphere-icon-view_properties {
  background: url("/ui/resources/img/svg-sprite.svg") 99.87147335% 70.81967213% no-repeat;
  width: 18px;
  height: 18px;
}
.vsphere-icon-virtual-port-group {
  background: url("/ui/resources/img/svg-sprite.svg") 99.87147335% 76.72131148% no-repeat;
  width: 18px;
  height: 18px;
}
.vsphere-icon-virtual-port-group-alert {
  background: url("/ui/resources/img/svg-sprite.svg") 99.87147335% 82.62295082% no-repeat;
  width: 18px;
  height: 18px;
}
.vsphere-icon-virtual-port-group-warning {
  background: url("/ui/resources/img/svg-sprite.svg") 99.87147335% 88.52459016% no-repeat;
  width: 18px;
  height: 18px;
}
.vsphere-icon-vm {
  background: url("/ui/resources/img/svg-sprite.svg") 0 99.70491803% no-repeat;
  width: 18px;
  height: 18px;
}
.vsphere-icon-vm-add {
  background: url("/ui/resources/img/svg-sprite.svg") 5.64263323% 99.70491803% no-repeat;
  width: 18px;
  height: 18px;
}
.vsphere-icon-vm-add-from-template {
  background: url("/ui/resources/img/svg-sprite.svg") 11.28526646% 99.70491803% no-repeat;
  width: 18px;
  height: 18px;
}
.vsphere-icon-vm-error {
  background: url("/ui/resources/img/svg-sprite.svg") 16.92789969% 99.70491803% no-repeat;
  width: 18px;
  height: 18px;
}
.vsphere-icon-vm-information {
  background: url("/ui/resources/img/svg-sprite.svg") 39.4984326% 87.90163934% no-repeat;
  width: 18px;
  height: 18px;
}
.vsphere-icon-vm-on {
  background: url("/ui/resources/img/svg-sprite.svg") 33.85579937% 87.90163934% no-repeat;
  width: 18px;
  height: 18px;
}
.vsphere-icon-vm-suspended {
  background: url("/ui/resources/img/svg-sprite.svg") 28.21316614% 87.90163934% no-repeat;
  width: 18px;
  height: 18px;
}
.vsphere-icon-vm-template {
  background: url("/ui/resources/img/svg-sprite.svg") 22.57053292% 87.90163934% no-repeat;
  width: 18px;
  height: 18px;
}
.vsphere-icon-vm-templates-24x {
  background: url("/ui/resources/img/svg-sprite.svg") 37.94212219% 45.45454545% no-repeat;
  width: 26px;
  height: 26px;
}
.vsphere-icon-vm-warning {
  background: url("/ui/resources/img/svg-sprite.svg") 11.28526646% 87.90163934% no-repeat;
  width: 18px;
  height: 18px;
}
.vsphere-icon-vmManaged {
  background: url("/ui/resources/img/svg-sprite.svg") 0 87.90163934% no-repeat;
  width: 18px;
  height: 18px;
}
.vsphere-icon-vmOnManaged {
  background: url("/ui/resources/img/svg-sprite.svg") 78.9968652% 82% no-repeat;
  width: 18px;
  height: 18px;
}
.vsphere-icon-vmRedManaged {
  background: url("/ui/resources/img/svg-sprite.svg") 73.35423197% 82% no-repeat;
  width: 18px;
  height: 18px;
}
.vsphere-icon-vmStuckManaged {
  background: url("/ui/resources/img/svg-sprite.svg") 62.06896552% 82% no-repeat;
  width: 18px;
  height: 18px;
}
.vsphere-icon-vmSuspendedManaged {
  background: url("/ui/resources/img/svg-sprite.svg") 50.78369906% 82% no-repeat;
  width: 18px;
  height: 18px;
}
.vsphere-icon-vmYellowManaged {
  background: url("/ui/resources/img/svg-sprite.svg") 73.35423197% 70.19672131% no-repeat;
  width: 18px;
  height: 18px;
}
.vsphere-icon-vsphere-admin {
  background: url("/ui/resources/img/svg-sprite.svg") 28.21316614% 64.29508197% no-repeat;
  width: 18px;
  height: 18px;
}
.vsphere-icon-vsphere-inventory-lists {
  background: url("/ui/resources/img/svg-sprite.svg") 50.78369906% 64.29508197% no-repeat;
  width: 18px;
  height: 18px;
}
.password-label {
  display: inline;
  float: left;
}
.password-input {
  width: 50%;
  float: right;
  display: inline;
}
linked-text div {
  display: inline;
}
settings-view-header {
  display: block;
}
settings-view-header .settingsViewHeader {
  -webkit-flex: 1 1 auto;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  display: flex;
  align-items: center;
  box-sizing: border-box;
}
settings-view-header .settingsViewHeader .settingsBlockTitle {
  flex: 1 1 auto;
  font-size: 22px;
  font-weight: 400;
  color: #565656;
  padding: 4px 0 4px 2px;
}
settings-view-header .settingsViewHeader .sizing-button {
  visibility: hidden;
  margin-left: 10px;
  margin-right: 0;
  margin-top: 0;
}
settings-view-header .settingsViewHeader .sizing-button:not(:last-child) {
  display: none;
}
settings-view-header .settingsViewHeader .settingsBlockBtnGroup.btn-group {
  margin: 0;
  padding: 0 0.3em 0 0;
}
.user-tabbing {
  /* Vui tree (inventory and object selector tree) */
}
.user-tabbing div[vui-tree-view]:focus {
  outline: 1px dotted #212121 !important;
  outline-offset: -1px;
  outline: 5px auto -webkit-focus-ring-color !important;
}
div[vui-datagrid] .k-header > .k-grid-filter {
  margin-right: 3px !important;
}
div[vui-datagrid] .k-header > .k-grid-filter span.k-icon {
  padding: 0 !important;
}
.k-treelist.k-grid.k-widget {
  box-sizing: border-box;
  border: 1px solid #dddddd;
  box-shadow: none;
  background-color: #fff;
  border-radius: 0;
}
.k-treelist.k-grid.k-widget .k-grouping-header {
  border: none;
  border-bottom: 1px solid #dddddd;
  border-radius: 0;
}
.k-treelist.k-grid.k-widget .k-grid-header {
  border: none;
  border-bottom: 1px solid #dddddd;
  background-color: #fafafa;
}
.k-treelist.k-grid.k-widget .k-grid-header .k-link:link {
  color: #565656;
}
.k-treelist.k-grid.k-widget .k-grid-header th.k-header {
  height: 36px;
  vertical-align: middle;
  padding: 0 0 0 12px;
  border-bottom: none;
  background-color: #fafafa;
  font-size: 12px;
}
.k-treelist.k-grid.k-widget .k-grid-content tbody tr,
.k-treelist.k-grid.k-widget .k-grid-content-locked tbody tr {
  height: 30px;
}
.k-treelist.k-grid.k-widget .k-grid-content tbody tr:not(.k-alt),
.k-treelist.k-grid.k-widget .k-grid-content-locked tbody tr:not(.k-alt) {
  background-color: #fff;
}
.k-treelist.k-grid.k-widget .k-grid-content tbody tr.k-alt,
.k-treelist.k-grid.k-widget .k-grid-content-locked tbody tr.k-alt {
  background-color: #fff;
}
.k-treelist.k-grid.k-widget .k-grid-content tbody tr.k-state-selected,
.k-treelist.k-grid.k-widget .k-grid-content-locked tbody tr.k-state-selected {
  background-color: #eee;
  color: #000;
}
.k-treelist.k-grid.k-widget .k-grid-content tbody tr:hover,
.k-treelist.k-grid.k-widget .k-grid-content-locked tbody tr:hover {
  background-color: #EEEEEE;
  color: #4b4b4b;
}
.k-treelist.k-grid.k-widget .k-grid-content tbody tr:hover .k-state-seleted,
.k-treelist.k-grid.k-widget .k-grid-content-locked tbody tr:hover .k-state-seleted {
  background-color: #EEEEEE;
}
.k-treelist.k-grid.k-widget .k-grid-content tbody tr:hover .k-state-focused,
.k-treelist.k-grid.k-widget .k-grid-content-locked tbody tr:hover .k-state-focused {
  background-color: #EEEEEE;
}
.k-treelist.k-grid.k-widget .k-grid-content tbody tr:hover,
.k-treelist.k-grid.k-widget .k-grid-content-locked tbody tr:hover {
  background-color: #f8f8f8;
}
.k-treelist.k-grid.k-widget .k-grid-content tbody tr.k-state-selected,
.k-treelist.k-grid.k-widget .k-grid-content-locked tbody tr.k-state-selected {
  background-color: #eee;
  color: #313131;
}
.k-treelist.k-grid.k-widget .k-grid-content tbody tr.k-state-selected:hover td,
.k-treelist.k-grid.k-widget .k-grid-content-locked tbody tr.k-state-selected:hover td {
  background-color: #EEEEEE;
}
.k-treelist.k-grid.k-widget .k-grid-content tbody tr td,
.k-treelist.k-grid.k-widget .k-grid-content-locked tbody tr td {
  border-width: 0 0 1px 0;
  border-color: #dddddd;
  font-size: 12px;
  font-weight: normal;
  color: #333;
  padding: 0 12px;
}
div[vui-tree-view] .k-icon.k-icon.k-plus:not(.k-loading),
div[vui-tree-view] .k-icon.k-icon.k-minus:not(.k-loading) {
  background-size: 16px 16px;
  animation-play-state: paused;
}
.italic {
  font-style: italic;
}
.k-treeview .k-item > .k-group {
  min-width: 120px;
}
.formPane {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}
.formPane .userRolesPane {
  -webkit-flex: 1 0 50%;
  -ms-flex: 1 0 50%;
  flex: 1 0 50%;
  padding-right: 10px;
}
.formPane .privilegesPane {
  -webkit-flex: 1 0 50%;
  -ms-flex: 1 0 50%;
  flex: 1 0 50%;
  padding-left: 10px;
}
.formPane .row {
  margin-left: 0px;
  margin-right: 0px;
}
storage-locator storage-profile {
  display: inline-block;
  padding-bottom: 8px;
}
storage-locator storage-profile div.select {
  min-width: 180px;
}
storage-locator disk-format-selector {
  display: inline-block;
  padding-bottom: 8px;
  min-width: 180px;
}
storage-locator .settings-label {
  padding-right: 10px;
}
storage-locator .storage-locator-settings-table {
  width: 100%;
  table-layout: fixed;
}
storage-locator .storage-locator-settings-table tr {
  vertical-align: top;
}
storage-locator .storage-locator-settings-table a.tooltip {
  align-self: center;
  margin-left: 5px;
  opacity: 1;
}
storage-locator .replication-group-container {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  padding-top: 8px;
}
storage-locator .replication-group-container select {
  min-width: 220px;
}
.storage-selector-header .toggle-switch {
  margin-right: 0px;
}
.storage-selector-header-pmem-info {
  padding-top: 1px;
  margin-left: 5px;
  margin-top: -6px;
}
.storage-selector-dialog {
  height: 400px;
}
.encryption-options {
  padding-left: 0px !important;
}
.center-dialog-content {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 1;
  pointer-events: none;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}
.center-dialog-content.block-input {
  pointer-events: auto;
  background: rgba(255, 255, 255, 0.2);
}
.center-dialog-content.ng-animate {
  transition: 1s all;
  transition-timing-function: step-end;
}
.center-dialog-content.ng-animate.ng-hide-animate {
  opacity: 0;
}
.center-dialog-content.ng-animate.ng-hide-animate.ng-hide-remove.ng-hide-remove-active {
  opacity: 1;
}
vx-static-progress-indicator .progress-static {
  align-items: center;
  flex-grow: 1;
  flex-shrink: 1;
  padding-right: 0;
  width: auto;
}
vx-static-progress-indicator .progress-static > .progress-meter {
  right: 0;
}
vx-static-progress-indicator [role="percentage"] {
  align-self: center;
  padding-left: 5px;
  white-space: nowrap;
  line-height: 1;
  flex-basis: 4ch;
}
vx-static-progress-indicator [role="cancelButton"].vui-icon-datagrid-cancel-disabled {
  cursor: not-allowed;
}
vx-static-progress-indicator [role="cancelButton"].vui-icon-datagrid-cancel {
  cursor: pointer;
  opacity: 0.7;
}
.progress-static {
  align-items: center;
  flex-grow: 1;
  flex-shrink: 1;
  padding-right: 0;
  width: auto;
}
.progress-static > .progress-meter {
  right: 0;
}
[role="percentage"] {
  flex: 0 0 10px;
  align-self: center;
  padding-left: 5px;
  white-space: nowrap;
  line-height: 1;
  flex-basis: 4ch;
}
[role="cancelButton"] {
  flex: 0 0 15px;
}
[role="cancelButton"].vui-icon-datagrid-cancel-disabled {
  cursor: not-allowed;
}
[role="cancelButton"].vui-icon-datagrid-cancel {
  cursor: pointer;
  opacity: 0.7;
}
.text-overflow-ellipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.modal-dialog .modal-content .modal-header .modal-title {
  display: flex;
  align-items: flex-start;
}
.modal-dialog .modal-content .modal-header .modal-title .secondaryTitle {
  margin-top: 0;
  margin-bottom: 0;
  line-height: 24px;
}
.modal-dialog .modal-content .modal-header .modal-title .primaryTitle {
  flex-shrink: 0;
  font-size: 24px;
}
.modal-dialog .modal-content .modal-header .modal-title .primaryTitle.secondaryTitleIsEmpty {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  flex-shrink: 1;
}
.modal-dialog .modal-content .modal-header .modal-title .primaryTitle:not(.secondaryTitleIsEmpty) {
  max-width: 65%;
}
.modal-dialog .modal-content .modal-header .modal-title .separator {
  content: "";
  border-left: 1px #ccc solid;
  margin: 0 18px;
  height: 22px;
}
.modal-dialog .modal-content .modal-header .modal-title .secondaryTitle {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  display: block;
}
[has-modal-button-group] .modal-dialog .modal-content .modal-header {
  padding-bottom: 12px;
}
.modal-button-group {
  margin-top: 0;
  margin-bottom: 12px;
}
.modal-button-group > button.btn {
  margin: 0;
}
.modal {
  overflow-y: auto;
}
.vui-popup.vui-dialog {
  padding: 24px;
}
.vui-popup.vui-dialog .titlebar.ui-draggable-handle {
  padding: 0 !important;
}
.vui-popup.vui-dialog .titlebar.ui-draggable-handle .titlebar-text {
  color: #000;
  font-size: 24px;
  font-family: "ProximaNova", "Avenir Next", "Helvetica Neue", Arial, sans-serif;
  font-weight: 200;
  line-height: 27px;
  letter-spacing: normal;
  margin: 0;
  padding: 0;
}
.vui-popup.vui-dialog .panel-content {
  padding: 0 !important;
}
.vui-popup.vui-dialog .dialog-footer {
  height: auto;
  padding: 0;
  margin-top: 12px;
}
.vui-popup.vui-dialog .dialog-footer .btn {
  margin: 0 0 0 12px;
}
.vui-wizard {
  padding: 24px;
}
.vui-wizard .wizard-modal-titlebar {
  padding: 0 !important;
  background: none !important;
}
.vui-wizard .wizard-modal-titlebar .titlebar-text {
  color: #000;
  font-size: 24px;
  font-family: "ProximaNova", "Avenir Next", "Helvetica Neue", Arial, sans-serif;
  font-weight: 200;
  line-height: 27px;
  letter-spacing: normal;
  margin: 0;
  padding: 0;
}
.vui-wizard .wizard-modal-body {
  flex: 1 1 100%;
  box-sizing: border-box;
  background: none !important;
}
.vui-wizard .wizard-modal-body .wizard-panel-TOC {
  background: none !important;
}
.vui-wizard .wizard-modal-body .wizard-panel-TOC .wizard-steps-list li a {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.vui-wizard .wizard-modal-body .wizard-content-container {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  flex: 1 1 100%;
  box-sizing: border-box;
}
.vui-wizard .wizard-modal-body .wizard-content-container[vui-splitter] .k-splitter {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  flex: 1 1 100%;
  box-sizing: border-box;
  height: auto !important;
}
.vui-wizard .wizard-modal-body .wizard-content-container[vui-splitter] .k-splitter.k-widget {
  border: none;
  background-color: transparent;
}
.vui-wizard .wizard-modal-body .wizard-content-container .wizard-content-header-area {
  flex-shrink: 0;
}
.vui-wizard .wizard-modal-body .wizard-content-container .wizard-content-header-area .wizard-content-title,
.vui-wizard .wizard-modal-body .wizard-content-container .wizard-content-header-area .wizard-content-instructional-text {
  outline: none;
}
.vui-wizard .wizard-modal-body .wizard-content-container .wizard-content {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  flex: 1 1 100%;
  box-sizing: border-box;
}
.vui-wizard .wizard-modal-body .wizard-content-container .wizard-content[vui-splitter] .k-splitter {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  flex: 1 1 100%;
  box-sizing: border-box;
  height: auto !important;
}
.vui-wizard .wizard-modal-body .wizard-content-container .wizard-content[vui-splitter] .k-splitter.k-widget {
  border: none;
  background-color: transparent;
}
.vui-wizard .wizard-modal-body .wizard-content-container .wizard-content > div:not(.creation-type):not(.edit-ha-dialog) {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  flex: 1 1 100%;
  box-sizing: border-box;
}
.vui-wizard .wizard-modal-body .wizard-content-container .wizard-content > div:not(.creation-type):not(.edit-ha-dialog)[vui-splitter] .k-splitter {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  flex: 1 1 100%;
  box-sizing: border-box;
  height: auto !important;
}
.vui-wizard .wizard-modal-body .wizard-content-container .wizard-content > div:not(.creation-type):not(.edit-ha-dialog)[vui-splitter] .k-splitter.k-widget {
  border: none;
  background-color: transparent;
}
.vui-wizard .wizard-modal-body .wizard-content-container .wizard-content .creation-type {
  display: flex;
  align-items: flex-start;
  box-sizing: border-box;
}
.vui-wizard .wizard-modal-body .wizard-content-container .wizard-content .creation-type .left-half-page {
  flex: 1 1 100%;
  box-sizing: border-box;
  margin-right: 5px;
}
.vui-wizard .wizard-modal-body .wizard-content-container .wizard-content .creation-type .right-half-page {
  flex: 1 1 100%;
  box-sizing: border-box;
  margin-left: 5px;
}
.vui-wizard .wizard-modal-footer {
  height: auto;
  padding: 0;
  margin-top: 12px;
  border: none;
  background: none !important;
}
.vui-wizard .wizard-modal-footer .btn {
  margin: 0 0 0 12px;
}
.vui-wizard .wizard-modal-footer .wizard-resize-icon-span:empty {
  display: none;
}
.vui-wizard .compatibility {
  padding-top: 10px;
  display: flex;
  flex: 0 1 auto;
  flex-flow: column;
  height: 150px;
}
.vui-wizard fieldset {
  display: flex;
  flex-flow: column;
  padding-left: 23px;
}
.vui-wizard fieldset:disabled,
.vui-wizard fieldset[disabled] {
  color: lightgray;
}
.dialog-content-view .form-group p {
  margin-top: 0 !important;
}
vui-wizard-toc .wizard-steps-list li {
  display: flex;
  align-items: center;
}
vui-wizard-toc .wizard-steps-list li,
vui-wizard-toc .wizard-steps-list li * {
  line-height: 24px !important;
}
.pnic-select-dialog-content {
  overflow: hidden;
  box-sizing: border-box;
}
.pnic-select-dialog-content .failover-order-details {
  padding-left: 0;
}
.pnic-select-dialog-content .failover-order-with-details failover-order {
  box-sizing: border-box;
  position: relative;
  display: block;
}
.radio {
  margin-bottom: 10px;
}
div[ng-controller="EditCustomAttributesDialogController as editCtrl"] > div {
  overflow: hidden;
}
.clr-confirm-modal .modal-body {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}
.clr-confirm-modal .modal-body .clr-confirm-modal-icon {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  align-items: center;
}
.clr-confirm-modal .modal-body .clr-confirm-modal-icon span {
  margin-right: 20px;
}
.clr-confirm-modal .modal-body .clr-confirm-modal-icon-top {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
}
.clr-confirm-modal .modal-body .clr-confirm-modal-icon-top span {
  margin-right: 20px;
}
.clr-confirm-modal .modal-body p {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  margin-top: 0px;
}
.creation-type select option {
  height: 24px;
  padding-left: 4px;
  padding-top: 4px;
  box-sizing: border-box;
}
heartbeat-datastores > .fill-parent {
  height: auto;
}
.body-overflow-hidden {
  overflow: hidden;
}
.body-overflow-hidden clr-stack-view > dl,
.body-overflow-hidden clr-stack-view > dl.stack-view {
  overflow: auto;
}
.vertical-padding {
  padding-top: 10px;
  padding-bottom: 10px;
}
.vx-property-view {
  width: 100%;
  height: 100%;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  background-color: white;
}
.vx-property-view .nav.nav-tabs {
  overflow: hidden;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
}
.vx-property-view .vx-property-view-table-wrapper {
  -webkit-flex: 1 1 auto;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  width: 100%;
  height: auto;
  overflow: auto;
  padding: 10px;
  box-sizing: border-box;
}
.vx-property-view .vx-property-view-table-wrapper .vx-property-view-table {
  width: 100%;
  box-sizing: border-box;
  border-collapse: collapse;
}
.vx-property-view .vx-property-view-table-wrapper .vx-property-view-table tr {
  vertical-align: top;
}
.vx-property-view .vx-property-view-table-wrapper .vx-property-view-table .vx-property-view-section {
  line-height: 18px;
}
.vx-property-view .vx-property-view-table-wrapper .vx-property-view-table .vx-property-view-section:not(:first-child) {
  border-top: 15px solid transparent;
}
.vx-property-view .vx-property-view-table-wrapper .vx-property-view-table .vx-property-view-section .vx-property-view-section-title {
  font-weight: bold;
}
.vx-property-view .vx-property-view-table-wrapper .vx-property-view-table .vx-property-view-section .vx-property-view-section-title .vx-property-view-section-title-icon {
  margin: -2px 0 0 0;
}
.vx-property-view .vx-property-view-table-wrapper .vx-property-view-table .vx-property-view-section .vx-property-view-section-actions-container {
  display: inline-block;
  float: right;
}
.vx-property-view .vx-property-view-table-wrapper .vx-property-view-table .vx-property-view-section .vx-property-view-section-actions-container a {
  margin-left: 12px;
}
.vx-property-view .vx-property-view-table-wrapper .vx-property-view-table .vx-property-view-section .vx-property-view-section-actions-container a.disabled {
  opacity: 0.4;
  pointer-events: none;
}
.vx-property-view .vx-property-view-table-wrapper .vx-property-view-table .vx-property-view-section .vx-property-view-section-padded-content {
  border-left: 30px solid transparent;
}
.vx-property-view .vx-property-view-table-wrapper .vx-property-view-table .vx-property-view-section .vx-property-view-section-message .vx-property-view-section-message-icon {
  margin: -2px 0 0 0;
}
.vx-property-view .vx-property-view-table-wrapper .vx-property-view-table .vx-property-view-section .vx-property-view-section-property .vx-property-view-section-property-key-column {
  border-right: 15px solid transparent;
}
.vx-property-view .vx-property-view-table-wrapper .vx-property-view-table .vx-property-view-section .vx-property-view-section-property .vx-property-view-section-property-key-column .vx-property-view-section-property-key-icon {
  margin-bottom: -2px;
}
.vx-property-view .vx-property-view-table-wrapper .vx-property-view-table .vx-property-view-section .vx-property-view-section-property .vx-property-view-section-property-values-table {
  display: table;
  width: 100%;
  table-layout: fixed;
  box-sizing: border-box;
  word-wrap: break-word;
}
.vx-property-view .vx-property-view-table-wrapper .vx-property-view-table .vx-property-view-section .vx-property-view-section-property .vx-property-view-section-property-values-table .vx-property-view-section-property-value {
  width: 100%;
  display: inline-block;
}
.vx-property-view .vx-property-view-table-wrapper .vx-property-view-table .vx-property-view-section .vx-property-view-section-property .vx-property-view-section-property-values-table .vx-property-view-section-property-value .vx-property-view-section-property-value-icon {
  margin: -2px 0 0 0;
}
.vx-property-view .vx-property-view-table-wrapper .vx-property-view-table .vx-property-view-section .vx-property-view-section-property .vx-property-view-section-property-values-table .vx-property-view-section-property-value .vx-property-view-section-property-value-text {
  white-space: pre-wrap;
}
.vx-property-view .vx-property-view-table .vx-property-view-section-property > .vx-property-view-section-property-key-column {
  white-space: nowrap;
}
.vx-property-view .vx-property-view-table .vx-property-view-section-property > .vx-property-view-section-property-values-column {
  width: 100%;
}
/*
  amarinov, vchomakov: Fix non-breakable text in value column for Chrome and Safari.
  DO NOT USE display: table as this breaks copy-pasting the whole property view table
  in Chrome and Safari.
*/
html.k-safari .vx-property-view .vx-property-view-table-wrapper .vx-property-view-table .vx-property-view-section .vx-property-view-section-property .vx-property-view-section-property-values-table,
html.k-webkit .vx-property-view .vx-property-view-table-wrapper .vx-property-view-table .vx-property-view-section .vx-property-view-section-property .vx-property-view-section-property-values-table {
  display: inline-block;
  table-layout: initial;
  word-break: break-word;
}
html.k-safari .vx-property-view .vx-property-view-table-wrapper .vx-property-view-table .vx-property-view-section .vx-property-view-section-property .vx-property-view-section-property-values-table .vx-property-view-section-property-value-text,
html.k-webkit .vx-property-view .vx-property-view-table-wrapper .vx-property-view-table .vx-property-view-section .vx-property-view-section-property .vx-property-view-section-property-values-table .vx-property-view-section-property-value-text {
  white-space: pre-wrap;
}
html .vx-portlet .vx-property-view .vx-property-view-table-wrapper .vx-property-view-table .vx-property-view-section .vx-property-view-section-property .vx-property-view-section-property-values-table .vx-property-view-section-property-value-text {
  white-space: nowrap;
}
.shares-control vx-value-in-units {
  margin-top: 0px;
}
.shares-control vx-value-in-units .input-group-btn {
  line-height: 18px;
}
.shares-control vx-value-in-units .input-group-btn button.dropdown-toggle {
  border-width: 1px;
}
.category-node-object-navigator {
  -webkit-user-select: none;
  user-select: none;
}
.category-node-object-navigator [role="container"] {
  padding-top: 24px;
  margin: 0;
}
.category-node-object-navigator [role="container"] [role="group"] {
  padding-left: 24px;
}
.category-node-object-navigator [role="container"] [role="group"] > .object-navigator-list {
  margin-right: 0;
}
.category-node-object-navigator [role="container"] [role="group"] [role="item"] > .object {
  padding-right: 0;
  border-radius: 3px 0 0 3px;
}
.category-node-object-navigator [role="container"] [role="group"] [role="item"] > .object.selected {
  font-size: 13px;
  color: #565656 !important;
  background: #d9e4ea;
}
.category-node-object-navigator [role="container"] [role="group"] [role="item"] > .object:hover {
  font-size: 13px;
  color: #565656 !important;
  background: #eee;
  color: #000 !important;
}
export-list .content {
  max-height: 450px;
  overflow: auto;
}
export-list .spinner-body {
  display: table;
  position: fixed;
  left: 50%;
  top: 50%;
  margin: -50px 0 0 -50px;
}
export-list .spinner-body .spinner-text {
  display: table-cell;
  vertical-align: middle;
  padding-left: 12px;
}
export-list .div-disabled {
  pointer-events: none;
  cursor: not-allowed;
  opacity: 0.6;
}
export-list .div-disabled .div-disabled {
  opacity: 1;
}
export-list .section {
  font-size: 18px;
  padding-top: 10px;
}
.failed-tasks-notification {
  list-style-type: none;
  margin: 0 0 10px;
}
.failed-tasks-notification > .item {
  display: table-row;
}
.failed-tasks-notification > .item > .name,
.failed-tasks-notification > .item .value {
  float: none;
  display: table-cell;
  width: auto;
}
.failed-tasks-notification > .item > .name {
  white-space: nowrap;
}
.failed-tasks-notification > .item .value {
  padding-left: 8px;
  white-space: pre-line;
}
search-center-pane {
  margin-left: -12px;
}
search-center-pane .search-center-pane {
  display: flex;
  flex-direction: column;
  height: 100%;
}
search-center-pane .search-center-pane vsc-search-center-pane {
  display: flex;
}
.remote-plugin-extension-context-selector {
  display: flex;
  flex-direction: column;
  position: relative;
}
.remote-plugin-extension-context-selector .remote-plugin-extension-context-selector-header {
  flex: 0 0 auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 24px;
  border-bottom: 1px solid transparent;
  margin-left: -10px;
  line-height: 48px;
  background-color: #FFFFFF;
  border-color: #CCCCCC;
}
[data-theme="dark"] .remote-plugin-extension-context-selector .remote-plugin-extension-context-selector-header {
  background-color: #17242B;
  border-color: #485764;
}
.remote-plugin-extension-context-selector .remote-plugin-extension-context-selector-header h6 {
  flex: 0 0 auto;
  margin-top: 0;
}
.remote-plugin-extension-context-selector .remote-plugin-extension-context-selector-header .instance-dropdown-container {
  flex: 1 1 100%;
  overflow: hidden;
}
.remote-plugin-extension-context-selector > [vx-view] {
  flex: 1 1 100%;
}
.remote-plugin-icon-navigation {
  width: 32px !important;
  height: 32px !important;
  transform: scale(0.5, 0.5) !important;
  transform-origin: 0 0 !important;
  margin-top: 1px !important;
  margin-right: calc(4px + -16px) !important;
  margin-bottom: calc(0px + -16px) !important;
  margin-left: 4px !important;
  max-width: none !important;
  max-height: none !important;
  vertical-align: text-bottom !important;
}
.remote-plugin-icon-menu {
  width: 32px !important;
  height: 32px !important;
  transform: scale(0.5, 0.5) !important;
  transform-origin: 0 0 !important;
  margin-top: -2px !important;
  margin-right: calc(4px + -16px) !important;
  margin-bottom: calc(0px + -16px) !important;
  margin-left: 0px !important;
}
.remote-plugin-icon-action {
  width: 32px !important;
  height: 32px !important;
  transform: scale(0.5, 0.5) !important;
  transform-origin: 0 0 !important;
  margin-top: -2px !important;
  margin-right: calc(4px + -16px) !important;
  margin-bottom: calc(0px + -16px) !important;
  margin-left: 0px !important;
}
html-bridge-plugin-modal-iframe {
  display: block;
  width: 100%;
  height: 100%;
}
.modal.sandbox-modal .modal-dialog {
  width: auto;
  height: auto;
}
.modal.sandbox-modal .modal-dialog .modal-content {
  margin: 0;
  padding: 0 0.125rem;
}
.modal.sandbox-modal .modal-dialog .modal-content .modal-header {
  box-sizing: border-box;
  margin: 0;
  padding: 1rem;
  min-height: 0;
  max-width: 100%;
}
.modal.sandbox-modal .modal-dialog .modal-content .modal-header .modal-title .modal-title-icon {
  flex: 0 0 auto;
}
.modal.sandbox-modal .modal-dialog .modal-content .modal-header .modal-title .modal-title-text {
  width: 100%;
  word-wrap: break-word;
  overflow-wrap: break-word;
  overflow: hidden;
}
.modal.sandbox-modal .modal-dialog .modal-content .modal-body {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  min-width: 0;
  max-width: 100%;
}
