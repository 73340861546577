// Flexbox Containers
//--------------------------------------------
// The 'flex' value causes an element to generate a block-level flex
// container box.
//
// The 'inline-flex' value causes an element to generate a inline-level
// flex container box.
//
// display: flex
//
// http://w3.org/tr/css3-flexbox/#flex-containers
//

.flexbox() {
  display: -webkit-flex; // Safari support, Chrome finally prefix-free
  display: -ms-flexbox;
  display: flex;
}

// Flexbox Direction
//--------------------------------------------
// The 'flex-direction' property specifies how flex items are placed in
// the flex container, by setting the direction of the flex container's
// main axis. This determines the direction that flex items are laid out in.
//
// Values: row | row-reverse | column | column-reverse
// Default: row
//
// http://w3.org/tr/css3-flexbox/#flex-direction-property

.flex-direction(@value) {
  -webkit-flex-direction: @value;
  -ms-flex-direction: @value;
  flex-direction: @value;
}

// Flexbox "Flex" (shorthand)
//--------------------------------------------
// The 'flex' property specifies the components of a flexible length: the
// flex grow factor and flex shrink factor, and the flex basis. When an
// element is a flex item, 'flex' is consulted instead of the main size
// property to determine the main size of the element. If an element is
// not a flex item, 'flex' has no effect.
//
// Values: none | <flex-grow> <flex-shrink> || <flex-basis>
// Default: See individual properties (1 1 0).
//
// 'none' keyword computer to 0 0 auto
// http://w3.org/tr/css3-flexbox/#flex-property

.flex(@flexGrow: 1, @flexShrink: 0, @flexBasis: 0) {
  -webkit-flex: @flexGrow @flexShrink @flexBasis;
  -ms-flex: @flexGrow @flexShrink @flexBasis;
  flex: @flexGrow @flexShrink @flexBasis;
}

// Flexbox Align Items
//--------------------------------------------
// Flex items can be aligned in the cross axis of the current line of the
// flex container, similar to 'justify-content' but in the perpendicular
// direction. 'align-items' sets the default alignment for all of the flex
// container's items, including anonymous flex items. 'align-self' allows
// this default alignment to be overridden for individual flex items. (For
// anonymous flex items, 'align-self' always matches the value of 'align-items'
// on their associated flex container.)
//
// Values: flex-start | flex-end | center | baseline | stretch
// Default: stretch
//
// http://w3.org/tr/css3-flexbox/#align-items-property

.align-items(@value: stretch) {
  -webkit-align-items: @value;
  -ms-flex-align: @value;
  align-items: @value;
}

// Flexbox Justify Content
//--------------------------------------------
// The 'justify-content' property aligns flex items along the main axis
// of the current line of the flex container. This is done after any flexible
// lengths and any auto margins have been resolved. Typically it helps distribute
// extra free space leftover when either all the flex items on a line are
// inflexible, or are flexible but have reached their maximum size. It also
// exerts some control over the alignment of items when they overflow the line.
//
// Values: flex-start | flex-end | center | space-between | space-around
// Default: flex-start
//
// http://w3.org/tr/css3-flexbox/#justify-content-property

.justify-content(@value: flex-start) {
  -webkit-justify-content: @value;
  -ms-flex-pack: @value;
  justify-content: @value;
}


// User select
// For enabling or blocking mouser selection of text on the page
// avoid allowing selection where mouse events are likely to be confused or cause artifacts by selecting large blocks
.user-select(@select) {
  -webkit-user-select: @select;
     -moz-user-select: @select;
      -ms-user-select: @select;
          user-select: @select;
}


.white-space-normal {
  white-space: normal;
}

.white-space-nowrap {
  white-space: nowrap;
}

.white-space-pre {
  white-space: pre;
}

.white-space-pre-line {
  white-space: pre-line;
}

.white-space-pre-wrap {
  white-space: pre-wrap;
}

.active-tab-btn (@bg-color, @color) {
  color: @color;
  background-color: @bg-color;
  cursor: not-allowed;
}

// chrome still requires prefixing
.animation(@arguments) {
  -webkit-animation: @arguments;
  -moz-animation: @arguments;
  animation: @arguments;
}

