@import "mixins";

.flex-column {
  .flexbox;
  .flex-direction(column);
}

.flex-row {
  .flexbox;
  .flex-direction(row);
}

.flex-auto {
  .flex(1, 1, auto);
}

.flex-100-percent{
  .flex(1, 1, 0);
}

.flex-none {
  .flex(0, 0, auto);
}

.flex-grow-auto {
  .flex(1, 0, auto);
}

.flex-shrink-auto {
  .flex(0, 1, auto);
}
/*
  Same as flex-grow-auto, but does not allow this element's children to overflow its size (in flex-direction only)
*/
.flex-basis-100-percent{
  .flex(1, 0, 0);
}

.center-pane-container {
  height: 100%;
}

/*
* margin and padding utility classes using x6 recommended by design
* use these when you need to tweak the layout of a component in context
*/
@padding-increment: 6px;

.padding-loop(@i) when(@i =< 1) {
  .pt@{i} {
    padding-top: @i * 1px;
  }
  .pb@{i} {
    padding-bottom: unit(@i, px);
  }
  .pr@{i} {
    padding-right: unit(@i, px);
  }
  .pl@{i} {
    padding-left: unit(@i, px);
  }
  .ph@{i} {
    padding-right: unit(@i, px);
    padding-left: unit(@i, px);
  }
  .pv@{i} {
    padding-top: unit(@i, px);
    padding-bottom: unit(@i, px);
  }
  .pa@{i} {
    padding: unit(@i, px);
  }

  .padding-loop(@i + @padding-increment);
}
.padding-loop(10);

/*
* margin and padding utility classes
* use these when you need to tweak the layout of a component in context
*/
@whitespace-small: 2px;
@whitespace-medium: 5px;
@whitespace-large: 10px;
.mts { margin-top: @whitespace-small; }
.mtm { margin-top: @whitespace-medium; }
.mtl { margin-top: @whitespace-large; }

.mrs { margin-right: @whitespace-small; }
.mrm { margin-right: @whitespace-medium; }
.mrl { margin-right: @whitespace-large; }

.mls { margin-left: @whitespace-small; }
.mlm { margin-left: @whitespace-medium; }
.mll { margin-left: @whitespace-large; }

.mbs { margin-bottom: @whitespace-small; }
.mbm { margin-bottom: @whitespace-medium; }
.mbl { margin-bottom: @whitespace-large; }

.mas { margin: @whitespace-small; }
.mam { margin: @whitespace-medium; }
.mal { margin: @whitespace-large; }

.mhs { margin: 0 @whitespace-small; }
.mhm { margin: 0 @whitespace-medium; }
.mhl { margin: 0 @whitespace-large; }

.mvs { margin: @whitespace-small 0; }
.mvm { margin: @whitespace-medium 0; }
.mvl { margin: @whitespace-large 0; }

.pts { padding-top: @whitespace-small; }
.ptm { padding-top: @whitespace-medium; }
.ptl { padding-top: @whitespace-large; }

.prs { padding-right: @whitespace-small; }
.prm { padding-right: @whitespace-medium; }
.prl { padding-right: @whitespace-large; }

.pls { padding-left: @whitespace-small; }
.plm { padding-left: @whitespace-medium; }
.pll { padding-left: @whitespace-large; }

.pbs { padding-bottom: @whitespace-small; }
.pbm { padding-bottom: @whitespace-medium; }
.pbl { padding-bottom: @whitespace-large; }

.pas { padding: @whitespace-small; }
.pam { padding: @whitespace-medium; }
.pal { padding: @whitespace-large; }

.phs { padding: 0 @whitespace-small; }
.phm { padding: 0 @whitespace-medium; }
.phl { padding: 0 @whitespace-large; }

.pvs { padding: @whitespace-small 0; }
.pvm { padding: @whitespace-medium 0; }
.pvl { padding: @whitespace-large 0; }

.main-nav {
  left: 0;
}
.app-frame-outer .app-frame-inner {
  margin: 0;
}





////////////////////////////////////////////////////////////////////////////
// flexbox related
// mixins and classes
//

// mixins
.flexbox-column() {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  flex: 1 1 100%;
  box-sizing: border-box;
}
.flexbox-row(@align-items: center) {
  display: flex;
  align-items: @align-items;
  box-sizing: border-box;
}
.flex-1() {
  flex: 1 1 100%;
  box-sizing: border-box;
}
.flex-fill-parent {
  flex: 1 1 auto;
}

// classes
.flexbox-column {
  .flexbox-column;

  // you're flexboxifying a
  // vui-splitter
  &[vui-splitter] {

    // you should flexboxify its
    // child element as well
    .k-splitter {
      .flexbox-column();
      height: auto !important;

      // remove its border
      // and background
      &.k-widget {
        border: none;
        background-color: transparent;
      }
    }
  }
}
.flexbox-row {
  .flexbox-row;
}

.flex-1 {
  .flex-1;
}
.overflow-auto {
  overflow: auto;
}

.skip-links-container {
  position: absolute;
  left: 2px;
  right: 0;
  z-index: 1500;
}

.skip-link {
  position: absolute;
  overflow: hidden;
  height: 1px;
}
.skip-link:active, .skip-link:focus {
    border: 1px solid #d3d3d3;
    background: #fff;
    position: static;
    overflow: visible;
    height: auto;
    padding-left: 5px;
    padding-right: 5px;
}
/////////////////////////////////////////////////////////////////////////////////

// center-pane
// center-pane-with-a-title

#center-pane {
  .center-pane-container.center-pane-with-a-title {
    .flexbox-column;
    overflow: hidden;

    .titlebar {
      .flexbox-row;
      padding: 0 0 0 20px;
      height: 60px;
      .titlebar-text {
        font-size: 22px;
        font-weight: 200;
      }
    }

    & > .titlebar + .panel-inner {
      .flex-1;
      overflow: hidden;
      margin: 0;

      .tabbed-object-view {
        box-sizing: border-box;
        display: block;
        height: 100%;

        .flexbox-column;
        .outer-tab-content {
          position: relative;
          .flexbox-column;
          > .fill-parent {
            .flexbox-column;
            > .fill-parent {
              .flexbox-column;
              > .fill-parent {
                .flexbox-column;
                > .fill-parent.vertical-flex-container {
                  .flexbox-column;
                }
              }
            }
          }
        }
      }

      .outer-tab-content-no-title {
        box-sizing: border-box;
        height: 100%;
        margin: 0;
        padding: 0 20px 10px 20px;
      }
    }
  }
}
#center-pane {
  .center-pane-container.center-pane-with-a-title {
    .outer-tab-content-no-title {
      top: auto;
      height: auto !important;
    }
  }
}

.simple-search {
  .flexbox-column;
  overflow: hidden;

  .search-form-panel-wrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  .simple-search-header {
    display: flex;
    padding-top: 20px;
    height: 40px;
    font-weight: 200;
    font-size: 22px;
    background: #fafafa;
    margin-left: 10px;
  }

  .search-header {
   display: flex;
   flex-direction: column;
   margin: 12px 36px 12px 36px;
   background: #fafafa;
   font-weight: 200;
   min-height: min-content;

      clr-alert {
         .alert {
            margin-top: 0px;
            margin-bottom: 12px;
         }
      }

      .saved-search-bar {
         width: 100%;
         font-size: 14px;
         align-self: flex-start;
      }

      .search-title-bar {
         display: flex;
         flex-direction: row;
         width: 100%;
         align-items: center;
         min-height: min-content;

         .search-title {
            font-size: 22px;
            flex-grow: 1;
         }
      }
  }

  .search-form-panel {
    padding: 0;
    margin: 0;
    max-height: none !important;
    box-sizing: border-box;
    height: auto;
    form {
      padding: 0;
      .search-results-header {
        text-align: right;
      }
    }
    form,
    form .search-input-container {
      display: flex;
      align-items: baseline;
    }
  }

  .search-results-panel {
    box-sizing: border-box;
    overflow: hidden;
    flex: 1 1 100%;
    height: 100%;
    padding: 0;
    margin: 0;

    .datagrid-container {
       margin-left: 36px;
       margin-right: 36px;
    }

    > div[vx-view-tabs] {
      height: 100%;
      box-sizing: border-box;
      display: block;
      position: relative;
      overflow: hidden;
      > .fill-parent {
        .flexbox-column;
        overflow: hidden;
        padding: 10px;

        .nav.nav-tabs {
          height: 32px !important;
          display: flex !important;
          background: none !important;
          box-shadow: none !important;
          > li {
            margin-bottom: 0;
          }
        }

        .tab-content {
          .flex-1;
          overflow: hidden;
          padding: 10px 0 0 0;
        }
      }
    }

    // search results container for simple and advanced search
    .search-results-panel-header {
      display: flex;
      align-items: center;
      box-sizing: border-box;
      padding: 0 0 0 20px;
      height: 60px;
      background: ghostwhite;

      .search-results-panel-header-text {
        font-size: 22px;
        font-weight: 200;
      }
    }
  }
}



LEFT-PANEL .vui-panel OBJECT-NAVIGATOR .panel-inner .panel-content {
  padding-top: 0 !important;
}

.simple-search-left-pane {
  .nav-bottom-block.panel-content.flex-column.fill-parent {
    box-sizing: border-box;
    overflow: hidden;
    .object-navigator-list {
      .flex-1;
      height: auto;
      overflow: auto;
    }
  }
}

#MainTemplateController {
  display: flex;
  height: 100vh;
  flex-direction: column;
  overflow: hidden;

  .app-header {
    display: flex;
    flex: 0 0 auto;

    .main-nav {
      width: 100vw;
    }
  }

  .app-frame-outer {
    box-sizing: border-box;
    width: 100vw;
    flex: 1 1 auto;
    height: calc(~'100% - 95px');
  }
}

.clr-icon.clr-vmw-logo {
  background-size: 37px 32px;
}
