.alarm-details-popup {
   td {
      vertical-align: top;
      padding-top: 5px;
   }

   table td:first-child {
      width: 120px;
      font-weight: bold;
   }

   .actions {
      text-align: right;

      button {
         margin-left: 5px;
         margin-top: 10px;
      }
   }
}