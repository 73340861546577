@import "focus";

.user-tabbing {
  bottom-panel {
    #bottom-panel {
      /* Recent Tasks and Alarms tabs */
      vsc-recent-tasks-and-alarms-view {
        clr-tabs {
          button:focus {
            outline: @outline-focus;
            outline: @outline-webkit-focus;
          }
        }
      }
    }
    /* More tasks link */
    .more-tasks:focus {
      outline: @outline-focus;
      outline: @outline-webkit-focus;
    }
  }
}

bottom-panel {

  #bottom-panel {
    height: 100%;
    vsc-recent-tasks-and-alarms-view {
      height: 100%;
      clr-tabs {
        position: relative;
        height: 100%;
        display: flex;
        flex-direction: column;
        button:focus {
          outline:0;
        }
        ul.nav {
          flex-shrink: 0;
          z-index: 200;
        }

        clr-tab.activeTab {
          flex: 1 1 auto;
          position: relative;
          clr-tab-content {
            position: absolute;
            width: 100%;
            height: 100%;
          }
        }

        // show only the clr-tab-content that is active
        clr-tab:not(.activeTab) {
          display: none;
        }

      }

    }
    .bottom-panel-tab-view {
      position: absolute;
      width: 100%;
      height: 100%;
    }
  }

  // task-status-select and more-tasks links
  // are position-absolute to appear above
  // the footer of the k-grid
  .task-status-select {
    position: absolute;
    bottom: 4px;
    left: 5px;
    z-index: 1;
  }
  .more-tasks {
    position: absolute;
    bottom: 8px;
    right: 17px;
    font-size: 11px;
    z-index: 1;
  }
  .bottom-panel-tab-view {
    .k-pager-info.k-label {
      line-height: 23px;
    }
  }

  // More Tasks links overlaps with
  // the k-grid pager-info
  // currently we're hiding the pager-info
  recent-tasks-view {
    .k-pager-info.k-label {
      display: none;
    }

    .vui-datagrid.k-grid.k-widget {
      .k-pager-wrap.k-grid-pager {
        height: 30px;
        line-height: 18px;

        vui-datagrid-footer-bar {
          float: none;
          height: 30px;
        }
      }
    }
  }

  // a tricky bit here is that toggle-splitter is position absolute
  // and it's supposed to appear as if it is below the clr-tab-links...
  toggle-splitter {
    box-sizing: border-box !important;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    height: 36px;
    position: absolute;
    right: 0;
    top: 0;
    background: white;
    cursor: pointer;
    overflow: hidden;
    z-index: 2;
    &:hover {
      svg {
        .cls-1{
          fill:#24b3fa;
        }
      }
    }
    svg {
      width: 12px;
      height: 12px;
      margin-right: 10px;
      -webkit-transition: all 300ms ease-in 0s;
      -moz-transition: all 300ms ease-in 0s;
      -o-transition: all 300ms ease-in 0s;
      transition: all 300ms ease-in 0s;
      display: inline-flex !important;
      overflow: hidden;
    }
  }

  // ...so the clr-tab-links should get some z-index
  // to show above the toggle-splitter
  clr-tab-link {
    z-index: 1;

    // preparation for badges
    .nav-link {
      display: inline-flex !important;
      align-items: center;
      .badge {
        margin-left: 7px;
        margin-top: 2px;
        height: 18px;
        font-weight: 400;
      }
    }
  }

  // when the bottomPanel is collapsed
  &.k-state-collapsed,
  &.collapsed {

    // the clr-tabs nav links
    // should not have their box-shadow
    clr-tabs {
      .nav-link {
        box-shadow: none !important;
      }
    }

    // toggle-splitter icon rotates
    toggle-splitter {
      svg {
        -webkit-transform: rotate(180deg);
        -moz-transform: rotate(180deg);
        -o-transform: rotate(180deg);
        -ms-transform: rotate(180deg);
        transform: rotate(180deg);
      }
    }
  }

  // couple of things that can be
  // fixed inside clr-tabs
  clr-tabs {

    // offset the nav a bit from the left edge
    // align it with the grid content
    & > .nav {
      padding-left: 10px;
    }

    // the section is the element wrapping the
    // vx-stretch-grid element it should be position:absolute
    // so that k-grid's size calculations will work
    clr-tab-content section {
      position: absolute;
      width: 100%;
      height: 100%;
      overflow: hidden;
    }
  }
}

////////////////////////////////////////////////////////////
// dealing with scroll
// TODO move this to a separate-file.less
/////////////////////////////////////////////////////////////

// fixing the box-sizing of the style of the splitter state when
// you dblclick on it to collapse it
.k-splitter {
  box-sizing: border-box;
  position: relative;
  overflow: hidden;
}
// removing the horizontal scroll inside k-grid when there's no content
.k-grid-content-expander {
  margin-left: 1px;
}
// the scroll that is in the outer tab content
// switching it from scroll to auto
#center-pane .outer-tab-content {
  overflow-y: auto !important;
}

// .k-grid-pager has 2px border-top-width
// overriding it to 1px
.k-grid .k-grid-pager {
   border-top-width: 1px !important;
}

.app-frame-outer .app-frame-inner {
  margin: 0 !important;
}

// hide the k-splitbar collapse/expand icons
.k-splitbar .k-icon {
  display: none;
}
