/**
 * Styles for the object link with icon
 */

.vui-stack-view .stack-view-table .stack-view-row .stack-view-cell .object-link [class*=icon] {
   position: static;
   margin: 0px 4px 0px 0px;
}

.vui-stack-view .stack-view-table .stack-view-row .stack-view-cell .object-link:first-child {
   margin-top: 0px;
}

.vui-stack-view .stack-view-table .stack-view-row .stack-view-cell .object-link {
   margin-top: 5px;
}