@import 'mixins';

storage-locator storage-profile {
  display: inline-block;
  padding-bottom: 8px;

  div.select {
    min-width: 180px;
  }
}

storage-locator disk-format-selector {
  display: inline-block;
  padding-bottom: 8px;
  min-width: 180px;
}

storage-locator {
  .settings-label {
    padding-right: 10px;
  }

  .storage-locator-settings-table {
    width: 100%;
    table-layout: fixed;

    tr {
      vertical-align: top;
    }

    a.tooltip {
      align-self: center;
      margin-left: 5px;
      opacity: 1;
    }
  }

  .replication-group-container {
    .flexbox();
    .flex-direction(row);
    .align-items(center);

    select {
      min-width: 220px;
    }

    padding-top: 8px;
  }
}

.storage-selector-header {
  .toggle-switch {
      margin-right: 0px;
  }
}

.storage-selector-header-pmem-info {
  padding-top: 1px;
  margin-left: 5px;
  margin-top: -6px;
}

.storage-selector-dialog {
  height: 400px;
}

.encryption-options {
  padding-left: 0px !important;
}
