.text-overflow-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.selected-header-item {
  background: #e7e7e7;
  border-top: 1px solid #b8babb;
  border-bottom: 1px solid #b8babb;
  height: 24px;
  line-height: 24px;
  padding: 0 8px;
}

left-panel {

  // object-navigator-counter
  span.object-navigator-counter {
    box-sizing: border-box;
    position: relative;
    right: auto;
    top: auto;
    border-radius: 3px;
    padding: 0px 4px;
    margin: 0 1px 0 0;
    line-height: 20px;
    background: lighten(@clr-selected-highlight-color, 30%);
    width: auto;
    min-width: 24px;
    flex-shrink: 0;
  }

  object-navigator {

    // headers
    .nav-top-block.obj-nav-counter, // all elements
    .nav-top-pane-relations-mode.primary-list-item, // relations : top
    .primary-list-item { // relations : bottom
      .selected-header-item;
    }
    .primary-list-item {
      padding: 0;
    }
    .nav-top-block.obj-nav-counter {
      padding: 0 3px 0 10px;
    }

    // obj-nav-counter
    .obj-nav-counter {
      display: flex;
      align-items: center;

      .obj-nav-counter-icon {
        display: flex;
        margin-left: 3px;
      }
      .obj-nav-counter-label {
        .text-overflow-ellipsis;
        flex: 1 1 auto;
      }
    }

    // flexboxify the label row
    // ellipsis for the label
    // reduce the vui padding of TR TD
    // cursor: pointer for the arrow
    div[vui-datagrid] {
      .k-grid tbody tr td > div {
        display: flex;
        align-items: center;
        .object-navigator-label {
          .text-overflow-ellipsis;
          flex: 1 1 auto;
        }
        .vui-icon-object-nav-pan {
          cursor: pointer;
        }
      }
      .k-grid.k-widget .k-grid-content tbody tr td {
        padding-left: 2px;
        padding-right: 0;
      }
    }

    // relations : top
    // adjsut paddings and flexboxify list items
    .object-navigator-list {
      li
        a.object, div.object {
        padding: 2px;
        display: flex;
        span[ng-class="node.icon"] {
          flex-shrink: 0;
          margin: 1px 4px 0;
          min-width: 16px;
        }

        .object-navigator-label {
          flex: 1 1 100%;
          margin-right: 17px;
        }

        .object-navigator-label-search {
           flex: 1 1 100%;
           margin-right: 17px;
           margin-left: 20px;
        }
      }
    }
  }
}
.object-navigator-label {
  padding-right: 3px;
}

// adding overflow:hidden to hide scrolls that appear while loading
LEFT-PANEL .vui-panel OBJECT-NAVIGATOR .panel-inner div.panel-content {
  &.nav-bottom-block.objNavSeparator {
    overflow: hidden;
  }
}
.fill-parent.non-scrollable {
  overflow: hidden;
}

// moving grids down
// to show non-grid headers
// - - - - - - - - - - - - - - - - - -

// all elements
LEFT-PANEL .vui-panel OBJECT-NAVIGATOR .panel-inner div.panel-content {
  &.nav-bottom-block.objNavSeparator {
    top: 26px;
  }
}

// relations : bottom
left-panel object-navigator {
  .rel-items-splitter-bottom {
    .object-navigator-title ~ .panel-content.nav-bottom-block {
      top: 26px;
    }
  }
}