search-center-pane {
   margin-left: -12px;

   .search-center-pane {
      display: flex;
      flex-direction: column;
      height: 100%;

      vsc-search-center-pane {
         display: flex;
      }
   }
}
