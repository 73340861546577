div[vx-object-selector] {
  .flexbox-column;
  height: 100%;

  // override the height of the
  // nav-tabs from 24 to 36
  .nav.nav-tabs {
    height: 36px;
  }

  // flexbox-ify vui-primary tabs
  // flex-1 vui-tab-content
  .vui-tabs-container {
    .flexbox-column;

    .vui-primary-tabs {
      .flexbox-column;
      min-height: initial;

      .vui-secondary-tabs {
        .flexbox-column;
        padding: 0;
        min-height: initial;
        height: 100%;
      }

      .vui-tab-content {
        .flex-1;
        height: 100%;

        > div[ng-include] {
          height: 100%;
          width: 100%;
          position: absolute;
        }

        // overriding
        // - position
        // - top
        // - padding
        // set in scheduler.less
        // TODO remove/fix this in scheduler
        // or make it more specific
        position: relative;
        top: auto;
        padding: 0;

      }
    }
  }


  // get rid of the right-border of the 1st TH
  // when there's a checkbox inside it
  list-view[selection-mode="multi"],
  #selected-objects {
    thead th:first-child {
      border-right-style: none !important;
    }
  }
}


/////////////////////////////////////////////////////
//
// pills
//
/////////////////////////////////////////////////////

div[vx-object-selector] {
  // secondary level tabs
  .vui-secondary-tabs {
    padding: 8px 0;
    display:flex; // keep

    .btn-group {
        align-self: flex-start;
        height: 26px;
        flex-shrink: 0;
        z-index: 3;
        .btn {
            height: 24px !important;
            line-height: 24px !important;
        }
    }

    .btn-group {
      @tab2-highlight-color: @clr-actionblue;
      @tab2-shape-active-background: @clr-actionblue;

      @tab2-shape-default-background: @clr-white;
      @tab2-shape-default-border: @clr-actionblue;
      @tab2-text-default-foreground: @clr-actionblue;

      @tab2-text-hover-foreground: extract(@clr-actionblue-list, 6);
      @tab2-shape-hover-background: extract(@clr-actionblue-list, 1);
      @tab2-highlight-active-border: extract(@clr-actionblue-list, 2); // click on tab

      @tab2-shape-hover-border: @clr-actionblue;
      @tab2-text-active-foreground: @clr-white;

      @tab2-layout-gutter: 1px;

      background-color: @tab2-shape-default-border;
      padding: 1px; // inset
      border-radius: 3px;
      display: flex;
      overflow-x:hidden; // crop label; do

      > button:not(:last-child),
      > button.active:not(:last-child),
      > button:last-child {
        border-color: @tab2-highlight-color;
      }

      // Known use: as "pills" on the "networks" tab of Host objects
      > .btn {
        text-transform: none;
        margin: 0;
        background-color: @tab2-shape-default-background;
        color: @tab2-text-default-foreground;
        border: 0;

        cursor: pointer;

        padding: 0 15px;
        letter-spacing: 0.1px;
        font-size: 13px;
        font-weight: 400;

        // logical states
        // edge elements
        &:not(:first-child) {
          margin-left: @tab2-layout-gutter/2;
        }
        &:not(:last-child) {
          margin-right: @tab2-layout-gutter/2;
        }



        // interactions

        // softened inset shadow
        &.active {
          color: @tab2-text-active-foreground;
          background-color: @tab2-shape-active-background;
          cursor: not-allowed;
        }

        &:not(.active) {

          &:active {
            outline: none;
            // inset effect shading
            box-shadow: inset 0 1px 0 @tab2-highlight-active-border;
          }

          &:hover {
            color: @tab2-text-hover-foreground;
            background-color: @tab2-shape-hover-background;
          }
        }

      }
    }
  }
}

div[vx-tab-headers] {
    .btn-group {
        display: inline-flex !important;
    }
    .btn-group {
        align-self: flex-start;
        height: 24px;
        .btn {
            height: 24px !important;
            line-height: 24px !important;
        }
    }

    .btn-group {
      @tab2-highlight-color: @clr-actionblue;
      @tab2-shape-active-background: @clr-actionblue;

      @tab2-shape-default-background: @clr-white;
      @tab2-shape-default-border: @clr-actionblue;
      @tab2-text-default-foreground: @clr-actionblue;

      @tab2-text-hover-foreground: extract(@clr-actionblue-list, 6);
      @tab2-shape-hover-background: extract(@clr-actionblue-list, 1);
      @tab2-highlight-active-border: extract(@clr-actionblue-list, 2); // click on tab

      @tab2-shape-hover-border: @clr-actionblue;
      @tab2-text-active-foreground: @clr-white;

      @tab2-layout-gutter: 1px;

      background-color: @tab2-shape-default-border;
      padding: 1px; // inset
      border-radius: 3px;
      display: flex;
      overflow-x:hidden; // crop label; do

      > button:not(:last-child),
      > button.active:not(:last-child),
      > button:last-child {
        border-color: @tab2-highlight-color;
      }

      // Known use: as "pills" on the "networks" tab of Host objects
      > .btn {
        text-transform: none;
        margin: 0;
        background-color: @tab2-shape-default-background;
        color: @tab2-text-default-foreground;
        border: 0;

        cursor: pointer;

        padding: 0 15px;
        letter-spacing: 0.1px;
        font-size: 13px;
        font-weight: 400;

        // logical states
        // edge elements
        &:not(:first-child) {
          margin-left: @tab2-layout-gutter/2;
        }
        &:not(:last-child) {
          margin-right: @tab2-layout-gutter/2;
        }

        // interactions

        // softened inset shadow
        &.active {
          color: @tab2-text-active-foreground;
          background-color: @tab2-shape-active-background;
          cursor: not-allowed;
        }

        &:not(.active) {

          &:active {
            outline: none;
            // inset effect shading
            box-shadow: inset 0 1px 0 @tab2-highlight-active-border;
          }

          &:hover {
            color: @tab2-text-hover-foreground;
            background-color: @tab2-shape-hover-background;
          }
        }

      }
    }
}


/*TODO Update selectComputeResourcePage.html with a classname
       and replace this selector div[ng-controller*="SelectComputeResourcePageController"]*/
div[ng-controller*="SelectComputeResourcePageController"],
div[ng-controller*="SelectComputeResourcePageController"] > div[vx-object-selector],
div[ng-controller*="SelectComputeResourcePageController"] > div[vx-object-selector] > .vui-tabs-container {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    overflow: hidden;
}


.vui-secondary-tabs .btn-group.pills .btn {
  margin-right: 0;
  border-left: none;
  &.btn-sm {
    margin: 0;
    font-weight: 400;
    text-transform: none;
    font-size: 13px;
    line-height: 26px;
    height: 26px;
    flex-shrink: 0;
  }
  &.active {
    .active-tab-btn(@clr-actionblue, @clr-white);
  }
}
