@import "mixins";

/**
 * The Main Less file for the App Lib CSS
 *
 */
@unity-primary: #4679b9;

// sandbox.less
.sandbox-iframe {
   border: 0px;
   height: 100%;
   width: 100%;
   box-sizing: border-box;
   display: block;
}

.sandbox-iframe-dialog {
   border: 0px;
   height: 100%;
   width: 100%;
   position: absolute;
   top: 28px;
   bottom: 0;
   right: 0;
   left: 0;
}

/** TODO ajaffer: temp. fix to ask kendo grid to show ellipsis
This should be fixed in vui **/
.k-grid td {
   * {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
   }
   /* Override for columns that show wrapped text (IP-addresses, Notes) */
   // TODO move to generic.less
   .line-wrap {
      white-space: normal;
   }
}

div.k-animation-container #cols_menu {
   display: none;
}

.vertical-flex-container {
   .flexbox();
   .flex-direction(column);
}

.sub-tab-content {
   margin-top: 10px;
   // PhantomJS will render the tabs with 0 height unless "height: 100%" is here
   height: 100%;
   .flex(1, 1, 100%);
   position: relative;
   overflow: hidden;
   > .content {
      position: absolute;
   }
}

.vx-multi-extension-view > :not(:last-child) {
   margin-bottom: 10px;
}

// For the tabs in the InventoryTreeView. This should ideally be in VUI but for some reasons folks are not happy about putting it there.
.vui-primary-tabs {
   overflow: hidden;
}

// Adding class for display text under progress bar directive. Should be ideally in vui.
.progress-text {
   padding-top: 5px;
   font-weight: bold;
}

.progress-bordered {
   border: solid 1px black;
   border-color: lightgray;
   width: 200px;
   padding: 5px 5px 10px 5px;
}

.progress-centered {
   position: absolute;
   top: 50%;
   right: 50%;
   margin-right: -100px;
   margin-top: -50px;
   background: white;
}


a[disabled] {
   pointer-events: none;
   opacity: .5;
}

// is being used by a component which is not super trivial to fix
.disabled-link {
   pointer-events: none;
   cursor: default;
   color: #9A9A9A !important;
}

.non-navigatable-link {
   pointer-events: none;
   cursor: default;
   color: inherit !important;
   &:hover {
      text-decoration: none;
   }
}

.results-footer-searching-text-black {
   padding-left: 5px;
   padding-bottom: 3px;
   padding-top: 3px;
}

.results-footer-searching-text-blue {
   padding-left: 5px;
   padding-bottom: 3px;
   padding-top: 3px;
   border-top: 1px solid #D2D3D4;
}

.results-footer-searching-text-blue .all-results-link {
   color: #369 !important;
}

.vertical-aligned-text {
   vertical-align: middle;
}

.disabled-text {
   font-style: italic;
   color: gray;
}

.numeric-text {
   vertical-align: middle;
   text-align: right;
   width: 100%;
   display: inline-block;
}

.no-items-selected-container {
   .flexbox();
   .align-items(center);
   .justify-content(center);
   width: 100%;
   height: 100%;
}

.no-items-selected-text {
   font-size: 16px;
   font-weight: bold;
   color: #A0A0A0
}

.vx-master-details-view .master-details-details-container {
   width: 100%;
   height: calc(~"100% - 1px");
}

.vx-master-details-view .nav.nav-tabs {
   margin-top: 0px;
}

// This style is reponsible for making the nav-tabs overflow similar to Clarity
.vx-master-details-view vx-tabbed-view, .tabbed-object-view {
   ul.nav-tabs {
      > li {
         flex: 0 1 auto;
         overflow-x: auto;
         overflow-y: hidden;

         > a {
            text-overflow: ellipsis;
            overflow: hidden;
            display: inline-block;
            white-space: nowrap;
            padding-left: 0px;
            padding-right: 0px;
            width: 100%;
         }
      }
   }
}

.vx-master-details-view{
   min-height: 450px;/*makes view usable for super-small screen heights*/
}

.vx-master-details-hide-splitter .k-splitbar {
  display: none;
}

// For images pulled from the sprite
[class^="vx-icon-"],
[class*=" vx-icon-"] {
   display: inline-block;
   vertical-align: text-bottom;
   margin: 1px 4px 0;
}

[class^="vsphere-icon-"],
[class*=" vsphere-icon-"] {
   display: inline-block;
   vertical-align: text-bottom;
   //vertical-align: middle;
   margin: 1px 4px 0;
}

.word-wrap {
   word-wrap: break-word;
}

p:first-child {
   display: inline-block;
}

.relative-container {
   position: relative;
}

.absolute-container {
   position: absolute;
}

.centered-text-block {
   height: 100%;
   box-sizing: border-box;
   display: flex;
   justify-content: center;
   align-items: center;
   text-align: center;
   padding: 10%;
}

.object-not-found {
   background: #ececec;
}

input[type=number].spinfree::-webkit-inner-spin-button,
input[type=number].spinfree::-webkit-outer-spin-button {
   -webkit-appearance: none;
   -moz-appearance: none;
   appearance: none;
   margin: 0;
}

input[type=number].spinfree {
   -moz-appearance: textfield;
}

clr-stack-block.warning .stack-block-label {
   background-color: #fbd6d6;
}

clr-tooltip.tooltip {
   opacity: 1;
}

// When open nested clarity dialog from vui-dialog change the
// z-index so the clarity dialog to be visible
body div.modal-backdrop + clr-modal {
   > div.modal {
      z-index: 1060;
   }
}

// Components
@import "kss.less";
@import "colors.less";
@import "focus.less";
@import "actionBar.less";
@import "actionMenu.less";
@import "alarms.less";
@import "charts.less";
@import "columnMeter.less";
@import "controlBar.less";
@import "controlCenter.less";
@import "datagrids.less";
@import "dialogs.less";
@import "events.less";
@import "feedback.less";
@import "splitView.less";
@import "forms.less";
@import "generic.less";
@import "objectLink.less";
@import "layout.less";
@import "listView.less";
@import "multiLevelTertiaryTabs.less";
@import "objectSelector.less";
@import "virtualObjectNavigator.less";
@import "bottomPanel.less";
@import "resourceMeter.less";
@import "settingsBlock.less";
@import "stackView.less";
@import "summaryView.less";
@import "table.less";
@import "tabs.less";
@import "tooltip.less";
@import "navigator.less";
@import "icons.less";
@import "portlets.less";
@import "vsphere-sprite.less";
@import "change-password.less";
@import "linkedText.less";
@import "settingsViewHeader.less";
@import "treeList.less";
@import "permissions.less";
@import "storageLocator.less";
@import "vxActivityIndicator.less";
@import "vxStaticProgressIndicator.less";
@import "modal.less";
@import "spacers.less";
@import "propertyView.less";
@import "shares.less";
@import "categoryObjectNavigator.less";
@import "exportList";
@import "recent-tasks-notification";
@import "search.less";
@import "plugins";
