.vsphere-icon-Add_Host {
	background: url("/ui/resources/img/svg-sprite.svg") 87.8871473354232% 16.39344262295082% no-repeat;
	width: 18px;
	height: 18px;
}

.vsphere-icon-Host_Connect {
	background: url("/ui/resources/img/svg-sprite.svg") 67.7115987460815% 82% no-repeat;
	width: 18px;
	height: 18px;
}

.vsphere-icon-Host_Disconnect {
	background: url("/ui/resources/img/svg-sprite.svg") 78.99686520376176% 64.29508196721312% no-repeat;
	width: 18px;
	height: 18px;
}

.vsphere-icon-Host_Maintenance_Mode_Enter {
	background: url("/ui/resources/img/svg-sprite.svg") 33.85579937304075% 70.19672131147541% no-repeat;
	width: 18px;
	height: 18px;
}

.vsphere-icon-Host_Maintenance_Mode_Exit {
	background: url("/ui/resources/img/svg-sprite.svg") 33.85579937304075% 76.09836065573771% no-repeat;
	width: 18px;
	height: 18px;
}

.vsphere-icon-Host_Policy {
	background: url("/ui/resources/img/svg-sprite.svg") 78.99686520376176% 76.09836065573771% no-repeat;
	width: 18px;
	height: 18px;
}

.vsphere-icon-Host_Reboot {
	background: url("/ui/resources/img/svg-sprite.svg") 48.90282131661442% 23.278688524590162% no-repeat;
	width: 18px;
	height: 18px;
}

.vsphere-icon-LAG {
	background: url("/ui/resources/img/svg-sprite.svg") 11.285266457680251% 64.29508196721312% no-repeat;
	width: 18px;
	height: 18px;
}

.vsphere-icon-Lic_key_add {
	background: url("/ui/resources/img/svg-sprite.svg") 85.51839464882943% 52.63157894736842% no-repeat;
	width: 38px;
	height: 38px;
}

.vsphere-icon-Lic_key_existing {
	background: url("/ui/resources/img/svg-sprite.svg") 72.80936454849498% 52.63157894736842% no-repeat;
	width: 38px;
	height: 38px;
}

.vsphere-icon-Lic_key_inactive {
	background: url("/ui/resources/img/svg-sprite.svg") 30.76923076923077% 24.912280701754387% no-repeat;
	width: 38px;
	height: 38px;
}

.vsphere-icon-Lic_key_remove {
	background: url("/ui/resources/img/svg-sprite.svg") 76.82274247491638% 35.08771929824562% no-repeat;
	width: 38px;
	height: 38px;
}

.vsphere-icon-Lic_key_upgraded {
	background: url("/ui/resources/img/svg-sprite.svg") 60.10033444816054% 52.63157894736842% no-repeat;
	width: 38px;
	height: 38px;
}

.vsphere-icon-NIC_blue {
	background: url("/ui/resources/img/svg-sprite.svg") 84.63949843260188% 76.09836065573771% no-repeat;
	width: 18px;
	height: 18px;
}

.vsphere-icon-NIC_single {
	background: url("/ui/resources/img/svg-sprite.svg") 5.6426332288401255% 82% no-repeat;
	width: 18px;
	height: 18px;
}

.vsphere-icon-NICs {
	background: url("/ui/resources/img/svg-sprite.svg") 16.927899686520377% 82% no-repeat;
	width: 18px;
	height: 18px;
}

.vsphere-icon-NiC_green {
	background: url("/ui/resources/img/svg-sprite.svg") 16.927899686520377% 87.9016393442623% no-repeat;
	width: 18px;
	height: 18px;
}

.vsphere-icon-action-remove {
	background: url("/ui/resources/img/svg-sprite.svg") 5.6426332288401255% 93.8032786885246% no-repeat;
	width: 18px;
	height: 18px;
}

.vsphere-icon-add {
	background: url("/ui/resources/img/svg-sprite.svg") 87.8871473354232% 22.295081967213115% no-repeat;
	width: 18px;
	height: 18px;
}

.vsphere-icon-app-defense {
	background: url("/ui/resources/img/svg-sprite.svg") 0 35.876705406771094% no-repeat;
	width: 92px;
	height: 125.1px;
}

.vsphere-icon-assign_license {
	background: url("/ui/resources/img/svg-sprite.svg") 45.141065830721004% 44.26229508196721% no-repeat;
	width: 18px;
	height: 18px;
}

.vsphere-icon-caret_collapsed {
	background: url("/ui/resources/img/svg-sprite.svg") 28.84012539184953% 52.78688524590164% no-repeat;
	width: 18px;
	height: 18px;
}

.vsphere-icon-caret_expanded {
	background: url("/ui/resources/img/svg-sprite.svg") 34.48275862068966% 52.78688524590164% no-repeat;
	width: 18px;
	height: 18px;
}

.vsphere-icon-cloud-network {
	background: url("/ui/resources/img/svg-sprite.svg") 40.125391849529784% 52.78688524590164% no-repeat;
	width: 18px;
	height: 18px;
}

.vsphere-icon-cloud-network-alert {
	background: url("/ui/resources/img/svg-sprite.svg") 45.76802507836991% 52.78688524590164% no-repeat;
	width: 18px;
	height: 18px;
}

.vsphere-icon-cloud-network-warning {
	background: url("/ui/resources/img/svg-sprite.svg") 0 64.29508196721312% no-repeat;
	width: 18px;
	height: 18px;
}

.vsphere-icon-cluster {
	background: url("/ui/resources/img/svg-sprite.svg") 5.6426332288401255% 64.29508196721312% no-repeat;
	width: 18px;
	height: 18px;
}

.vsphere-icon-cluster-24x {
	background: url("/ui/resources/img/svg-sprite.svg") 37.942122186495176% 36.7003367003367% no-repeat;
	width: 26px;
	height: 26px;
}

.vsphere-icon-cluster-error {
	background: url("/ui/resources/img/svg-sprite.svg") 16.927899686520377% 64.29508196721312% no-repeat;
	width: 18px;
	height: 18px;
}

.vsphere-icon-cluster-warning {
	background: url("/ui/resources/img/svg-sprite.svg") 22.570532915360502% 64.29508196721312% no-repeat;
	width: 18px;
	height: 18px;
}

.vsphere-icon-collapse {
	background: url("/ui/resources/img/svg-sprite.svg") 81.61585365853658% 40.12738853503185% no-repeat;
	width: 9px;
	height: 9px;
}

.vsphere-icon-content-library {
	background: url("/ui/resources/img/svg-sprite.svg") 33.85579937304075% 64.29508196721312% no-repeat;
	width: 18px;
	height: 18px;
}

.vsphere-icon-content-library-new-subscription {
	background: url("/ui/resources/img/svg-sprite.svg") 39.49843260188088% 64.29508196721312% no-repeat;
	width: 18px;
	height: 18px;
}

.vsphere-icon-content-library-publish {
	background: url("/ui/resources/img/svg-sprite.svg") 45.141065830721004% 64.29508196721312% no-repeat;
	width: 18px;
	height: 18px;
}

.vsphere-icon-cpu-summary {
	background: url("/ui/resources/img/svg-sprite.svg") 41.80064308681672% 23.905723905723907% no-repeat;
	width: 26px;
	height: 26px;
}

.vsphere-icon-datacenter {
	background: url("/ui/resources/img/svg-sprite.svg") 56.426332288401255% 64.29508196721312% no-repeat;
	width: 18px;
	height: 18px;
}

.vsphere-icon-datacenter-error {
	background: url("/ui/resources/img/svg-sprite.svg") 62.06896551724138% 64.29508196721312% no-repeat;
	width: 18px;
	height: 18px;
}

.vsphere-icon-datacenter-warning {
	background: url("/ui/resources/img/svg-sprite.svg") 67.7115987460815% 64.29508196721312% no-repeat;
	width: 18px;
	height: 18px;
}

.vsphere-icon-datastore {
	background: url("/ui/resources/img/svg-sprite.svg") 73.35423197492163% 64.29508196721312% no-repeat;
	width: 18px;
	height: 18px;
}

.vsphere-icon-datastore-24x {
	background: url("/ui/resources/img/svg-sprite.svg") 86.07717041800643% 33.67003367003367% no-repeat;
	width: 26px;
	height: 26px;
}

.vsphere-icon-datastore-cluster {
	background: url("/ui/resources/img/svg-sprite.svg") 84.63949843260188% 64.29508196721312% no-repeat;
	width: 18px;
	height: 18px;
}

.vsphere-icon-datastore-cluster-error {
	background: url("/ui/resources/img/svg-sprite.svg") 0 70.19672131147541% no-repeat;
	width: 18px;
	height: 18px;
}

.vsphere-icon-datastore-cluster-warning {
	background: url("/ui/resources/img/svg-sprite.svg") 5.6426332288401255% 70.19672131147541% no-repeat;
	width: 18px;
	height: 18px;
}

.vsphere-icon-datastore-error {
	background: url("/ui/resources/img/svg-sprite.svg") 11.285266457680251% 70.19672131147541% no-repeat;
	width: 18px;
	height: 18px;
}

.vsphere-icon-datastore-inaccessible {
	background: url("/ui/resources/img/svg-sprite.svg") 16.927899686520377% 70.19672131147541% no-repeat;
	width: 18px;
	height: 18px;
}

.vsphere-icon-datastore-maintenance {
	background: url("/ui/resources/img/svg-sprite.svg") 22.570532915360502% 70.19672131147541% no-repeat;
	width: 18px;
	height: 18px;
}

.vsphere-icon-datastore-warning {
	background: url("/ui/resources/img/svg-sprite.svg") 28.213166144200628% 70.19672131147541% no-repeat;
	width: 18px;
	height: 18px;
}

.vsphere-icon-dell {
	background: url("/ui/resources/img/svg-sprite.svg") 62.613240418118465% 36.63003663003663% no-repeat;
	width: 50px;
	height: 50px;
}

.vsphere-icon-devcenter {
	background: url("/ui/resources/img/svg-sprite.svg") 39.49843260188088% 70.19672131147541% no-repeat;
	width: 18px;
	height: 18px;
}

.vsphere-icon-drag-and-drop-accept {
	background: url("/ui/resources/img/svg-sprite.svg") 45.141065830721004% 70.19672131147541% no-repeat;
	width: 18px;
	height: 18px;
}

.vsphere-icon-drag-and-drop-reject {
	background: url("/ui/resources/img/svg-sprite.svg") 50.78369905956113% 70.19672131147541% no-repeat;
	width: 18px;
	height: 18px;
}

.vsphere-icon-dv-switch {
	background: url("/ui/resources/img/svg-sprite.svg") 56.426332288401255% 70.19672131147541% no-repeat;
	width: 18px;
	height: 18px;
}

.vsphere-icon-dv-switch-alert {
	background: url("/ui/resources/img/svg-sprite.svg") 62.06896551724138% 70.19672131147541% no-repeat;
	width: 18px;
	height: 18px;
}

.vsphere-icon-dv-switch-warning {
	background: url("/ui/resources/img/svg-sprite.svg") 67.7115987460815% 70.19672131147541% no-repeat;
	width: 18px;
	height: 18px;
}

.vsphere-icon-dvsPnicError {
	background: url("/ui/resources/img/svg-sprite.svg") 22.291021671826627% 98.41423948220066% no-repeat;
	width: 14px;
	height: 14px;
}

.vsphere-icon-event-console {
	background: url("/ui/resources/img/svg-sprite.svg") 78.99686520376176% 70.19672131147541% no-repeat;
	width: 18px;
	height: 18px;
}

.vsphere-icon-export-line_16x16 {
	background: url("/ui/resources/img/svg-sprite.svg") 84.63949843260188% 70.19672131147541% no-repeat;
	width: 18px;
	height: 18px;
}

.vsphere-icon-filebrowser-copy {
	background: url("/ui/resources/img/svg-sprite.svg") 0 76.09836065573771% no-repeat;
	width: 18px;
	height: 18px;
}

.vsphere-icon-filebrowser-download {
	background: url("/ui/resources/img/svg-sprite.svg") 5.6426332288401255% 76.09836065573771% no-repeat;
	width: 18px;
	height: 18px;
}

.vsphere-icon-filebrowser-move {
	background: url("/ui/resources/img/svg-sprite.svg") 11.285266457680251% 76.09836065573771% no-repeat;
	width: 18px;
	height: 18px;
}

.vsphere-icon-filebrowser-upload {
	background: url("/ui/resources/img/svg-sprite.svg") 16.927899686520377% 76.09836065573771% no-repeat;
	width: 18px;
	height: 18px;
}

.vsphere-icon-folder {
	background: url("/ui/resources/img/svg-sprite.svg") 22.570532915360502% 76.09836065573771% no-repeat;
	width: 18px;
	height: 18px;
}

.vsphere-icon-folder-add {
	background: url("/ui/resources/img/svg-sprite.svg") 28.213166144200628% 76.09836065573771% no-repeat;
	width: 18px;
	height: 18px;
}

.vsphere-icon-fujitsu {
	background: url("/ui/resources/img/svg-sprite.svg") 76.03452652957603% 18.315018315018314% no-repeat;
	width: 100.66px;
	height: 50px;
}

.vsphere-icon-help-info {
	background: url("/ui/resources/img/svg-sprite.svg") 39.49843260188088% 76.09836065573771% no-repeat;
	width: 18px;
	height: 18px;
}

.vsphere-icon-help-info-hover {
	background: url("/ui/resources/img/svg-sprite.svg") 45.141065830721004% 76.09836065573771% no-repeat;
	width: 18px;
	height: 18px;
}

.vsphere-icon-host {
	background: url("/ui/resources/img/svg-sprite.svg") 50.78369905956113% 76.09836065573771% no-repeat;
	width: 18px;
	height: 18px;
}

.vsphere-icon-host-disconnected {
	background: url("/ui/resources/img/svg-sprite.svg") 56.426332288401255% 76.09836065573771% no-repeat;
	width: 18px;
	height: 18px;
}

.vsphere-icon-host-error {
	background: url("/ui/resources/img/svg-sprite.svg") 62.06896551724138% 76.09836065573771% no-repeat;
	width: 18px;
	height: 18px;
}

.vsphere-icon-host-maintenance {
	background: url("/ui/resources/img/svg-sprite.svg") 67.7115987460815% 76.09836065573771% no-repeat;
	width: 18px;
	height: 18px;
}

.vsphere-icon-host-warning {
	background: url("/ui/resources/img/svg-sprite.svg") 73.35423197492163% 76.09836065573771% no-repeat;
	width: 18px;
	height: 18px;
}

.vsphere-icon-hp {
	background: url("/ui/resources/img/svg-sprite.svg") 0 0 no-repeat;
	width: 179.7px;
	height: 71px;
}

.vsphere-icon-ibm {
	background: url("/ui/resources/img/svg-sprite.svg") 83.15209846837259% 0 no-repeat;
	width: 120.89px;
	height: 50px;
}

.vsphere-icon-info {
	background: url("/ui/resources/img/svg-sprite.svg") 0 82% no-repeat;
	width: 18px;
	height: 18px;
}

.vsphere-icon-memory-summary {
	background: url("/ui/resources/img/svg-sprite.svg") 46.30225080385852% 36.7003367003367% no-repeat;
	width: 26px;
	height: 26px;
}

.vsphere-icon-network {
	background: url("/ui/resources/img/svg-sprite.svg") 11.285266457680251% 82% no-repeat;
	width: 18px;
	height: 18px;
}

.vsphere-icon-network-24x {
	background: url("/ui/resources/img/svg-sprite.svg") 29.581993569131832% 45.45454545454545% no-repeat;
	width: 26px;
	height: 26px;
}

.vsphere-icon-network-error {
	background: url("/ui/resources/img/svg-sprite.svg") 22.570532915360502% 82% no-repeat;
	width: 18px;
	height: 18px;
}

.vsphere-icon-network-warning {
	background: url("/ui/resources/img/svg-sprite.svg") 28.213166144200628% 82% no-repeat;
	width: 18px;
	height: 18px;
}

.vsphere-icon-ovf-deploy {
	background: url("/ui/resources/img/svg-sprite.svg") 33.85579937304075% 82% no-repeat;
	width: 18px;
	height: 18px;
}

.vsphere-icon-plugin {
	background: url("/ui/resources/img/svg-sprite.svg") 39.49843260188088% 82% no-repeat;
	width: 18px;
	height: 18px;
}

.vsphere-icon-portChannel {
	background: url("/ui/resources/img/svg-sprite.svg") 45.141065830721004% 82% no-repeat;
	width: 18px;
	height: 18px;
}

.vsphere-icon-port_blocked {
	background: url("/ui/resources/img/svg-sprite.svg") 50.154798761609904% 57.92880258899677% no-repeat;
	width: 14px;
	height: 14px;
}

.vsphere-icon-port_blocked_SRIOV {
	background: url("/ui/resources/img/svg-sprite.svg") 56.426332288401255% 82% no-repeat;
	width: 18px;
	height: 18px;
}

.vsphere-icon-port_blocked_SRIOV12x {
	background: url("/ui/resources/img/svg-sprite.svg") 45.82043343653251% 57.92880258899677% no-repeat;
	width: 14px;
	height: 14px;
}

.vsphere-icon-port_blocked_left {
	background: url("/ui/resources/img/svg-sprite.svg") 26.625386996904023% 98.41423948220066% no-repeat;
	width: 14px;
	height: 14px;
}

.vsphere-icon-port_blue-grey {
	background: url("/ui/resources/img/svg-sprite.svg") 41.48606811145511% 57.92880258899677% no-repeat;
	width: 14px;
	height: 14px;
}

.vsphere-icon-port_blue-grey_left {
	background: url("/ui/resources/img/svg-sprite.svg") 98.63467492260061% 93.20388349514563% no-repeat;
	width: 14px;
	height: 14px;
}

.vsphere-icon-port_count {
	background: url("/ui/resources/img/svg-sprite.svg") 84.63949843260188% 82% no-repeat;
	width: 18px;
	height: 18px;
}

.vsphere-icon-port_green {
	background: url("/ui/resources/img/svg-sprite.svg") 32.81733746130031% 57.92880258899677% no-repeat;
	width: 14px;
	height: 14px;
}

.vsphere-icon-port_green_SRIOV {
	background: url("/ui/resources/img/svg-sprite.svg") 5.6426332288401255% 87.9016393442623% no-repeat;
	width: 18px;
	height: 18px;
}

.vsphere-icon-port_green_SRIOV12x {
	background: url("/ui/resources/img/svg-sprite.svg") 28.48297213622291% 57.92880258899677% no-repeat;
	width: 14px;
	height: 14px;
}

.vsphere-icon-port_green_left {
	background: url("/ui/resources/img/svg-sprite.svg") 50.77399380804953% 52.103559870550164% no-repeat;
	width: 14px;
	height: 14px;
}

.vsphere-icon-port_red_X {
	background: url("/ui/resources/img/svg-sprite.svg") 50.154798761609904% 43.689320388349515% no-repeat;
	width: 14px;
	height: 14px;
}

.vsphere-icon-port_red_X_left {
	background: url("/ui/resources/img/svg-sprite.svg") 86.79876160990712% 27.831715210355988% no-repeat;
	width: 14px;
	height: 14px;
}

.vsphere-icon-port_white {
	background: url("/ui/resources/img/svg-sprite.svg") 37.15170278637771% 57.92880258899677% no-repeat;
	width: 14px;
	height: 14px;
}

.vsphere-icon-port_white_left {
	background: url("/ui/resources/img/svg-sprite.svg") 93.06191950464395% 87.37864077669903% no-repeat;
	width: 14px;
	height: 14px;
}

.vsphere-icon-portgroup {
	background: url("/ui/resources/img/svg-sprite.svg") 45.141065830721004% 87.9016393442623% no-repeat;
	width: 18px;
	height: 18px;
}

.vsphere-icon-powered-on {
	background: url("/ui/resources/img/svg-sprite.svg") 50.78369905956113% 87.9016393442623% no-repeat;
	width: 18px;
	height: 18px;
}

.vsphere-icon-precheck-error {
	background: url("/ui/resources/img/svg-sprite.svg") 56.426332288401255% 87.9016393442623% no-repeat;
	width: 18px;
	height: 18px;
}

.vsphere-icon-precheck-ok {
	background: url("/ui/resources/img/svg-sprite.svg") 62.06896551724138% 87.9016393442623% no-repeat;
	width: 18px;
	height: 18px;
}

.vsphere-icon-precheck-warning {
	background: url("/ui/resources/img/svg-sprite.svg") 67.7115987460815% 87.9016393442623% no-repeat;
	width: 18px;
	height: 18px;
}

.vsphere-icon-remove {
	background: url("/ui/resources/img/svg-sprite.svg") 73.35423197492163% 87.9016393442623% no-repeat;
	width: 18px;
	height: 18px;
}

.vsphere-icon-rescan-hba {
	background: url("/ui/resources/img/svg-sprite.svg") 78.99686520376176% 87.9016393442623% no-repeat;
	width: 18px;
	height: 18px;
}

.vsphere-icon-rescan-storage {
	background: url("/ui/resources/img/svg-sprite.svg") 84.63949843260188% 87.9016393442623% no-repeat;
	width: 18px;
	height: 18px;
}

.vsphere-icon-resource-pool {
	background: url("/ui/resources/img/svg-sprite.svg") 94.22884012539184% 0 no-repeat;
	width: 18px;
	height: 18px;
}

.vsphere-icon-roles-clone,
.vsphere-icon-roles-clone\:regular {
	background: url("/ui/resources/img/svg-sprite.svg") 94.22884012539184% 5.901639344262295% no-repeat;
	width: 18px;
	height: 18px;
}

.vsphere-icon-roles-clone:hover,
.vsphere-icon-roles-clone\:hover {
	background: url("/ui/resources/img/svg-sprite.svg") 94.22884012539184% 11.80327868852459% no-repeat;
	width: 18px;
	height: 18px;
}

.vsphere-icon-rules-profile {
	background: url("/ui/resources/img/svg-sprite.svg") 94.22884012539184% 17.704918032786885% no-repeat;
	width: 18px;
	height: 18px;
}

.vsphere-icon-scsi-adapter {
	background: url("/ui/resources/img/svg-sprite.svg") 94.22884012539184% 23.60655737704918% no-repeat;
	width: 18px;
	height: 18px;
}

.vsphere-icon-scsi-adapter-warning {
	background: url("/ui/resources/img/svg-sprite.svg") 94.22884012539184% 29.508196721311474% no-repeat;
	width: 18px;
	height: 18px;
}

.vsphere-icon-snapshot {
	background: url("/ui/resources/img/svg-sprite.svg") 94.22884012539184% 35.40983606557377% no-repeat;
	width: 18px;
	height: 18px;
}

.vsphere-icon-snapshot_here {
	background: url("/ui/resources/img/svg-sprite.svg") 94.22884012539184% 41.31147540983606% no-repeat;
	width: 18px;
	height: 18px;
}

.vsphere-icon-snapshot_run {
	background: url("/ui/resources/img/svg-sprite.svg") 94.22884012539184% 47.21311475409836% no-repeat;
	width: 18px;
	height: 18px;
}

.vsphere-icon-snapshot_suspend {
	background: url("/ui/resources/img/svg-sprite.svg") 94.22884012539184% 53.114754098360656% no-repeat;
	width: 18px;
	height: 18px;
}

.vsphere-icon-status-error {
	background: url("/ui/resources/img/svg-sprite.svg") 94.22884012539184% 59.01639344262295% no-repeat;
	width: 18px;
	height: 18px;
}

.vsphere-icon-status-ok {
	background: url("/ui/resources/img/svg-sprite.svg") 94.22884012539184% 64.91803278688525% no-repeat;
	width: 18px;
	height: 18px;
}

.vsphere-icon-status-unknown {
	background: url("/ui/resources/img/svg-sprite.svg") 94.22884012539184% 70.81967213114754% no-repeat;
	width: 18px;
	height: 18px;
}

.vsphere-icon-status-warning {
	background: url("/ui/resources/img/svg-sprite.svg") 94.22884012539184% 76.72131147540983% no-repeat;
	width: 18px;
	height: 18px;
}

.vsphere-icon-storage-device-mount {
	background: url("/ui/resources/img/svg-sprite.svg") 94.22884012539184% 82.62295081967213% no-repeat;
	width: 18px;
	height: 18px;
}

.vsphere-icon-storage-device-unmount {
	background: url("/ui/resources/img/svg-sprite.svg") 0 93.8032786885246% no-repeat;
	width: 18px;
	height: 18px;
}

.vsphere-icon-storage-summary {
	background: url("/ui/resources/img/svg-sprite.svg") 29.581993569131832% 36.7003367003367% no-repeat;
	width: 26px;
	height: 26px;
}

.vsphere-icon-storage-system-refresh {
	background: url("/ui/resources/img/svg-sprite.svg") 11.285266457680251% 93.8032786885246% no-repeat;
	width: 18px;
	height: 18px;
}

.vsphere-icon-suspended {
	background: url("/ui/resources/img/svg-sprite.svg") 16.927899686520377% 93.8032786885246% no-repeat;
	width: 18px;
	height: 18px;
}

.vsphere-icon-synchronize {
	background: url("/ui/resources/img/svg-sprite.svg") 22.570532915360502% 93.8032786885246% no-repeat;
	width: 18px;
	height: 18px;
}

.vsphere-icon-task-console {
	background: url("/ui/resources/img/svg-sprite.svg") 28.213166144200628% 93.8032786885246% no-repeat;
	width: 18px;
	height: 18px;
}

.vsphere-icon-toolbar-add,
.vsphere-icon-toolbar-add\:regular {
	background: url("/ui/resources/img/svg-sprite.svg") 33.85579937304075% 93.8032786885246% no-repeat;
	width: 18px;
	height: 18px;
}

.vsphere-icon-toolbar-add-folder {
	background: url("/ui/resources/img/svg-sprite.svg") 39.49843260188088% 93.8032786885246% no-repeat;
	width: 18px;
	height: 18px;
}

.vsphere-icon-toolbar-add-vm {
	background: url("/ui/resources/img/svg-sprite.svg") 45.141065830721004% 93.8032786885246% no-repeat;
	width: 18px;
	height: 18px;
}

.vsphere-icon-toolbar-add:hover,
.vsphere-icon-toolbar-add\:hover {
	background: url("/ui/resources/img/svg-sprite.svg") 50.78369905956113% 93.8032786885246% no-repeat;
	width: 18px;
	height: 18px;
}

.vsphere-icon-toolbar-edit {
	background: url("/ui/resources/img/svg-sprite.svg") 56.426332288401255% 93.8032786885246% no-repeat;
	width: 18px;
	height: 18px;
}

.vsphere-icon-toolbar-host-question {
	background: url("/ui/resources/img/svg-sprite.svg") 62.06896551724138% 93.8032786885246% no-repeat;
	width: 18px;
	height: 18px;
}

.vsphere-icon-toolbar-more {
	background: url("/ui/resources/img/svg-sprite.svg") 67.7115987460815% 93.8032786885246% no-repeat;
	width: 18px;
	height: 18px;
}

.vsphere-icon-toolbar-network-add {
	background: url("/ui/resources/img/svg-sprite.svg") 73.35423197492163% 93.8032786885246% no-repeat;
	width: 18px;
	height: 18px;
}

.vsphere-icon-toolbar-remove {
	background: url("/ui/resources/img/svg-sprite.svg") 78.99686520376176% 93.8032786885246% no-repeat;
	width: 18px;
	height: 18px;
}

.vsphere-icon-toolbar-roles {
	background: url("/ui/resources/img/svg-sprite.svg") 84.63949843260188% 93.8032786885246% no-repeat;
	width: 18px;
	height: 18px;
}

.vsphere-icon-uplink-port-group {
	background: url("/ui/resources/img/svg-sprite.svg") 90.28213166144201% 93.8032786885246% no-repeat;
	width: 18px;
	height: 18px;
}

.vsphere-icon-uplink-port-group-alert {
	background: url("/ui/resources/img/svg-sprite.svg") 99.87147335423197% 0 no-repeat;
	width: 18px;
	height: 18px;
}

.vsphere-icon-uplink-port-group-warning {
	background: url("/ui/resources/img/svg-sprite.svg") 99.87147335423197% 5.901639344262295% no-repeat;
	width: 18px;
	height: 18px;
}

.vsphere-icon-vapp {
	background: url("/ui/resources/img/svg-sprite.svg") 99.87147335423197% 11.80327868852459% no-repeat;
	width: 18px;
	height: 18px;
}

.vsphere-icon-vapp-add {
	background: url("/ui/resources/img/svg-sprite.svg") 99.87147335423197% 17.704918032786885% no-repeat;
	width: 18px;
	height: 18px;
}

.vsphere-icon-vapp-edit {
	background: url("/ui/resources/img/svg-sprite.svg") 99.87147335423197% 23.60655737704918% no-repeat;
	width: 18px;
	height: 18px;
}

.vsphere-icon-vapp-managed {
	background: url("/ui/resources/img/svg-sprite.svg") 99.87147335423197% 29.508196721311474% no-repeat;
	width: 18px;
	height: 18px;
}

.vsphere-icon-vapp-running {
	background: url("/ui/resources/img/svg-sprite.svg") 99.87147335423197% 35.40983606557377% no-repeat;
	width: 18px;
	height: 18px;
}

.vsphere-icon-vapp-running-managed {
	background: url("/ui/resources/img/svg-sprite.svg") 99.87147335423197% 41.31147540983606% no-repeat;
	width: 18px;
	height: 18px;
}

.vsphere-icon-vapp-suspended {
	background: url("/ui/resources/img/svg-sprite.svg") 99.87147335423197% 47.21311475409836% no-repeat;
	width: 18px;
	height: 18px;
}

.vsphere-icon-vapp-suspended-managed {
	background: url("/ui/resources/img/svg-sprite.svg") 99.87147335423197% 53.114754098360656% no-repeat;
	width: 18px;
	height: 18px;
}

.vsphere-icon-vc-extension {
	background: url("/ui/resources/img/svg-sprite.svg") 99.87147335423197% 59.01639344262295% no-repeat;
	width: 18px;
	height: 18px;
}

.vsphere-icon-vcenter {
	background: url("/ui/resources/img/svg-sprite.svg") 99.87147335423197% 64.91803278688525% no-repeat;
	width: 18px;
	height: 18px;
}

.vsphere-icon-view_properties {
	background: url("/ui/resources/img/svg-sprite.svg") 99.87147335423197% 70.81967213114754% no-repeat;
	width: 18px;
	height: 18px;
}

.vsphere-icon-virtual-port-group {
	background: url("/ui/resources/img/svg-sprite.svg") 99.87147335423197% 76.72131147540983% no-repeat;
	width: 18px;
	height: 18px;
}

.vsphere-icon-virtual-port-group-alert {
	background: url("/ui/resources/img/svg-sprite.svg") 99.87147335423197% 82.62295081967213% no-repeat;
	width: 18px;
	height: 18px;
}

.vsphere-icon-virtual-port-group-warning {
	background: url("/ui/resources/img/svg-sprite.svg") 99.87147335423197% 88.52459016393442% no-repeat;
	width: 18px;
	height: 18px;
}

.vsphere-icon-vm {
	background: url("/ui/resources/img/svg-sprite.svg") 0 99.70491803278689% no-repeat;
	width: 18px;
	height: 18px;
}

.vsphere-icon-vm-add {
	background: url("/ui/resources/img/svg-sprite.svg") 5.6426332288401255% 99.70491803278689% no-repeat;
	width: 18px;
	height: 18px;
}

.vsphere-icon-vm-add-from-template {
	background: url("/ui/resources/img/svg-sprite.svg") 11.285266457680251% 99.70491803278689% no-repeat;
	width: 18px;
	height: 18px;
}

.vsphere-icon-vm-error {
	background: url("/ui/resources/img/svg-sprite.svg") 16.927899686520377% 99.70491803278689% no-repeat;
	width: 18px;
	height: 18px;
}

.vsphere-icon-vm-information {
	background: url("/ui/resources/img/svg-sprite.svg") 39.49843260188088% 87.9016393442623% no-repeat;
	width: 18px;
	height: 18px;
}

.vsphere-icon-vm-on {
	background: url("/ui/resources/img/svg-sprite.svg") 33.85579937304075% 87.9016393442623% no-repeat;
	width: 18px;
	height: 18px;
}

.vsphere-icon-vm-suspended {
	background: url("/ui/resources/img/svg-sprite.svg") 28.213166144200628% 87.9016393442623% no-repeat;
	width: 18px;
	height: 18px;
}

.vsphere-icon-vm-template {
	background: url("/ui/resources/img/svg-sprite.svg") 22.570532915360502% 87.9016393442623% no-repeat;
	width: 18px;
	height: 18px;
}

.vsphere-icon-vm-templates-24x {
	background: url("/ui/resources/img/svg-sprite.svg") 37.942122186495176% 45.45454545454545% no-repeat;
	width: 26px;
	height: 26px;
}

.vsphere-icon-vm-warning {
	background: url("/ui/resources/img/svg-sprite.svg") 11.285266457680251% 87.9016393442623% no-repeat;
	width: 18px;
	height: 18px;
}

.vsphere-icon-vmManaged {
	background: url("/ui/resources/img/svg-sprite.svg") 0 87.9016393442623% no-repeat;
	width: 18px;
	height: 18px;
}

.vsphere-icon-vmOnManaged {
	background: url("/ui/resources/img/svg-sprite.svg") 78.99686520376176% 82% no-repeat;
	width: 18px;
	height: 18px;
}

.vsphere-icon-vmRedManaged {
	background: url("/ui/resources/img/svg-sprite.svg") 73.35423197492163% 82% no-repeat;
	width: 18px;
	height: 18px;
}

.vsphere-icon-vmStuckManaged {
	background: url("/ui/resources/img/svg-sprite.svg") 62.06896551724138% 82% no-repeat;
	width: 18px;
	height: 18px;
}

.vsphere-icon-vmSuspendedManaged {
	background: url("/ui/resources/img/svg-sprite.svg") 50.78369905956113% 82% no-repeat;
	width: 18px;
	height: 18px;
}

.vsphere-icon-vmYellowManaged {
	background: url("/ui/resources/img/svg-sprite.svg") 73.35423197492163% 70.19672131147541% no-repeat;
	width: 18px;
	height: 18px;
}

.vsphere-icon-vsphere-admin {
	background: url("/ui/resources/img/svg-sprite.svg") 28.213166144200628% 64.29508196721312% no-repeat;
	width: 18px;
	height: 18px;
}

.vsphere-icon-vsphere-inventory-lists {
	background: url("/ui/resources/img/svg-sprite.svg") 50.78369905956113% 64.29508196721312% no-repeat;
	width: 18px;
	height: 18px;
}

