.center-dialog-content {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 1;
  pointer-events:none;

  .flexbox();
  .flex-direction(row);
  .justify-content(center);
  .align-items(center);

  &.block-input {
    pointer-events: auto;
    background: rgba(255,255,255,0.2);
  }

  &.ng-animate {
    transition: 1s all;
    transition-timing-function: step-end;

    &.ng-hide-animate {
      opacity: 0;

      &.ng-hide-remove {

        &.ng-hide-remove-active {
          opacity: 1;
        }
      }
    }
  }
}
