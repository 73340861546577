@import "mixins";

settings-view-header {
  display: block;

  .settingsViewHeader {
    .flex-auto;
    .flexbox-row();

    .settingsBlockTitle {
      flex: 1 1 auto;

      font-size: 22px;
      font-weight: 400;
      color: #565656;
      padding: 4px 0 4px 2px;
    }

    .sizing-button {
      visibility: hidden;
      margin-left: 10px;
      margin-right: 0;
      margin-top: 0;

      &:not(:last-child) {
        display: none;
      }
    }

    .settingsBlockBtnGroup.btn-group {
      margin: 0;
      padding: 0 0.3em 0 0; // The focus frame is cut when padding is 0px
    }
  }
}
