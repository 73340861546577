@import 'mixins';

/* Use for all summary pages.
 * TODO: Define boundary between app styles and normal styles*/
.summary-toggle {
  display: flex;
  flex-direction: row;
  align-items: baseline;

  .form-block {
    margin-left: auto;
    .toggle-switch {
      margin-right: 0;

      label {
        box-sizing: border-box;

        &:before {
          box-sizing: border-box;
        }
      }

    }
    .summary-toggle-label {
      line-height: 24px;
    }
  }
  vsc-sign-post {
    line-height: 1rem;
  }
}

.summary {
  .flexbox();
  .flex-direction(row);
}

.summary-image-block {
  margin-right: 20px;
  .flex(0, 0, auto);
}

.summary-info-block {
  .flex(1, 1, auto);
  padding-bottom: 10px;

  li {
    display: table-row-group;
  }
  vx-summary-item {
    display: table-row;
  }
  .summary-label {
    white-space: nowrap;
  }
  .summary-value {
    padding-left: 8px;
  }

  .summary-label, .summary-value {
    float: none;
    display: table-cell;
    width: auto;
  }
}

h6.summary-name-label {
  font-weight: 200;
  display: inline-block;
}

.summary-label {
  float: left;
  width: 155px;
}

.summary-value {
  min-width: 10px;
  display: inline-block;
}

.summary-image-thumbnail, .summary-image-state-label {
  width: 160px;
  height: 120px;
  border: 1px solid black;
}

.unlock-vm {
  position: absolute;
  margin-top: 10px;
}

.locked-vm-signpost-content {
  margin-top: 10px;
}

.launch-web-console-text-disabled {
    color: #bbb;
    pointer-events: none;
}

.launch-web-console {
   position: absolute;
   margin-top: 10px;
}

.launch-remote-console {
  position: absolute;
  margin-top: 30px;
}

.launch-remote-console-text {
  vertical-align: bottom;
}

.launch-remote-console-icon {
  margin-left: 5px;
}

.summary-image-state-label {
  .flexbox();
  .align-items(center);
  .justify-content(center);
}

.summary-image-thumbnail-container {
  position: relative;
  cursor: pointer;
  min-height: 120px;
  min-width: 160px;
}

.summary-image-caption {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 5px 0;
}

/*TODO: Can't this just use the Bootstrap meter?*/
.summary-meters-block {
  min-width: 230px;
  .flex(0, 0, auto);
}

.summary-action-container {
  .justify-content(flex-end);

  a.disabled {
    color: #bbb;
    pointer-events: none;
  }
}

.summary-action-container > a {
  margin-left: 8px;
}

.summary-badges {
  display: inline-flex;
  min-height: 24px;
}

.badge-item {
  margin-right: 7px;
  width: 24px;
  height: 24px;
}

.summary-icons-block {
  .flex(0, 0, auto);

  td {
    padding: 4px;
  }

  .resource-label {
    text-transform: uppercase;
    font-size: 11px;
  }

  .resource-value {
    font-weight: 500;
    font-size: 14px;
  }
}

.summary-items-list {
  list-style-type: none;
  margin: 0 0 10px 0;
}

// summary title layout
.titlebar {

  .object {
    align-items: center;

    // iconography
    > .titlebar-icon {
      transform: scale(1.2); // slight emphasis
      margin-right: 10px;
      flex: 0 0 auto;
    }

    // object's title
    > .titlebar-title {
      line-height: 1rem;
      color: @clr-black;
      font-size: 22px;
      font-weight: 200;
      // RWD gobble
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      margin-top: 0;
      .sr-only {
        -webkit-user-select: none;  /* Chrome all / Safari all */
        -moz-user-select: none;     /* Firefox all */
        -ms-user-select: none;      /* IE 10+ */
        user-select: none;
      }
    }
    // object's title
    > .titlebar-divider {
      width: 1px;
      height: 26px;
      background: @clr-light-midtone-gray;
      margin: 0 18px;
    }

    // holds an action affordance
    > .titlebar-actionable {

      // Action Link
      // --------------------------------------------------
      .summary-action-link {
        //padding: 0 4px;
        font-size: 12px;
        letter-spacing: 1px;
        color: @clr-actionblue;

        .actionable-title {
          text-transform: uppercase;
          font-weight: normal;

        }

        .actionable-toggle {
          vertical-align: inherit;
        }

        // ~~~~~~~ user interactions ~~~~~~~~~~~
        &:hover, &:active {
          color: extract(@clr-actionblue-list, 6);
          cursor: pointer;
          text-decoration: none;
        }

      }

    }

  }
}

// PLACE in a common Global icon location, not here!!
// the hyphenated notation is deliberate per convention for making easy lookups
.icon-dropdownToggle {
  &::before {
    font-family: "FontAwesome";
    content: "\f107"; // add code for dropdown arrow
  }
}

.ip-sign-post, .vm-tools-sign-post {
  -webkit-user-select: inherit;

  header {
    font-weight: bold;
    margin-bottom: 8px;
  }

  ul {
    margin-left: 0px;
    list-style-type: none;

  }
}

.vm-tools-info-label {
  display: table-cell;
  width: 100px;
}

.vm-tools-info-row {
  display: table-row-group;
}

.view-all-issues {
  margin-top: 6px;
}

@media (min-width: 1000px) {
  .vx-portlet-two-columns {
    .col {
      max-width: 50%;
    }
  }
}
