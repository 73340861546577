// holds the home, hosts and clusters, VMs and templates, etc object navigator
.category-node-object-navigator {
   @line-item-block-radius: 3px;
   -webkit-user-select: none;
   user-select: none;

   [role="container"] {
      padding-top: 24px;
      margin: 0;

      [role="group"] {
         padding-left: 24px;
         > .object-navigator-list {
            margin-right: 0; // flush
         }

         // line item
         [role="item"] {

            > .object {
               padding-right: 0; // flush
               border-radius: @line-item-block-radius 0 0 @line-item-block-radius;

               // ~~~~~~~ state modifiers ~~~~~~~~~
               &.selected {
                  .clr-nav-element-selected;
               }
               // ---------- user interactions ----------
               &:hover {
                  .clr-nav-element-hover;
               }

            }

         }

      }

   }

}
