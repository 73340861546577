@clr-white: #fff;
@clr-almost-white: #fafafa;
@clr-near-white: #eee;
@clr-light-gray: #ddd;
@clr-light-midtone-gray: #bbb;
@clr-dark-midtone-gray: #9a9a9a;
@clr-gray: #565656;
@clr-dark-gray: @clr-gray;
@clr-near-black: #313131;
@clr-black: #000;

@clr-gray-list: @clr-white, @clr-near-white, @clr-light-gray, @clr-light-midtone-gray, @clr-dark-midtone-gray, @clr-gray, @clr-near-black, @clr-black;
@clr-grays: (label: gray, base: @clr-gray, hex-colors: @clr-gray-list);

@clr-blue: #006a91;
@clr-blue-list: #e1f1f6, #c0dae4, #a9cdda, #66a6bd, #4392ae, @clr-blue, #004057, #00202c;
@clr-blues: (label: blue, base: @clr-blue, hex-colors: @clr-blue-list);

@clr-actionblue: #0079b8;
@clr-actionblue-list: #e1f1f6, #89cbdf, #49afd9, #0094d2, @clr-actionblue, #004a70, #002538;
@clr-actionblues: (label: action-blue, base: @clr-actionblue, hex-colors: @clr-actionblue-list);

@clr-actionpurple: #853fb3;
@clr-actionpurple-list: #f3ecf7, #e7d9f0, #b68cd1, #9460b8, @clr-actionpurple, #660092, #50266b, #281336;
@clr-actionpurples: (label: action-purple, base: @clr-actionpurple, hex-colors: @clr-actionpurple-list);

@clr-red: #c92100;
@clr-red-list: #fbf1f2, #f5dbd9, #ebafa6, #e62700, @clr-red, #a32100, #7d2100, #642100;
@clr-reds: (label: stoplight-red, base: @clr-red, hex-colors: @clr-red-list);

@clr-yellow: #efd603;
@clr-yellow-list: #f9f0e1, #eed7a9, @clr-yellow, #eb8d00, #ce5c00, #c25400, #9e4100, #642100;
@clr-yellows: (label: stoplight-yellow, base: @clr-yellow, hex-colors: @clr-yellow-list);

@clr-green: #60b515;
@clr-green-list: #dff0d0, #c6e4ab, #a0d373, @clr-green, #318700, #266900, #1d5100, #0f2900;
@clr-greens: (label: stoplight-green, base: @clr-green, hex-colors: @clr-green-list);

@clr-base-color-list: @clr-white, @clr-gray, @clr-blue, @clr-actionblue, @clr-green;
@clr-base-colors: (label: base, base: @clr-white, hex-colors: @clr-base-color-list);

@light-grey: #f5f7f8;
@light-grey-10: @clr-near-white;
@light-grey-20: @clr-light-gray;
@light-grey-30: @clr-light-gray;
@light-grey-40: #c7d1d6;
