@tooltip-error-color: rgb(208, 38, 33);

.vx-error-tooltip.tooltip {
  z-index: 10000;
  border-radius: 5px;
  visibility: visible;
  position: fixed;

  .tooltip-inner {
    background-color: @tooltip-error-color;
    color: white;
  }

  &.right .tooltip-arrow {
    border-right-color: @tooltip-error-color;
  }
}