vx-static-progress-indicator {
  @percentage-field-width: 4ch; //"100%"

  .progress-static {
    align-items: center;
    flex-grow: 1;
    flex-shrink: 1;
    padding-right: 0;
    width: auto;

    > .progress-meter {
      right: 0;
    }

  }

  // attributes of the progress
  [role="percentage"] {
    align-self: center;
    padding-left: 5px;
    white-space: nowrap;
    line-height: 1;
    flex-basis: @percentage-field-width; // forced allocation for max
  }

  [role="cancelButton"] {

    &.vui-icon-datagrid-cancel-disabled {
      cursor: not-allowed;
    }

    &.vui-icon-datagrid-cancel {
      cursor: pointer;
      opacity: 0.7;
    }

  }
}

@percentage-field-width: 4ch; //"100%"

.progress-static {
  align-items: center;
  flex-grow: 1;
  flex-shrink: 1;
  padding-right: 0;
  width: auto;

  > .progress-meter {
    right: 0;
  }

}

// attributes of the progress
[role="percentage"] {
  flex: 0 0 10px;
  align-self: center;
  padding-left: 5px;
  white-space: nowrap;
  line-height: 1;
  flex-basis: @percentage-field-width; // forced allocation for max
}

[role="cancelButton"] {
  flex: 0 0 15px;
  &.vui-icon-datagrid-cancel-disabled {
    cursor: not-allowed;
  }

  &.vui-icon-datagrid-cancel {
    cursor: pointer;
    opacity: 0.7;
  }

}
