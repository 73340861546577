@import "mixins";

.vx-property-view {
  width: 100%;
  height: 100%;

  .vx-property-view-proto();

  .vx-property-view-table {
    .vx-property-view-section-property {
      & > .vx-property-view-section-property-key-column {
        white-space: nowrap;
      }

      & > .vx-property-view-section-property-values-column {
        width: 100%;
      }
    }
  }
}

.vx-property-view-proto() {
  .flexbox();
  .flex-direction(column);
  background-color: white;

  .nav.nav-tabs {
    overflow: hidden;
    .flex(0, 0, auto);
  }

  .vx-property-view-table-wrapper {
    .flex(1, 1, auto);
    width: 100%;
    height: auto;
    overflow: auto;
    padding: 10px;
    box-sizing: border-box;

    .vx-property-view-table {
      width: 100%;
      box-sizing: border-box;
      border-collapse: collapse;

      tr {
        vertical-align: top;
      }

      .vx-property-view-section {
        line-height: 18px;

        &:not(:first-child) {
          border-top: 15px solid transparent;
        }

        .vx-property-view-section-title {
          font-weight: bold;

          .vx-property-view-section-title-icon {
            margin: -2px 0 0 0;
          }
        }

        .vx-property-view-section-actions-container {
          display: inline-block;
          float: right;

          a {
            margin-left: 12px;
          }

          a.disabled {
            opacity: 0.4;
            pointer-events: none;
          }
        }

        .vx-property-view-section-padded-content {
          border-left: 30px solid transparent;
        }

        .vx-property-view-section-message {
          .vx-property-view-section-message-icon {
            margin: -2px 0 0 0;
          }
        }

        .vx-property-view-section-property {
          .vx-property-view-section-property-key-column {
            border-right: 15px solid transparent;
              .vx-property-view-section-property-key-icon {
                 margin-bottom: -2px;
              }
          }

          .vx-property-view-section-property-values-column {
          }

          .vx-property-view-section-property-values-table {
            display: table;
            width: 100%;
            table-layout: fixed;
            box-sizing: border-box;
            word-wrap: break-word;

            .vx-property-view-section-property-value {
              width: 100%;
              display: inline-block;

              .vx-property-view-section-property-value-icon {
                margin: -2px 0 0 0;
              }

              .vx-property-view-section-property-value-text {
                white-space: pre-wrap;
              }
            }
          }
        }
      }
    }
  }
}

/*
  amarinov, vchomakov: Fix non-breakable text in value column for Chrome and Safari.
  DO NOT USE display: table as this breaks copy-pasting the whole property view table
  in Chrome and Safari.
*/
html.k-safari,
html.k-webkit {
  .vx-property-view {
    .vx-property-view-table-wrapper {
      .vx-property-view-table {
        .vx-property-view-section {
          .vx-property-view-section-property {
            .vx-property-view-section-property-values-table {
              display: inline-block;
              table-layout: initial;
              word-break: break-word;

              .vx-property-view-section-property-value-text {
                white-space: pre-wrap;
              }
            }
          }
        }
      }
    }
  }
}

// fix for wrapping issues in property views in portlets for small screen resolutions
html {
  .vx-portlet {
    .vx-property-view {
      .vx-property-view-table-wrapper {
        .vx-property-view-table {
          .vx-property-view-section {
            .vx-property-view-section-property {
              .vx-property-view-section-property-values-table {
                .vx-property-view-section-property-value-text {
                  white-space: nowrap;
                }
              }
            }
          }
        }
      }
    }
  }
}
