//TODO verify with UIX
@outline-white-focus: 1px dotted #fafafa; // FF and Edge focus style for header elements
@outline-focus:1px dotted #212121; // FF and Edge focus style
@outline-webkit-focus: 5px auto -webkit-focus-ring-color; // Chrome focus style

.user-tabbing {
   .focusable-area {
      *:focus {
         outline: @outline-focus;
         outline: @outline-webkit-focus;
      }
   }

  [role=tree].view-focused [role=treeitem].state-focused {
      outline: @outline-white-focus;
      outline-offset: @header-items-outline-offset;
      outline: @outline-focus;
      outline: @outline-webkit-focus;
    }
}

