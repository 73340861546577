/**
 * Styles for the Resource Meter Control
 */
 
.resource-meter {
   margin-bottom: 6px;
}

.resource-meter span {
   font-size: 10px;
}

.resource-meter > .resource-meter-top-right-info, .resource-meter-bottom-right-info {
   float: right;
}

.resource-meter .progress {
   margin-bottom: 0px;
   border-radius: 4px;
   border: 1px solid #cecece;
   background-image: linear-gradient(to bottom,#e2e2e2 0,#eaeaea 100%);
}

.resource-meter .normal .progress .progress-bar {
   background-image: linear-gradient(to bottom,#7ec2ed 0,#6fb3df 100%);
}

.resource-meter .overusage .progress .progress-bar {
   background-image: linear-gradient(to bottom,#ff5a5a 0,#ec3d3d 100%);
}

.vertical-resource-meter {
   -webkit-appearance: none;
   -moz-appearance: none;
   appearance: none;
   width: 20px;
   height: 12px;
   margin-bottom: 0px;
   margin-left: -5px;
   margin-right: 0px;
   -webkit-transform: rotate(-90deg);
   -moz-transform: rotate(-90deg);
   transform: rotate(-90deg);
   border: 1px solid;
   display: inline-block;

   .meter-gauge {
      width: 100%;
      height: 10px;
      display: block;
      background: #dfdfdf;

      &, .meter-gauge-value {
         box-shadow: 0 5px 5px -5px #999 inset;
      }
   }
}

.horizontal-resource-meter {
   width: 100%;

   .meter-gauge {
      border: none;
      background: #dfdfdf;
      height: 12px;
      display: block;

      .meter-gauge-value {
         background: #4679b9;
      }
   }
}

.meter-gauge .meter-gauge-value {
   height: inherit;
   background-size: 100% 100%;
   display: block;
}

.vertical-resource-meter {
   .resource-meter-usage-normal {
      background: #73FF27;
   }
}

.vertical-resource-meter {
   .resource-meter-usage-warning {
      background: #F9FF27;
   }
}

.vertical-resource-meter {
   .resource-meter-usage-danger {
      background: #FC6F6F;
   }
}