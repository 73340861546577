.clr-action-bar {
  clr-icon {
    color: rgb(86, 86, 86);
  }

 .clr-no-border-button {
    outline: 0;
    span {
      color: #0079b8;
    }
  }
}
