@import "mixins";

.charts-toolbar {
  background-color: #F2F2F2;
  border: 1px solid #DDDDDD;
  padding: 8px;
  font-weight: bold;
}

.charts-overview-title {
  font-weight: bold;
  margin: 5px;
}

chart-view {
  display: inline-block;
  vertical-align: top;
  border: 1px solid transparent;
  margin: 2px 4px;

  .loading-text {
    display: none;
  }

  .centered-content {
    display: inline-block;
  }
}

chart-view.loading {
  width: 500px;
  height: 330px;
  border: 1px solid #d6d6d6;

  .loading-text {
    height: 100%;
    .flexbox();
    .align-items(center);
    .justify-content(center);
    text-align: center;

    img {
      margin-bottom: 3px;
    }
  }
}

.half-width {
  width: 50%;
}