@import "mixins";
@import "colors";

.object-navigator {
   .vui-primary-tabs {
      min-height: 0;
   }
}

.object-navigator .k-splitbar,
.object-navigator .k-splitter {
   box-sizing: border-box;
}

.non-scrollable {
   overflow: hidden;
}

.related-items-pane-splitter {
   height: 100%;

   [kendo-splitter] {
      height: 100%;
      border: 0;
      background: transparent;
   }
}

.nav-top-pane-relations-mode {
   height: 100%;
   .flexbox();
   .flex-direction(column);
}

.nav-bottom-pane-relations-mode {
   overflow: auto;
   height: calc(~"100% - 26px");
}

#related-items-virtual-list td {
   white-space: nowrap;
}

// left hand main object navigator

//------------------------------------------

@object-navigator-tab-background: @light-grey-10;
@object-navigator-line-height: 26px;
.user-tabbing LEFT-PANEL .vui-panel OBJECT-NAVIGATOR {

   .vui-tabs-container .vui-primary-tabs {
      /* Tabs of inventory trees */
      .nav > li:focus > a {
         outline: @outline-focus;
         outline: @outline-webkit-focus;
      }
   }

   /* Object list top header with counter */
   .obj-nav-counter:focus {
      outline: @outline-focus;
      outline-offset: -1px;
      outline: @outline-webkit-focus;
   }

   .object-navigator-list {
      /* Object navigator list items */
      a.object:focus {
         outline: @outline-focus;
         outline: @outline-webkit-focus;

      }
      /* Object navigator expand/collapse icon */
      [vx-collapse-icon] i:focus {
         outline: @outline-focus;
         outline: @outline-webkit-focus;
      }
   }
}

LEFT-PANEL .vui-panel OBJECT-NAVIGATOR {

   .panel-inner {

      .titlebar {
         display: none;
      }

      .panel-content {
         top: 0;
         margin: 0;
      }

      .object-navigator-history-prev-button {
         margin-right: 2px;
      }

      .object-navigator-history-prev-button {
         button.object-navigator-history-prev-button {
            border: 1px solid #D2D2D2;
            background: @object-navigator-tab-background;
         }

         button.btn.btn-default.dropdown-toggle.object-navigator-history-prev-button {
            border: 1px solid #C5C5C5;
            box-sizing: border-box;
            margin: 0;
            background-image: none;
            width: 160px;
         }

         .btn.btn-default.dropdown-toggle.object-navigator-history-prev-button,
         .btn.btn-default.dropdown-toggle.object-navigator-history-prev-button:focus,
         .btn.btn-default.dropdown-toggle.object-navigator-history-prev-button:hover,
         .btn.btn-default.dropdown-toggle.object-navigator-history-prev-button:active,
         .btn.btn-default.dropdown-toggle.object-navigator-history-next-button,
         .btn.btn-default.dropdown-toggle.object-navigator-history-next-button:focus,
         .btn.btn-default.dropdown-toggle.object-navigator-history-next-button:hover,
         .btn.btn-default.dropdown-toggle.object-navigator-history-next-button:active {
            background-color: @object-navigator-tab-background;
         }

         button.btn.btn-default.dropdown-toggle.object-navigator-history-next-button {
            border: 1px solid #C5C5C5;
            min-width: auto;
            margin: 0;
            box-sizing: border-box;
         }

         .object-navigator {
            .nav-tabs > li.active > a {
               background-color: @object-navigator-tab-background;
            }
         }

         .object-navigator .k-treeview .k-item {
            line-height: @object-navigator-line-height;
         }

         .object-navigator .object-navigator-list {
            margin: 2px 0;
         }

         .object-navigator .object-navigator-list > li > a.object span.vui-icon-object-nav-pan {
            right: 2px;
            top: 8px;
            margin-top: 0;
            opacity: 0.3;
         }

         .object-navigator .object-navigator-list > li > a.object:hover {
            background-color: @object-navigator-tab-background;
         }

         .object-navigator .object-navigator-list > li > a.object:hover span.vui-icon-object-nav-pan {
            background-position: -120px -1px;
            opacity: 1;
         }

         .object-navigator .object-navigator-list > li > a.object .vui-icon-object-nav-pan, .object-navigator .object-navigator-list > li > a.object .vui-icon-object-nav-pan-hover {
            margin-top: 5px;
            margin-right: 2px;
         }

         .object-navigator .object-navigator-list > li > a.object:hover {
            background-color: @object-navigator-tab-background;
            color: inherit;
         }

         .object-navigator .object-navigator-list > li {
            color: @light-grey-30;
            line-height: 26px;
         }

         .object-navigator-heading {
            text-transform: uppercase;
         }

         .object-navigator .btn:focus {
            box-shadow: none;
         }

         .object-navigator-counter {
            margin-top: 2px;
            background: #DDDDDD;
            text-align: center;
            color: #313131;
            border: 1px solid rgba(81, 81, 81, 0.16);
         }
      }


      // OFFICIAL .object-navigator

      .object-navigator {
         @navigator-layout-default-border: @clr-light-gray;

         .nav.nav-tabs {
            margin-top: 0; // reset
            padding-top: 17px; // top bounds padding of only icon row
            padding-bottom: 9px;
            border-bottom: 1px solid @navigator-layout-default-border;

            > li, li.active {
               > a, a:hover {
                  height: 18px;
                  padding-bottom: 15px;
               }
            }
         }

         div.vui-primary-tabs .vui-tab-content {
            top: 60px; // offset from above row of icons
            padding-left: 14px;
            overflow: initial;

            // -------- inventory tree -------

            // ** Inventory tree styles**
            .scrollableInventoryTree {
               position: absolute;
               overflow: auto;
               margin: -14px;
               width: 100%;
               height: 100%;
               box-sizing: border-box;
            }
            // /inventory tree
         }
      }


      // OFFICIAL .object-navigator
   }

}

.nav-without-scroll {
   .nav {
      overflow-y: hidden;
   }
}