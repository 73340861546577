@select-height: 24px;

input[type=color],
input[type=date],
input[type=datetime-local],
input[type=datetime],
input[type=email],
input[type=month],
input[type=number],
input[type=password],
input[type=phone],
input[type=search],
input[type=tel],
input[type=text],
input[type=time],
input[type=url],
input[type=week],
textarea,
select,
input
{
  &.has-error {
    border: 2px solid red;
  }

  &:focus, &:active {
    box-shadow: none;
  }
}

.full-width {
  width: 100%;
  max-width: none !important;;
}

label.normal-weight {
  font-weight: normal;
}

@selected-color: #2b5480;
@hover-color: #5f7e9f;

.value-in-units {
  display: inline;

  .input-container {
    float: left;
    padding-right: 2px;
  }

  .input-group-btn {
    width: 20px;
  }

  .input-container {
    position: relative;
  }

  .recommended-values {
    position: absolute;
    z-index: 3;
    left: 0;
    right: 0;
    top: 100%;
    width: 100%;
    background-color: white;
    border: 1px solid black;

    tr {
      cursor: pointer;
      &:hover {
        background-color: @hover-color;
        color: white;
      }
      &.highlighted {
        background-color: @selected-color;
        color: white;
      }
      &:focus {
        outline: auto;
      }
    }

    td {
      padding: 5px;
      white-space: nowrap;
    }
  }

  .recommended-values-openUpwards {
    top: initial;
    bottom: 100%;
  }
}

.select {
  select {
    height: @select-height;
  }
}

select[disabled] {
  opacity: .5;
}

/* this overrides VUI's tooltip class since clarity's tooltip class works differently */
/* The clr-tooltip class is necessary to avoid using Clarity forms while at the same
   time using the Clarity tooltip.
*/
form .form-group .tooltip, .clr-tooltip {
  opacity: 1;
}


/* this is a pre-implementation of clarity's helper text
   it works when .form-tip is inside the label.tooltip used for
   form validation.

   probably should be removed once the official clarity helper
   text is implemented.
*/

.form-tip {
  font-size: 12px;
  color: #747474;
  visibility: hidden;
  line-height: 14px;
  padding-top: 3px;
  padding-left: 6px;
}

input[type=text]:focus ~ .form-tip {
  visibility: visible;
}

.vx-validate-tooltip {
  span.tooltip-content span {
    margin: 0;
  }
}
