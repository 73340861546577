// Set display of list-view elements to 'block' as by default custom elements have
// display 'inline' which prevents viewVisibilityMonitor from working correctly
@import "focus";

.user-tabbing {
   .filter-input {
      /* List view filter icon button */
      .nav-icon:focus {
         outline: @outline-focus;
         outline: @outline-webkit-focus;
      }
   }
}
.filter-button {
   border-style: none;
   background-color: transparent;
   padding: 0px;
}

list-view {
   display: block;
   .filter-input {
      align-items: center;
      display: inline-flex;
      font: inherit;

      &:before {
         width: 24px;
         display: inline-block;
         font: normal normal normal 14px/1 FontAwesome;
         font-size: inherit;
         text-rendering: auto;
         -webkit-font-smoothing: antialiased;
         -moz-osx-font-smoothing: grayscale;
      }

      input {
         margin-left: 4px;
         margin-bottom: 1px;
      }
   }
}