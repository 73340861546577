export-list {
   .content {
      max-height: 450px;
      overflow: auto;
   }

   .spinner-body {
      display: table;
      position: fixed;
      left: 50%;
      top: 50%;
      margin: -50px 0 0 -50px;

      .spinner-text {
         display: table-cell;
         vertical-align: middle;
         padding-left: 12px;
      }
   }

   .div-disabled {
      pointer-events: none;
      cursor: not-allowed;
      opacity: 0.6;

      // Prevent double-disabling of elements.
      .div-disabled {
         opacity: 1;
      }
   }

   .section {
      font-size: 18px;
      padding-top: 10px;
   }
}