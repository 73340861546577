.k-menu.k-menu-vertical.k-context-menu {
   /* Size the menu based on the contents, but truncate
    * if they don't fit in 400px */
   width: auto;
   max-width: 400px;
   span.k-link {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
   }

   /* Some hacks around pointer-events:none, which prevent the tooltip from
    * showing on the menu header */
   .menu-header {
      span.k-link {
         pointer-events: auto;
      }
      &.k-state-hover span.k-link {
         color: #666;
         cursor: default;
      }
   }
}

/** These css rules are needed for menu scroller
 * https://jira.eng.vmware.com/browse/VSUIP-1811
 */
#vui-actions-menu.k-menu.k-menu-vertical.k-context-menu.vui-menu-scroller {
   position: static!important;
   overflow-y: auto;
   overflow-x: hidden;
   > li {
      position: static;
      > .k-link:first-child {
         position: relative;
      }
      &.k-state-border-down > .k-animation-container {
         width:auto!important;
         > ul.k-menu-group:first-child {
            position: relative!important;
         }
      }
   }

}

/* This fixes the missing separators between child menus/actions
 * in the tree view context menu */
li.k-item.is-separated {
   border-top-width: 1px !important;
}

//the 2nd level submenu can be a bit wider
ul.k-group.k-menu-vertical {
   ul.k-group.k-menu-group.k-popup.k-reset {
      max-width: 400px;
      width: auto;
   }
}
