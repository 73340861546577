.table-layout {
  border: none;
}

.table-layout td {
  border: none;
  padding-right: 5px;
  padding-bottom: 5px;
}

