@import "mixins";

.column-meter {
  .flexbox();
  .align-items(center);

  span {
    width: 40px;
    display: block;
    text-align: right;
    margin-right: 10px;
  }

  meter {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    .flex(1, 1, 100%);
  }

  meter::-webkit-meter-bar {
    background: none;
    background-color: #e5e5e5;
  }

  meter::-webkit-meter-optimum-value {
    background: none;
    background-color: @unity-primary;
  }
}
