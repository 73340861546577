@import "focus";
.controlcenter {
   padding-top: 5px;
}

/******** Controlcenter Category********/
.controlcenter-category {
   padding: 0 10px;
   height: 21px;
   line-height: 21px;
   color: #597994;
   background-color: rgba(62, 137, 196, 0.2);
   font-weight: 400;
}

/******** Controlcenter Shortcuts ********/
.user-tabbing {
   /* Shortcut items in shortcuts view */
   .controlcenter-shortcut:focus {
      outline: @outline-focus;
      outline: @outline-webkit-focus;
   }
}

.controlcenter-shortcut-icon-display {
   display: inline-block;
   background: url(/ui/resources/img/plugin.svg);
}

.controlcenter-shortcut {
   display: inline-block;
   cursor: pointer;
   margin: 21px 10px;
   text-align: center;
   vertical-align: top;
   width: 95px;
}

// !important is required to enforce styling for plugins
.controlcenter-shortcut span.controlcenter-shortcut-plugin-icon {
   background-size: cover;
   width: 32px !important;
   height: 32px !important;
}

.controlcenter-shortcut-icon {
   width: 32px;
   height: 32px;
}

.controlcenter-shortcut-label {
   margin-top: 10px;
   max-width: 95px;
   color: #333333;
   line-height: 13px;
   white-space: pre-line;
   word-wrap: break-word;
   padding-bottom: 2px; //focus related
}