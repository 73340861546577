.text-overflow-ellipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.modal-dialog .modal-content .modal-header {
  // action and object name parts of a title
  .modal-title {
    display: flex;
    align-items: flex-start;

    // note: clarity should define headers, however using the h3 introduced awkward spacing
    @title-height: 24px;
    @separator-color: #ccc; // clarity spec color code to use..

    // secondary-title
    .secondaryTitle {
      margin-top: 0;
      margin-bottom: 0;
      line-height: 24px;
    }

    // Spoke to Yen, please remove / recode this after the new clarity 0.40 is released..
    .primaryTitle {
      flex-shrink: 0;
      font-size: @title-height;

      &.secondaryTitleIsEmpty {
        .text-overflow-ellipsis;
        flex-shrink: 1;
      }

      &:not(.secondaryTitleIsEmpty) {
        max-width: 65%;
      }
    }
    .separator {
      content: "";
      border-left: 1px @separator-color solid;
      margin: 0 18px;
      height: 22px;
    }

    .secondaryTitle {
      .text-overflow-ellipsis;
      display: block;

      // =========================== remove/fix above after Clarity 0.40 is released

    }
  }
}

// reduce the spacing between the title when a top button group is adjacent
[has-modal-button-group] .modal-dialog .modal-content .modal-header {
  padding-bottom: 12px;

}

// resets to allow a grouping tag to organize layout for children
.modal-button-group {
  margin-top: 0;
  margin-bottom: 12px;

  > button.btn {
    margin: 0;
  }
}

//////////////////////////////////////////////////////////////////////

.modal {
  overflow-y: auto;
}

@clr-modal-offset: 24px;

.clr-modal-header() {
  color: #000;
  font-size: 24px;
  font-family: "ProximaNova","Avenir Next","Helvetica Neue",Arial,sans-serif;
  font-weight: 200;
  line-height: 27px;
  letter-spacing: normal;
  margin: 0;
  padding: 0;
}

.vui-popup.vui-dialog {
  padding: @clr-modal-offset;

  .titlebar.ui-draggable-handle {
    padding: 0 !important;
    .titlebar-text {
      .clr-modal-header;
    }
  }
  .panel-content {
    padding: 0 !important;
  }

  .dialog-footer {
    height: auto;
    padding: 0;
    margin-top: @clr-modal-offset / 2;
    .btn {
      margin: 0 0 0 @clr-modal-offset / 2;
    }
  }
}


//////////////////////////////////////////////////////////////
//
// wizard
//

.vui-wizard {
  padding: @clr-modal-offset;

  // wizard header titlebar
  .wizard-modal-titlebar {
    padding: 0 !important;
    background: none !important;
    .titlebar-text {
      .clr-modal-header;
    }
  }

  // wizard body
  .wizard-modal-body {
    .flex-1;
    background: none !important;

    // TOC
    .wizard-panel-TOC {
      background: none !important;
      .wizard-steps-list li a {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }


    // wizard content - container
    .wizard-content-container {
      .flexbox-column;

      // wizard content - header
      .wizard-content-header-area {
        flex-shrink: 0;
        .wizard-content-title,
        .wizard-content-instructional-text {
          outline: none;
        }
      }

      // wizard content
      .wizard-content {
        .flexbox-column;

        // generally the div inside wizard-content
        // is a flexbox-column
        > div:not(.creation-type):not(.edit-ha-dialog) {
          .flexbox-column;
        }

        // except when it's not
        // like in the 1st step of the create VM wizard
        .creation-type {
          .flexbox-row(flex-start);
          .left-half-page {
            .flex-1;
            margin-right: 5px;
          }
          .right-half-page {
            .flex-1;
            margin-left: 5px;
          }
        }

      }
    }
  }

  // footer
  .wizard-modal-footer {
    height: auto;
    padding: 0;
    margin-top: @clr-modal-offset / 2;
    border: none;
    background: none !important;
    .btn {
      margin: 0 0 0 @clr-modal-offset / 2;
    }
    .wizard-resize-icon-span:empty {
      display: none;
    }
  }

  .compatibility {
    padding-top: 10px;
    display: flex;
    flex: 0 1 auto;
    flex-flow: column;
    height: 150px;
  }
  fieldset {
    display: flex;
    flex-flow: column;
    padding-left: 23px;
    &:disabled, &[disabled] {
      color: lightgray;
    }
  }
}


//////////////////////////////////////////////////////////////

// the vui bootstrap navigation is using floats to layout
// it's li elements and also margin-bottom:-1 to push them down
// which causes this scroll that is visible on every tab/nav
// TODO fix nav-tabs to use flexbox instead of floats and layout it without
// negative margins
.nav.nav-tabs {
  // XXXX banned XXXX
  // overflow-x: scroll !important;
  // it doesn't matter if French or Germans can't read the tabs. Design a more comprehensive tab widget that implements RWD
  // also note: mixing of tab visual design inside of the "modal.less" file is wrong, UNLESS it is scoped. this broke the rule
  // XXXX banned XXXX

  // 20170303 - decision made to remove the scrollbars as it crops and hides the other UI elements that make up a tab
  // in addition, we have a strict no !important policy here on the team.
  // Anytime this rule is broken we require a formal discussion as to why you believe an !important is , well, so important.
  // justify your position on this matter with UX folks and team in PA. Any further deviations from this will be dealt
  // with accordingly. Use of !important is a poor sign of understanding the CSS model and the consequences of the cascade.
  // A better resort would be to inline. that way it is very clear that the stylesheet is of no value.
  // when placing code in a shared space such as a stylesheet, do comply with the cascade and leverage specificity to
  // achieve your visual goals. -Matt



}

// fix the top 24px margin tha causes the scrolls
// in the simple rename dialogs
.dialog-content-view {
  .form-group {
    p {
      margin-top: 0 !important;
    }
  }
}

// fix a bit the vertical alignment of the passed check
// in the wizard TOC
vui-wizard-toc {
  .wizard-steps-list {
    li {
      display: flex;
      align-items: center;
    }
    li,
    li * {
      line-height: 24px !important;
    }
  }
}


//////////////////////////////////////////
.pnic-select-dialog-content {
  overflow: hidden;
  box-sizing: border-box;
  .failover-order-details {
    padding-left: 0;
  }

  .failover-order-with-details {
    failover-order {
      box-sizing: border-box;
      position: relative;
      display: block;
    }
  }
}

////////////////////////////////////////

.radio {
  margin-bottom: 10px;
}

//////////////////////////////////////

div[ng-controller="EditCustomAttributesDialogController as editCtrl"] {
  > div {
    overflow: hidden;
  }
}

.clr-confirm-modal {
  .modal-body{
    .flexbox();
    .clr-confirm-modal-icon {
      .flexbox();
      .flex(0, 0, auto);
      align-items: center;
      span {
        margin-right: 20px;
      }
    }
    .clr-confirm-modal-icon-top {
      .flexbox();
      .flex(0, 0, auto);
      span {
        margin-right: 20px;
      }
    }
    p {
      .flexbox();
      align-items: center;
      margin-top: 0px;
    }
  }
}


//////////////////////////////////////////////////////

.creation-type {
  select {
    option {
      height: 24px;
      padding-left: 4px;
      padding-top: 4px;
      box-sizing: border-box;
    }
  }
}

// TODO remove this fill-parent from the html
// run UI tests
heartbeat-datastores > .fill-parent {
  height: auto;
}

.body-overflow-hidden {
  overflow: hidden;

  clr-stack-view {
    > dl,
    > dl.stack-view {
      overflow: auto;
    }
  }
}
