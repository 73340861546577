//LESS file containing VUI specific overrides and/or changes to vui components

.vui-stack-view .stack-view-table .stack-view-child .stack-view-cell>:last-child,
.vui-stack-view .stack-view-table .stack-view-child-i .stack-view-cell>:last-child,
.vui-stack-view .stack-view-table .stack-view-parent .stack-view-cell>:last-child,
.vui-stack-view .stack-view-table .stack-view-parent-i .stack-view-cell>:last-child,
.vui-stack-view .stack-view-table .stack-view-row .stack-view-cell>:last-child,
.vui-stack-view .stack-view-table .stack-view-row-i .stack-view-cell>:last-child {
  margin-bottom: 0px;
}

// Override kendo "box-sizing: content-box" for portlets
.vui-stack-view * {
   -webkit-box-sizing: border-box;
   -moz-box-sizing: border-box;
   box-sizing: border-box;
}

// Override kendo grid "box-sizing: content-box" for vui-datagrid
div[vui-datagrid] .k-grid.k-widget {
   -webkit-box-sizing: border-box;
   -moz-box-sizing: border-box;
   box-sizing: border-box;
}

// Override cursor 'auto' (vertical bar) setting for kendo grid text cursor in cells
.k-grid td {
  cursor: default
}

// Used in ColumnGridRenderers to display lines where we do not want to wrap the text or have ellipsis
.line-wrap {
    white-space: normal;
}

.mv5 {
  margin: 5px 0;
}

.k-grid-content {
  min-height: 35px;
}

// Override outline of focused items in the tree (bug id: #108564148)
div[vui-tree-view] .k-state-focused {
  outline: none !important;
}

// Remove min width and height for the App body.
.vui-layout-body {
  min-height: 0;
  min-width: 0;
  background: none;
  padding: 0px;
}

// Ensure TabViews are scrollable even if the browser height is very small
.vui-tertiary-tabs, .vui-toc-tabs {
  min-height: 1px; // phantomjs fails if this is "0"
}

.vui-toc-tabs {
  overflow-x: hidden;
}

// Override the padding of the quick search results header cells.
.perf-container .results-padding-LR table .results-cell-blue {
   padding-top: 8px;
   padding-bottom: 8px;
}

// Override the padding of the quick search results result cells.
.perf-container .results-padding-LR table .results-cell-white {
   padding-top: 8px;
   padding-bottom: 8px;
}

.perf-container {
  max-width: 556px;
  color: #333;
  position: absolute;
  top: 60px; // height of the clarity header bar
  z-index: 1000;

  .results-padding-LR {
    background-color: #FFF;

    table {
      .results-cell-blue {
        text-indent: 0px;
      }
    }
  }

  ul {
    margin: 0;
  }

  li {
    list-style: none;
  }
}

// Hacky workaround to prevent Kendo from blocking
// the whole page when a signpost is opened
div.km-popup-overlay.km-popover-root {
  height: 0px;
  width: 0px;
}

.clr-component, .clr-component * {
  box-sizing: border-box;
}

.clr-component {
  display: block;
  line-height: 24px;
}

.close * {
  font-size: inherit;
}

action-bar {
  .vui-action-bar {
    min-height: 22px;
  }
}

.vui-action-bar {
  ul {
    li {
      a {
        will-change: opacity;

        &:focus {
          box-shadow: none;
        }
      }
    }
  }
}

// adding bottom border to .k-grid-content
// when there's no footer/pager (hidePager: true)
.k-grid-content-locked:last-child,
.k-grid-content:last-child {
    border-bottom: 1px solid #dddddd;
}

.k-splitbar.k-splitbar-vertical {
  .k-icon.k-expand-next {
    background: none;
  }
}

.alert-text {
  position: relative;
}

dl.stack-view {
  -webkit-mask-image: none;
  overflow-y: visible;
}

.cluster-settings-drs-view {
  display: flex;
  flex-direction: column;
  height: 100%;
  box-sizing: border-box;
  clr-stack-view {
    flex: 1 1 auto;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    dl.stack-view {
      overflow: auto;
    }
  }
}

.vsphere-ha-settings-view {
  box-sizing: border-box;
  height: 100%;
  display: flex;
  flex-direction: column;
  .ha-container {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    .settingsBlock {
      flex-shrink: 0;
      .failure-grid-container {
        flex: 1 1 auto;
      }
    }
    clr-stack-view {
      flex: 1 1 auto;
      display: flex;
      flex-direction: column;
      dl.stack-view {
        overflow: auto;
      }
    }
  }
}

.related-items-portlet {
  .object-link {
    display: flex;
    span {
      flex-shrink: 0;
    }
    a {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
}